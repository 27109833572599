import React, { useState, createContext } from "react";

import { collection, query, onSnapshot, where } from "firebase/firestore";
import db from '../firebase/config';

export const ReturnsContext = createContext();

export const ReturnsProvider = (props) => {
    const { children } = props;

    const [ returns, setReturns ] = useState('');
    const [ returnsUser, setReturnsUser ] = useState('');

    const clearReturnsContext = async () => {
        setReturns('');
        setReturnsUser('');
        return
    }

   //Get returns admin
    const getReturnsAdmin = async () => {
        const consulta = query(collection(db, 'clientes'), where('selected', '==', ''),  where('retornos', '>', 0));
        await onSnapshot(consulta, (querySnapshot) => {
            const database = [];
            
            const data = querySnapshot.docs;
            
            for(const i in data){
                const client = data[i].data();
                database.push({...client, docId: data[i].id});
            }

            setReturns(database);
        }, error => {
            console.log("error: ", error)
        });
    }

    const getReturnsUser = async (user) => {

        const consulta = query(collection(db, 'clientes'), 
            where("selected", "==", ""), 
            where("operatorUid", "==", user.uid),  where('retornos', '>', 0));
        await onSnapshot(consulta, (querySnapshot) => {
            const database = [];
            
            const data = querySnapshot.docs;
            
            for(const i in data){
                const client = data[i].data();
                database.push({ ...client, docId: data[i].id });
            }

            setReturnsUser(database);
        }, err => {
            console.log("getReturnsUser: ", err)
        });
    }

    return (
        <ReturnsContext.Provider 
            value={{ 
                returns: returns,
                returnsUser: returnsUser,
                getReturnsAdmin,
                clearReturnsContext,
                getReturnsUser,
            }}
        >
            {children}
        </ReturnsContext.Provider>
    )
}