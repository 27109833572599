import React, { useContext } from 'react';

import { Container, Content, ActionsContent, CancelButton, ConfirmButton, Divider, FieldText } from './styles';

//Firebase and context
import { AuthContext } from '../../contexts/AuthContext';
import { UsersContext } from '../../contexts/UsersContext';

//Utils
import Select from 'react-select'
import { useEffect } from 'react';
import { useState } from 'react';
import CustomLoading from '../CustomLoading';
import ModalHeader from '../ModalHeader';
import { ClientsContext } from '../../contexts/ClientsContext';

const ModalAssignReturn = ({ client, onCancel, onConfirm }) => {

    const { isAdmin } = useContext(AuthContext);
    const { users } = useContext(UsersContext);
    const { getClientByCpf } = useContext(ClientsContext);

    const [ operators, setOperators ] = useState('');
    const [ selectedOperator, setSelectedOperator ] = useState('');

    useEffect(() => {
        processFilterOptions();
    }, [users]);

    const processFilterOptions = async () => {
        const arrayOperators = [];

        const clientDoc = await getClientByCpf(client.clientCpf);

        if(!clientDoc){
            return []
        }

        if(isAdmin){
            for(const user of users?.operators){
                const inMap = user.mapsIds.find(x => x.value === clientDoc?.mapId);
               
                if(inMap){
                    arrayOperators.push({
                        label: user.userName,
                        value: user.uid,
                    })
                }
            }
        }else{
            for(const user of users.operators){
                arrayOperators.push({
                    label: user.userName,
                    value: user.uid,
                })
            }
        }
        
        setOperators(arrayOperators);
    }


    const customStyle = {      
        container: (base) => ({
            ...base,
            borderRadius: 40
        }),
        control: (base) => ({
            ...base,
            padding: 3,
            borderRadius: 40
        }),
        multiValue: (base) => ({
            ...base,
            borderRadius: 40
        }),
    }

    if(!operators){
        return(
            <Container>
                <CustomLoading />
            </Container>
        )
    }

    return (
        <Container>
            <ModalHeader title='Encaminhar cliente' onClose={onCancel} />
            <Content>
                <FieldText>
                    Operador
                </FieldText>
                <Select 
                    isClearable={false} 
                    isSearchable={true} 
                    closeMenuOnSelect={true} 
                    options={operators} 
                    isMulti={false} 
                    styles={customStyle}
                    autoFocus={true}
                    openMenuOnFocus={true}
                    onChange={(text) => {
                        setSelectedOperator(text)
                    }}
                />
            </Content>
            <Divider />
            <ActionsContent>
                <CancelButton onClick={onCancel}>
                    <text>Voltar</text>
                </CancelButton>
                <ConfirmButton onClick={() => onConfirm(selectedOperator)}>
                    <text>Encaminhar</text>
                </ConfirmButton>
            </ActionsContent>
        </Container>
    )
}

export default ModalAssignReturn;