import React, { useEffect, useState, useContext } from 'react'

import {
    Container,
    Header,
    RoutesContainer,
    Divider,
    SwitchRoute,
    SwitchButton,
    RouteSection,
    RoutesCard,
    RoutesCardHeader,
    HistoryContainer,
    FilterContainer,
    FilteredDescription,
    DateDescription,
    FilterItem,
    PointDivider,
    HistoryHeader,
    HistoryCard,
    HistoryList,
    DividerLight,
} from './styles';

//Firebase and context
import { AuthContext } from '../../contexts/AuthContext';
import { MapsContext } from '../../contexts/MapsContext';

import Modal from 'react-modal';

import routeSuccess from '../../assets/routeSuccess.svg'
import routeYellow from '../../assets/routeYellow.svg'
import routeRed from '../../assets/routeRed.svg'

import { useNavigate } from 'react-router-dom';

import { collection, onSnapshot, where, query, orderBy, writeBatch, doc } from "firebase/firestore";
import db from '../../firebase/config';
import ProfileButton from '../../components/ProfileButton';
import CustomLoadingPage from '../../components/CustomLoadingPage';
import PointIcon from '../../components/PointIcon';
import RouteItem from '../../components/RouteItem';
import DateFilter from '../../components/DateFilter';
import ModalViewRoute from '../../components/ModalViewRoute';
import CustomFilterHeader from '../../components/CustomFilterHeader';
import { arrayHelper } from '../../helpers/arrayHelper';

const monthNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

const AdminRoutesPage = () => {

    const { isAdmin, isManager, userData } = useContext(AuthContext);
    const { maps, getMapsAdmin } = useContext(MapsContext);

    const navigate = useNavigate();

    //Switch
    const [ switchSelected, setSwitchSelected ] = useState(0);

    const [ loadingData, setLoadingData ] = useState(true);
    const [ savedRoutes, setSavedRoutes ] = useState('');
    const [ currentRoute, setCurrentRoute ] = useState('');

    //Route of day
    const [ routesOfDay, setRoutesOfDay ] = useState('');

    //History
    const [ historyInfo, setHistoryInfo ] = useState('');
    const [ historyData, setHistoryData ] = useState('');
    const [ historyDataBackup, setHistoryDataBackup ] = useState('');
    const [ calendarValues, setCalendarValues ] = useState('');

    const [ historyDataFilter, setHistoryDataFilter ] = useState('');
    const [ historyDataCalendar, setHistoryDataCalendar ] = useState('');

    //Modal
    const [ modalViewRoute, setModalViewRoute ] = useState(false);

    useEffect(() => {
        getMapsAdmin()
        const getSavedRoutesAdmin = async () => {
            const consulta = query(collection(db, 'rotas'), orderBy("time", "desc"));
            await onSnapshot(consulta, (querySnapshot) => {
                const database = [];
                const data = querySnapshot.docs;

                for(const i in data){
                    var atendido = 0;
                    const route = data[i].data();

                    for(const point of route.points){
                        if(point.atendido){
                            atendido++
                        }
                    }
                    database.push({
                        ...data[i].data(), 
                        docId: data[i].id,
                        status: atendido < route.points.length && route.status == 'finalizado' 
                            ? 'parcial' : route.status
                    });
                }
                setSavedRoutes(database); 
            });                  
        }

        const getSavedRoutesManagerHistory = async () => {
            if(!userData.operatorsArray || userData.operatorsArray.length == 0){
                return setSavedRoutes([]); 
            }

            const operatorsUid = arrayHelper.reduceToSimple(userData.operatorsArray, 'uid');
            const consulta = query(collection(db, 'rotas'), where('uid', 'in', operatorsUid));
            await onSnapshot(consulta, (querySnapshot) => {
                const database = [];
                const data = querySnapshot.docs;

                for(const i in data){
                    var atendido = 0;
                    const route = data[i].data();

                    for(const point of route.points){
                        if(point.atendido){
                            atendido++
                        }
                    }
                    database.push({
                        ...data[i].data(), 
                        docId: data[i].id,
                        status: atendido < route.points.length && route.status == 'finalizado' 
                            ? 'parcial' : route.status
                    });
                }

                const ordenatedArray = database.sort(function(a,b) {
                    return a.time < b.time ? 1 : a.time > b.time ? -1 : 0;
                }); 
                setSavedRoutes(ordenatedArray); 
            });                  
        }

        if(isAdmin){
            getSavedRoutesAdmin();
        }

        if(isManager){
            getSavedRoutesManagerHistory()
        }
        
    }, []);

    useEffect(() => {
        setLoadingData(true)
        if(savedRoutes && maps){
            const dateNow = new Date();
            const arrayRoutes = [];

            const ontemTime = dateNow.getTime() - (2*(86400*1000))

            const aguardando = savedRoutes.filter(x => x.status == 'aguardando' && x.time >= ontemTime );
            const iniciado = savedRoutes.filter(x => x.status == 'iniciado' && x.time >= ontemTime );
            const finalizado = savedRoutes.filter(x => (x.status == 'finalizado' || x.status == 'parcial') && x.time >= ontemTime );
            const cancelado = savedRoutes.filter(x => x.status == 'cancelado' && x.time >= ontemTime );
            

            setRoutesOfDay({
                aguardando: aguardando,
                iniciado: iniciado,
                finalizado: finalizado,
                cancelado: cancelado
            })

            for(const route of savedRoutes){
                var city = '';

                for(const point of route.points){
                    if(point.mapId){
                        const result = maps.find(x => x.docId == point.mapId);

                        if(result){
                            city = result.mapName
                        }
                    }
                }

                arrayRoutes.push({
                    ...route,
                    city: city
                })
            }

            setHistoryData(arrayRoutes);
            setHistoryDataBackup(arrayRoutes)
        }
        setLoadingData(false)
    }, [savedRoutes])

    useEffect(() => {
        setLoadingData(true)

        if(historyData){
            const parcial = historyData.filter(x => x.status == 'parcial');
            const finalizado = historyData.filter(x => x.status == 'finalizado');
            const cancelado = historyData.filter(x => x.status == 'cancelado');

            setHistoryInfo({
                parcial: parcial,
                finalizado: finalizado,
                cancelado: cancelado
            })
        }
        setLoadingData(false);
    }, [historyData])

    const reOpenRoute = async (item) => {
        const batch = writeBatch(db);

        for(const point of item.points){
            const docRef = doc(db, "clientes", point.cpf);

            batch.update(docRef, {
                selected: item.uid,
            });
        }
        const docRef = doc(db, "rotas", item.docId);

        batch.update(docRef, {
            status: 'aguardando',
        });

        batch.commit().then(() => {
            alert("Rota re-aberta com sucesso");
        }).catch((error) => {
            alert("Erro ao re-abrir rota: ", error.code);
        })
        
    }

    const getFilterDescription = () => {

        if(!calendarValues.selectedEnd.day){
            return `${calendarValues.selectedStart.day} de ${
                    monthNames[calendarValues.selectedStart.month-1]} de ${
                    calendarValues.selectedStart.year
                }`
        }

        if(calendarValues.selectedStart.year == calendarValues.selectedEnd.year){
            if(calendarValues.selectedStart.month == calendarValues.selectedEnd.month){
                return `${calendarValues.selectedStart.day} a ${
                        calendarValues.selectedEnd.day} de ${
                        monthNames[calendarValues.selectedEnd.month-1]} de ${
                        calendarValues.selectedEnd.year
                    }`
            }else{
                return `${calendarValues.selectedStart.day} de ${
                            monthNames[calendarValues.selectedStart.month-1]} a ${
                            calendarValues.selectedEnd.day} de ${
                            monthNames[calendarValues.selectedEnd.month-1]} de ${
                            calendarValues.selectedEnd.year
                        }`
            }
        }else{
            return `${calendarValues.selectedStart.day} de ${
                    monthNames[calendarValues.selectedStart.month-1]} de ${
                    calendarValues.selectedStart.year} a ${
                    calendarValues.selectedEnd.day} de ${
                    monthNames[calendarValues.selectedEnd.month-1]} de ${
                    calendarValues.selectedEnd.year
                }`
        }
    }

    if(loadingData || !routesOfDay || !historyData){
        return (
            <Container>
                <CustomLoadingPage />
            </Container>
        );
    }

    return (
        <Container>
            <Header>
                <h1>Rotas</h1>
                <div>
                    {switchSelected == 1 && (
                        <CustomFilterHeader 
                            tableRowsBackup={historyDataCalendar ? historyDataCalendar : historyDataBackup}
                            data={historyData}
                            filterTypes={[
                                { headerName: 'Status', field: 'status' },
                                { headerName: 'Operador', field: 'operatorName' },
                                { headerName: 'Mapa', field: 'city' }
                            ]}
                            onFilter={(newData, values) => {
                                setHistoryData(newData)
                                setHistoryDataFilter(newData)
                            }}
                            clearFilters={() => {
                                if(historyDataCalendar){
                                    setHistoryData(historyDataCalendar)
                                    setHistoryDataFilter('')
                                }else{
                                    setHistoryData(historyDataBackup)
                                    setHistoryDataFilter('')
                                }
                            }}
                            filterType='atendimentos'
                        />
                    )}
                    <ProfileButton arrowColor='var(grey2)' />
                </div>
            </Header>
            <SwitchRoute>
                <SwitchButton onClick={() => setSwitchSelected(0)}>
                    Rotas de hoje
                    {switchSelected == 0 && (<div></div>)}
                </SwitchButton>
                <SwitchButton onClick={() => setSwitchSelected(1)}>
                    Histórico
                    {switchSelected == 1 && (<div></div>)}
                </SwitchButton>
            </SwitchRoute>
            {switchSelected == 0 && (
                <RoutesContainer>
                    <> 
                        <RouteSection>
                            Aguardando
                            <PointIcon
                                size={30}
                                fontWeight='500'
                                fontSize='14'
                                backgroundColor='#4A5E83'
                                textColor='white'
                                text={routesOfDay.aguardando.length}
                            />
                        </RouteSection>
                        <RoutesCard>
                            <RoutesCardHeader>
                                <h3>Nome</h3>
                                <h3>Operador</h3>
                                <h3>Status</h3>
                                <h3>Criação</h3>
                                <h3>Progresso</h3>
                                <h3>Ações</h3>
                            </RoutesCardHeader>
                            {routesOfDay.aguardando.map((item, index) => (
                                <RouteItem 
                                    key={index} 
                                    data={item} 
                                    columns={6} 
                                    onViewRoute={() => {
                                        setCurrentRoute(item);
                                        setModalViewRoute(true)
                                    }}
                                />
                            ))}
                        </RoutesCard>
                    </>
                    

                    <> 
                        <RouteSection>
                            Em andamento
                            <PointIcon
                                size={30}
                                fontWeight='500'
                                fontSize='14'
                                backgroundColor='#4A5E83'
                                textColor='white'
                                text={routesOfDay.iniciado.length}
                            />
                        </RouteSection>
                        <RoutesCard>
                            <RoutesCardHeader columns={7}>
                                <h3>Nome</h3>
                                <h3>Operador</h3>
                                <h3>Status</h3>
                                <h3>Criação</h3>
                                <h3>Início</h3>
                                <h3>Progresso</h3>
                                <h3>Ações</h3>
                            </RoutesCardHeader>
                            {routesOfDay.iniciado.map((item, index) => (
                                <RouteItem 
                                    key={index} 
                                    data={item} 
                                    columns={7} 
                                    onViewRoute={() => {
                                        setCurrentRoute(item);
                                        setModalViewRoute(true)
                                    }}
                                />
                            ))}
                        </RoutesCard>
                    </>

                    <> 
                        <RouteSection>
                            Encerradas
                            <PointIcon
                                size={30}
                                fontWeight='500'
                                fontSize='14'
                                backgroundColor='#4A5E83'
                                textColor='white'
                                text={routesOfDay.finalizado.length}
                            />
                        </RouteSection>
                        <RoutesCard>
                            <RoutesCardHeader columns={8}>
                                <h3>Nome</h3>
                                <h3>Operador</h3>
                                <h3>Status</h3>
                                <h3>Criação</h3>
                                <h3>Início</h3>
                                <h3>Finalização</h3>
                                <h3>Progresso</h3>
                                <h3>Ações</h3>
                            </RoutesCardHeader>
                            {routesOfDay.finalizado.map((item, index) => (
                                <RouteItem 
                                    key={index} 
                                    data={item} 
                                    columns={8} 
                                    onViewRoute={() => {
                                        setCurrentRoute(item);
                                        setModalViewRoute(true)
                                    }}    
                                />
                            ))}
                        </RoutesCard>
                    </>

                    <> 
                        <RouteSection>
                            Canceladas
                            <PointIcon
                                size={30}
                                fontWeight='500'
                                fontSize='14'
                                backgroundColor='#4A5E83'
                                textColor='white'
                                text={routesOfDay.cancelado.length}
                            />
                        </RouteSection>
                        <RoutesCard>
                            <RoutesCardHeader columns={7}>
                                <h3>Nome</h3>
                                <h3>Operador</h3>
                                <h3>Status</h3>
                                <h3>Criação</h3>
                                <h3>Cancelamento</h3>
                                <h3>Progresso</h3>
                                <h3>Ações</h3>
                            </RoutesCardHeader>
                            {routesOfDay.cancelado.map((item, index) => (
                                <RouteItem 
                                    key={index} 
                                    data={item} 
                                    columns={7}
                                    history={false} 
                                    onViewRoute={() => {
                                        setCurrentRoute(item);
                                        setModalViewRoute(true)
                                    }}
                                />
                            ))}
                        </RoutesCard>
                    </>
                </RoutesContainer>
            )}     
            {switchSelected == 1 && (
                <HistoryContainer>
                    <FilterContainer>
                        <DateFilter 
                            data={historyData}
                            defaultValues={calendarValues}
                            filterType='atendimentos'
                            onFilterDate={(arrayData, values) => {
                                setHistoryData(arrayData)
                                setHistoryDataCalendar(arrayData)
                                setCalendarValues(values)
                            }}
                            tableRowsBackup={historyDataFilter ? historyDataFilter : historyDataBackup}
                            clearDateFilter={() => {
                                if(historyDataFilter){
                                    setHistoryData(historyDataFilter)
                                    setHistoryDataCalendar('');
                                    setCalendarValues('')
                                }else{
                                    setHistoryData(historyDataBackup)
                                    setHistoryDataCalendar('');
                                    setCalendarValues('')
                                }
                                
                            }}
                            dateField='routeCloseDate'
                        />
                        <Divider />
                        
                        <FilteredDescription>
                            <h3>Exibindo:</h3>
                            <br />
                            {calendarValues
                                ?   <DateDescription>
                                    {getFilterDescription()}
                                </DateDescription>
                                : <DateDescription>
                                    Desde o início
                                </DateDescription>
                            }
                            
                            <br />
                            <FilterItem>
                                <img src={routeSuccess} alt='img img' />
                                <div>
                                    <h3>Rotas concluídas:</h3>
                                    <h4>{historyInfo.finalizado.length}</h4>
                                </div>
                            </FilterItem>
                            <PointDivider />
                            <FilterItem>
                                <img src={routeYellow} alt='img img' />
                                <div>
                                    <h3>Rotas parcialmente concluídas:</h3>
                                    <h4>{historyInfo.parcial.length}</h4>
                                </div>
                            </FilterItem>
                            <PointDivider />
                            <FilterItem>
                                <img src={routeRed} alt='img img' />
                                <div>
                                    <h3>Rotas canceladas:</h3>
                                    <h4>{historyInfo.cancelado.length}</h4>
                                </div>
                            </FilterItem>
                        </FilteredDescription>                        
                    </FilterContainer>
                    <HistoryCard>
                        <HistoryHeader>
                            <h3>Nome</h3>
                            <h3>Operador</h3>
                            <h3>Data de conclusão</h3>
                            <h3>Progresso</h3>
                            <h3>Ações</h3>
                        </HistoryHeader>
                        <HistoryList>
                        {historyData.map((item, index) => (
                            <>
                                <RouteItem 
                                    key={index} 
                                    data={item} 
                                    columns={5} 
                                    history={true} 
                                    onViewRoute={() => {
                                        setCurrentRoute(item);
                                        setModalViewRoute(true)
                                    }}
                                />
                                {index != historyData.length-1 && (
                                    <DividerLight />
                                )}
                            </>
                        ))}
                        </HistoryList>
                        
                    </HistoryCard>
                </HistoryContainer>
            )}       
            <Modal
                isOpen={modalViewRoute}
                onRequestClose={() => setModalViewRoute(false)}
                overlayClassName="modal-overlay"
                className="modal-view-route"
                contentLabel="Example Modal"
            >
                <ModalViewRoute 
                    routeData={currentRoute} 
                    onClose={() => setModalViewRoute(false)}
                />
            </Modal>
        </Container>
    )
};

export default AdminRoutesPage;