import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    html, body, #root {
        max-height: 100vh;
        max-width: 100vw;

        width: 100%;
        height: 100%;
    }
    *, button, input {
        border: 0;
        background: none;
        font-family: 'Roboto';
    }

    input{
        width: 100%;
        height: 100%;
        padding: 10px;
        border: 0;
        background: none;
        color: var(--grey2);
        
        :focus{
            /* outline: 2px solid var(--red); */
            outline: none;
            border-radius: 100px;
            color: var(--grey2);
        }
    }
    html {
        background: var(--primary);
    }
    :root {
        --primary: #EBF3FF;
        --red: #DE6161;
        /* --yellow: #FFD793; */
        --yellow: #F5C87B;
        --orange: #F5987B;
        --blue: #9BBEFF;
        --aqua: #83DBD6;
        --purple: #B5AAFF;
        --background: #EBF3FF;
        --grey1: #B4C1D5;
        --grey2: #808B9F;
        --blue2: #596E95;
        --blue3: #4D6289;
        --blue4: #4A5E83;
        --white: #FFFFFF;
        --success: #68CFC9;
    }
    .modal-overlay {
        background: rgba(0, 0, 0, 0.2);
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        height: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10000;
    }
    .modal-content {
        width: 90%;
        max-width: 500px;
        height: 80%;
        overflow-y: scroll;
        background-color: white;
        padding: 30px;
        border-radius: 15px;
        outline: none;
        z-index: 10000;
    }
    .modal-content-auto-width {
        /* width: 100%; */
        max-height: 90%;
        /* overflow-y: scroll; */
        background-color: white;
        /* padding: 30px; */
        border-radius: 15px;
        outline: none;
        z-index: 10000;
    }
    .modal-content-auto {
        background-color: white;
        border-radius: 15px;
        outline: none;
        z-index: 10000;
    }
    .modal-content-alert {
        background-color: white;
        border-radius: 40px;
        outline: none;
        z-index: 10000;
    }
    .modal-view-route {
        height: 98vh;
        width: 90vw;
        background-color: white;
        border-radius: 40px;
        outline: none;
        z-index: 10000;
    }
    .modal-title {
        color: var(--red);
        margin-bottom: 10px;
    }
    /* width */
    ::-webkit-scrollbar {
        width: 8px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #B4C1D5;
        border-radius: 50px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #4A5E83;
        border-radius: 50px;
        margin-left: 5px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #4A5E83;
        border-radius: 50px;
    }
`;
