import React, { useEffect, useState, useContext } from "react";

import {
    Container, 
    ChartsContainer,
    FilteredChartContainer,
    Header,
    CustomCard,
    CardTitle,
    CardDivider,
    CardContent,
    CardsContainer,
    ChartContainer,
    ChartHeader,
    RangeSelectContainer,
    RangeSelectButton,
    ChartLineFilter,
    ChartLineItem,
    AddLineButton,
    ChartLinesContainer
} from './styles';

//Firebase and context
import { AuthContext } from '../../contexts/AuthContext';

//Icons
import { MdRemoveCircleOutline } from 'react-icons/md';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { HiOutlineChartBar } from 'react-icons/hi';

import checkedIcon from '../../assets/checkedIcon.svg'
import listIcon from '../../assets/listIcon.svg'
import winnerIcon from '../../assets/winnerIcon.svg';
import medalIcon from '../../assets/medalIcon.svg';

//Charts
import { 
    VictoryChart, 
    VictoryGroup, 
    VictoryAxis,
    VictoryLine,
    VictoryVoronoiContainer,
    VictoryTooltip,
    VictoryScatter
} from 'victory';

//Custom Components
import ModalDashboardCard from "../../components/ModalDashboardCard";
import CustomLoadingPage from "../../components/CustomLoadingPage";
import CustomFilterHeader from "../../components/CustomFilterHeader";
import CustomDivider from "../../components/CustomDivider";
import ProfileButton from "../../components/ProfileButton";
import OperatorsCardDashboard from "../../components/OperatorsCardDashboard";
import CustomTable from "../../components/CustomTable";
import PointIcon from "../../components/PointIcon";

//Utils
import tableColumnsReturns from '../../resources/tableColumnsReturns.json';
import Modal from 'react-modal';

//MUI
import { Avatar } from "@mui/material";
import { datetime } from "../../helpers/datetime";
import { ReportsContext } from "../../contexts/ReportsContext";
import { ReturnsContext } from "../../contexts/ReturnsContext";
import { RoutesContext } from "../../contexts/RoutesContext";
import { UsersContext } from "../../contexts/UsersContext";

//utils
import { avatar } from "../../helpers/avatar";

const options = [
    "Contrato fechado",
    "Contrato fechado por indicação",
    "Endereço não encontrado",
    "Litispendência",
    "Já tem advogado",
    "Retornar",
    "Não tem direito",
    "Não tem interesse",
    "Localidade diversa",
]

const dateNow = new Date();

const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

const mesesRedux = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

const lines = ['line1', 'line2', 'line3']

const DashboardPage = () => {

    const { isAdmin, isManager } = useContext(AuthContext);

    const { users } = useContext(UsersContext);

    const { returns, getReturnsAdmin } = useContext(ReturnsContext);

    const { routes, getRoutesAdmin, getRoutesManager } = useContext(RoutesContext);

    const { reports, getReportsAdmin, getReportsManager } = useContext(ReportsContext);
    

    const [ loadingData, setLoadingData ] = useState(true);

    //Modal
    const [ modalDashboardCard, setModalDashboardCard ] = useState(false);

    //Cards
    const [ fechados, setFechados ] = useState([]); 
    const [ visitas, setVisitas ] = useState([]);
    const [ winnerContratos, setWinnerContratos ] = useState('');
    const [ winnerVisitas, setWinnerVisitas ] = useState('');
    const [ cardData, setCardData ] = useState('');
    const [ cardDataBackup, setCardDataBackup ] = useState('');
    const [ calendarValues, setCalendarValues ] = useState('');
    
    //Charts
    const [ chartFilters, setChartFilters ] = useState({ line1: {}});
    const [ chartData, setChartData ] = useState({ line1: {}});

    const [ chartRange, setChartRange ] = useState('mes');
    const [ selectedLine, setSelectedLine ] = useState('line1');

    //Table
    const [ tableRows, setTableRows ] = useState('');
    const [ tableRowsBackup, setTableRowsBackup ] = useState('');
    const [ modalData, setModalData ] = useState('');
    const [ tableDefaultFilters, setTableDefaultFilters ] = useState('');
    const [ clearTable, setClearTable ] = useState(false);

    const [ currentMonth, setCurrentMonth ] = useState(dateNow.getMonth());
    const [ operatorsData, setOperatorsData ] = useState('');
    const [ operatorsDataBackup, setOperatorsDataBackup ] = useState('');
    
    useEffect(() => {
        (async () => {
            if(reports && returns && routes && users){
                const array = []

                const firstDay = new Date(`${currentMonth+1}/1/${dateNow.getFullYear()}`).getTime();
                const lastDay = new Date(dateNow.getFullYear(), currentMonth+1, 0).getTime();
                
                //Returns
                const findRealized = reports.filter(x => x.retornos > 0 && x.result != options[5] && x.time > firstDay && x.time < lastDay);
                const findExpired = [];
                const findPending = [];

                for(const item of returns){
                    if(item.atraso){

                        const report = reports.find(x => x.docId == item.reportId);

                        if(report && report.time > firstDay && report.time < lastDay){
                            findExpired.push({
                                ...report,
                                ...item,
                            })
                        }
                    }else{
                        const report = reports.find(x => x.docId == item.reportId);

                        if(report && report.time > firstDay && report.time < lastDay){
                            findPending.push({
                                ...report,
                                ...item,
                            })
                        }
                    }
                }

                //Routes
                const findDaysWithRoutes = [];
                const daysOfMonth = [];

                const monthInterval = datetime.getMonthIntervalMs(dateNow.getTime())

                for(var i = monthInterval.timeStart; i <= monthInterval.timeEnd; i = i+(86400*1000)){
                    daysOfMonth.push(i)
                }

                for(const dayTime of daysOfMonth){
                    const result = routes.filter(x => x.time > dayTime && x.time < (dayTime+(86399*1000)))

                    if(result.length > 0){
                        findDaysWithRoutes.push({
                            day: dayTime,
                            routes: result
                        })
                    }
                }

                const data = {
                    daysWithRoutes: findDaysWithRoutes,
                    visitedClients: reports,
                    returns: {
                        total: findRealized.concat(findPending, findExpired),
                        realized: findRealized,
                        expired: findExpired,
                        pending: findPending,
                    }
                }

                setOperatorsData(data)
                setOperatorsDataBackup(data.visitedClients);
                setTableRows(data.visitedClients)
                setTableRowsBackup(data.visitedClients)

                getReportsOfMonth();

                setLoadingData(false);
                
            }else{      
                console.log("users", users);
                console.log("returns", returns);
                console.log("reports", reports);

                if(isAdmin){
                    if(!reports){
                        await getReportsAdmin();
                    }
                    
                    if(!returns){
                        await getReturnsAdmin();
                    }

                    if(!routes){
                        await getRoutesAdmin();
                    }
                        
                }

                if(isManager){
                    if(users && !reports){
                        await getReportsManager(users.operators);
                    }

                    if(!returns){
                        await getReturnsAdmin();
                    }
                    if(users && !routes){
                        await getRoutesManager(users.operators);
                    }
                }
            }
        })();
    }, [reports, routes, returns, users]);

    useEffect(() => {
        if(cardData){
            calculateFechados();
            calculateVisitas();
            calculateRanking();
            calculateWinnerVisitas();
        }
    }, [cardData])

    useEffect(() => {
        if(chartFilters){
            const chartLines = Object.keys(chartFilters);

            var newObj = {};

            for(const key of chartLines){
                
                newObj[key] = filterNewChart(chartFilters[key].data, key, chartFilters[key].filters, chartRange)
                
            }

            setChartData(newObj)
        }
    }, [chartFilters])

    

    const getReportsOfMonth = () => {
        const result = reports.filter(x => new Date(x.time).getMonth() === currentMonth && new Date(x.time).getFullYear() === dateNow.getFullYear());

        setCardData(result)
        setCardDataBackup(result)
    }

    const calculateFechados = () => {
        const result = cardData.filter(x => x.result === options[0] || x.result === options[1])

        setFechados(result);
    }

    const calculateVisitas = () => {
        const result = cardData.filter(x => x.result != options[8])
        
        setVisitas(result);
    }

    const calculateRanking = () => {
        const operators = [];
        const rankingArray = [];

        for(const report of cardData){
            const result = operators.find(x => x === report.operatorName);

            if(!result){
                operators.push({
                    operatorName: report.operatorName,
                    operatorUid: report.operatorUid
                });
            }
        }

        const fechados = cardData.filter(x => x.result === options[0] || x.result === options[1]);

        if(operators.length > 0){
            for(const operator of operators){
            
                const result = fechados.filter(x => x.operatorUid === operator.operatorUid);
    
                rankingArray.push({
                    operatorName: operator.operatorName,
                    operatorUid: operator.operatorUid,
                    qtd: result.length,
                    array: result
                });
            }
        }

        if(rankingArray.length > 0){
            const winner = rankingArray.reduce(function(prev, current) { 
                return prev.qtd > current.qtd ? prev : current; 
            });
    
            setWinnerContratos(winner);
        }
    }

    const calculateWinnerVisitas= () => {
        const array = [];
        const operators = [];
        const rankingArray = [];

        for(const report of cardData){
            if(report.result != options[8]){
                array.push(report);

                const result = operators.find(x => x === report.operatorName);
    
                if(!result){
                    operators.push({
                        operatorName: report.operatorName,
                        operatorUid: report.operatorUid
                    });
                }
            }            
        }

        if(operators.length > 0){
            for(const operator of operators){
            
                const result = array.filter(x => x.operatorUid === operator.operatorUid);
    
                rankingArray.push({
                    operatorName: operator.operatorName,
                    operatorUid: operator.operatorUid,
                    qtd: result.length,
                    array: result
                });
            }
        }
        

        if(rankingArray.length > 0){
            const winnerVisitas = rankingArray.reduce(function(prev, current) { 
                return prev.qtd > current.qtd ? prev : current; 
            });

            setWinnerVisitas(winnerVisitas);
        }
    }

    const filterNewChart = (data, line, filters, chartRange) => {

        const arrayDays = []
        const arrayMonths = []

        if(!data || data.length == 0){
            return
        }
        
        const firstReportTime = 1659368211000;

        const lastReportTime = data.reduce(function(prev, current) { 
            return prev.time > current.time ? prev : current; 
        }).time;


        const dateStart = new Date(firstReportTime)
        const dateEnd = new Date(lastReportTime)

        const startTime = new Date(`${dateStart.getMonth()+1}/${dateStart.getDate()}/${dateStart.getFullYear()}`).getTime();

        const endTime = new Date(`${dateEnd.getMonth()+1}/${dateEnd.getDate()}/${dateEnd.getFullYear()}`).getTime();

        const array = [];

        for(var i = startTime; i <= endTime; i = i+(86400*1000)){
            const date = new Date(i);

            const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
            const month = date.getMonth()+1 < 10 ? `0${date.getMonth()+1}` : date.getMonth()+1;
            const year = date.getFullYear();

            const stringDate = `${day}/${month}/${year}`;

            arrayDays.push(stringDate)

            const result = arrayMonths.find(x => x.month == date.getMonth()+1 && x.year == date.getFullYear())

            if(!result){
                arrayMonths.push({
                    month: date.getMonth()+1,
                    year: date.getFullYear()
                })
            }
        }

        if(chartRange == 'mes'){
            for(const item of arrayMonths){
                const result = data.filter(x => (new Date(x.time)).getMonth()+1 == item.month &&
                    (new Date(x.time)).getFullYear() == item.year)

                const formatedMonth = `${mesesRedux[item.month-1]}`

                array.push({
                    x: formatedMonth,
                    y: result.length,
                })
            }
        }else{
            for(const day of arrayDays){
                const result = data.filter(x => datetime.getDate(x.time) == day);

                if(result.length > 0){
                    const date = new Date(result[0].time)

                    const exists = array.find(x => x.month === date.getMonth()+1)
    
                    var formatedDate = ''
    
                    if(!exists){
                        formatedDate = `${date.getDate()} de ${mesesRedux[date.getMonth()]}`;
                    }else{
                        formatedDate = `${date.getDate()}`;
                    }
    
    
                    array.push({
                        x: formatedDate,
                        y: result.length,
                        month: date.getMonth()+1,
                    })
                }
            }
        }

        return {
            filters: filters,
            data: array
        }
    }

    const filterFirstLine = (data, filters, chartRange) => {
    
        const arrayDays = []
        const arrayMonths = []

        if(!data || data.length == 0){
            return 
        } 
        
        const firstReportTime = 1659368211000;

        const lastReportTime = data.reduce(function(prev, current) { 
            return prev.time > current.time ? prev : current; 
        }).time;


        const dateStart = new Date(firstReportTime)
        const dateEnd = new Date(lastReportTime)

        const startTime = new Date(`${dateStart.getMonth()+1}/${dateStart.getDate()}/${dateStart.getFullYear()}`).getTime();

        const endTime = new Date(`${dateEnd.getMonth()+1}/${dateEnd.getDate()}/${dateEnd.getFullYear()}`).getTime();

        const array = [];

        for(var i = startTime; i <= endTime; i = i+(86400*1000)){
            const date = new Date(i);

            const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
            const month = date.getMonth()+1 < 10 ? `0${date.getMonth()+1}` : date.getMonth()+1;
            const year = date.getFullYear();

            const stringDate = `${day}/${month}/${year}`;

            arrayDays.push(stringDate)

            const result = arrayMonths.find(x => x.month == date.getMonth()+1 && x.year == date.getFullYear())

            if(!result){
                arrayMonths.push({
                    month: date.getMonth()+1,
                    year: date.getFullYear()
                })
            }
        }

        if(chartRange == 'mes'){
            for(const item of arrayMonths){
                const result = data.filter(x => (new Date(x.time)).getMonth()+1 == item.month &&
                    (new Date(x.time)).getFullYear() == item.year)

                const formatedMonth = `${mesesRedux[item.month-1]}`

                array.push({
                    x: formatedMonth,
                    y: result.length,
                })
            }
        }else{
            for(const day of arrayDays){
                const result = data.filter(x => datetime.getDate(x.time) == day);

                if(result.length > 0){
                    const date = new Date(result[0].time)

                    const exists = array.find(x => x.month === date.getMonth()+1)
    
                    var formatedDate = ''
    
                    if(!exists){
                        formatedDate = `${date.getDate()} de ${mesesRedux[date.getMonth()]}`;
                    }else{
                        formatedDate = `${date.getDate()}`;
                    }
    
    
                    array.push({
                        x: formatedDate,
                        y: result.length,
                        month: date.getMonth()+1,
                    })
                }
            }
        }


        setChartData({
            line1: {
                filters: filters,
                data: array
            }
        })
    }

    const removeLine = (line) => {
        let obj = chartFilters;

        delete obj[line]

        setTableRows(tableRowsBackup)
        setClearTable(!clearTable);

        const newKeys = Object.keys(obj)

        setTimeout(() => {
            setTableDefaultFilters(obj[newKeys[newKeys.length-1]].filters)
            
            setChartFilters(obj)
            setSelectedLine(newKeys[newKeys.length-1]);
        }, 300)
    }

    if(loadingData || !operatorsData){
        return <Container>
            <CustomLoadingPage />
        </Container>
    }

    return (
        <Container>
            <Header>
                <h1>Dashboard</h1>
                <div>
                    <CustomFilterHeader 
                        tableRowsBackup={reports}
                        data={reports}
                        filterTypes={[{ headerName: 'Data', value: 'time' }]}
                        onFilter={(newData, values) => {
                            if(newData.length > 0){
                                setCardData(newData)
                                setCalendarValues(values);
                            }else{
                                alert('Não encontrado')
                            }
                        }}
                        clearFilters={() => {
                            setCardData(cardDataBackup)
                        }}
                        defaultTitle={meses[currentMonth]}
                        filterType='atendimentos'
                        dateField='time'
                    />
                    <ProfileButton arrowColor='var(grey2)' />
                </div>
                
            </Header>
            <CardsContainer>
                <CustomCard onClick={() => {
                    setModalData({
                        array: fechados,
                        title: 'Contratos fechados',
                        type: null,
                        columns: 5,
                        filterTypes: null,
                        tableColumns: tableColumnsReturns
                    });
                    setModalDashboardCard(true)
                }}>
                    <CardTitle type='contratos'>
                        <img src={checkedIcon} alt="icon icon" />
                        <h1>Contratos fechados</h1>
                    </CardTitle>
                    <CardDivider />
                    <CardContent type='contratos'>
                        <h1 className="value">{fechados.length}</h1>
                        <h1 className="description">contratos</h1>
                    </CardContent>
                </CustomCard>

                <CustomCard onClick={() => {
                    setModalData({
                        array: visitas,
                        title: 'Clientes visitados',
                        type: null,
                        columns: 5,
                        filterTypes: null,
                        tableColumns: tableColumnsReturns
                    });
                    setModalDashboardCard(true)
                }}>
                    <CardTitle type='visitas'>
                        <img src={listIcon} alt="icon icon" />
                        <h1>Clientes visitados</h1>
                    </CardTitle>
                    <CardDivider />
                    <CardContent type='visitas'>
                        <h1 className="value">{visitas.length}</h1>
                        <h1 className="description">visitas</h1>
                    </CardContent>
                </CustomCard>

                <CustomCard onClick={() => {
                    setModalData({
                        array:  winnerContratos.array,
                        title: 'Ranking do mês',
                        type: 'contratos',
                        columns: 4,
                        filterTypes: null,
                        tableColumns: tableColumnsReturns
                    });
                    setModalDashboardCard(true)
                }}>
                    <CardTitle type='contratos'>
                        <img src={winnerIcon} alt="icon icon" />
                        <h1>Ranking do mês</h1>
                    </CardTitle>
                    <CardDivider />
                    <CardContent type='contratos'>
                        <h1 className="value"> {winnerContratos ? winnerContratos.qtd : "0"}</h1>
                        <h1 className="description">contratos</h1>
                        {winnerContratos && winnerContratos.qtd > 0 && (
                            <>
                                <Avatar
                                    alt="Remy Sharp"
                                    sx={{ width: 26, height: 26, cursor: 'pointer' }}
                                    src={avatar.getUserAvatar(users.operators, winnerContratos.operatorUid)}
                                />

                                <h1 className="operatorName">{winnerContratos.operatorName}</h1>
                            </>
                        )}
                        
                    </CardContent>
                </CustomCard>

                <CustomCard onClick={() => {
                    setModalData({
                        array:  winnerVisitas.array,
                        title: 'Campeão de visitas',
                        type: 'visitas',
                        columns: 4,
                        filterTypes: null,
                        tableColumns: tableColumnsReturns
                    });
                    setModalDashboardCard(true)
                }}>
                    <CardTitle type='visitas'>
                        <img src={medalIcon} alt="icon icon" />
                        <h1>Campeão de visitas</h1>
                    </CardTitle>
                    <CardDivider />
                    <CardContent type='visitas'>
                        <h1 className="value">{winnerVisitas ? winnerVisitas.qtd : "0"}</h1>
                        <h1 className="description">visitas</h1>
                        {winnerVisitas && winnerVisitas.qtd > 0 && (
                            <>
                                <Avatar
                                    alt="Remy Sharp"
                                    sx={{ width: 26, height: 26, cursor: 'pointer' }}
                                    src={avatar.getUserAvatar(users.operators, winnerVisitas.operatorUid)}
                                />
                                <h1 className="operatorName">{winnerVisitas.operatorName}</h1>
                            </>
                            
                        )}
                    </CardContent>
                </CustomCard>
            </CardsContainer> 

            <OperatorsCardDashboard
                data={operatorsData}
                onFilter={(newData) => {
                    setSelectedLine('line1');

                    setTableRows(newData);
                    setTableRowsBackup(newData);

                    filterFirstLine(newData, null, chartRange);
                }}
                unFilter={() => {
                    setTableRows(operatorsDataBackup)
                    setTableRowsBackup(operatorsDataBackup);
                    filterNewChart(operatorsDataBackup, 'line1', null, chartRange);
                }}
            />
            
            <ChartContainer>
                <ChartHeader>
                    <div>
                        <HiOutlineChartBar color='var(--red)' />
                        <h3>Gráficos</h3>
                    </div>
                    <RangeSelectContainer>
                        <h3>Selecionar intervalo: </h3>
                        <RangeSelectButton 
                            selected={chartRange == 'dia' ? true : false} 
                            onClick={() => setChartRange('dia')}
                        >
                            <h4>Dia</h4>
                        </RangeSelectButton>
                        <RangeSelectButton 
                            selected={chartRange == 'mes' ? true : false} 
                            onClick={() => setChartRange('mes')}
                        >
                            <h4>Mês</h4>
                        </RangeSelectButton>
                    </RangeSelectContainer>
                </ChartHeader>

                {chartData['line1'] && (
                    <VictoryChart height={400} width={1000} 
                        containerComponent={
                            <VictoryVoronoiContainer style={{ width: '100%' }} 
                        />}
                    >
                        <VictoryAxis dependentAxis
                            style={{ tickLabels: { fontSize: 10, color: 'var(--grey2)' }}}
                        />
                        <VictoryAxis
                            style={{ tickLabels: { fontSize: 10, color: 'var(--grey2)' }}}
                        />
                        <VictoryGroup
                            color="var(--red)"
                            labels={({ datum }) => `${datum.y}`}
                            labelComponent={
                                <VictoryTooltip
                                    style={{ fontSize: 12 }}
                                />
                            }
                            data={chartData['line1'].data}
                            style={{ fontSize: 5}}
                        >
                            <VictoryLine 
                                style={{ data: { stroke: "var(--red)", strokeWidth: 4 } }}
                            />
                            <VictoryScatter
                                size={({ active }) => active ? 5 : 2}
                            />
                        </VictoryGroup>
                        {chartData['line2'] && (
                            <VictoryGroup
                                color="var(--blue4)"
                                labels={({ datum }) => `${datum.y}`}
                                labelComponent={
                                    <VictoryTooltip
                                        style={{ fontSize: 12 }}
                                    />
                                }
                                data={chartData['line2'].data}
                                style={{ fontSize: 5}}
                            >
                                <VictoryLine 
                                    style={{ data: { stroke: "var(--blue4)", strokeWidth: 4 } }}
                                />
                                <VictoryScatter
                                    size={({ active }) => active ? 5 : 2}
                                />
                            </VictoryGroup>
                        )}

                        {chartData['line3'] && (
                            <VictoryGroup
                                color="var(--aqua)"
                                labels={({ datum }) => `${datum.y}`}
                                labelComponent={
                                    <VictoryTooltip
                                        style={{ fontSize: 12 }}
                                    />
                                }
                                data={chartData['line3'].data}
                                style={{ fontSize: 5}}
                            >
                                <VictoryLine 
                                    style={{ data: { stroke: "var(--aqua)", strokeWidth: 4 } }}
                                />
                                <VictoryScatter
                                    size={({ active }) => active ? 5 : 2}
                                />
                            </VictoryGroup>
                        )}
                    </VictoryChart>
                )}
                
                <CustomDivider />

                <ChartLinesContainer>
                    <ChartLineFilter 
                        color='var(--red)'
                        selected={selectedLine == 'line1' ? true : false}
                        onClick={() => {
                            setTableDefaultFilters(chartFilters['line1'].filters)
                            setSelectedLine('line1')
                        }}
                    >
                        <PointIcon 
                            backgroundColor='var(--red)'
                            size={10}
                        />
                        <h1>Linha vermelha:</h1>
                        {chartFilters['line1'] && chartFilters['line1'].filters && Object.keys(chartFilters['line1'].filters).map((item, index) => {
                            
                            if(item == 'data'){
                                return (
                                    <ChartLineItem key={index}>
                                        <h4>{chartFilters['line1'].filters[item].selectedStart.day}</h4>
                                    </ChartLineItem>
                                )
                            }else{
                                return (
                                    <ChartLineItem key={index}>
                                        <h4>{chartFilters['line1'].filters[item].length > 1 
                                            ? `${chartFilters['line1'].filters[item][0].label} + ${chartFilters['line1'].filters[item].length-1}`
                                            : chartFilters['line1'].filters[item][0].label
                                        }</h4>

                                    </ChartLineItem>
                                )
                            }
                        })}
                    </ChartLineFilter>

                    {chartFilters['line2'] && (
                        <ChartLineFilter 
                            color='var(--blue4)'
                            selected={selectedLine == 'line2' ? true : false}
                            onClick={() => {
                                setTableDefaultFilters(chartFilters['line2'].filters)
                                setSelectedLine('line2')
                            }}
                        >
                            <PointIcon 
                                backgroundColor='var(--blue4)'
                                size={10}
                            />
                            <h1>Linha azul:</h1>
                            {chartFilters['line2'] && chartFilters['line2'].filters && Object.keys(chartFilters['line2'].filters).map((item, index) => {
                                
                                if(item == 'data'){
                                    return (
                                        <ChartLineItem key={index}>
                                            <h4>{chartFilters['line2'].filters[item].selectedStart.day}</h4>
                                        </ChartLineItem>
                                    )
                                }else{
                                    return (
                                        <ChartLineItem key={index}>
                                            <h4>{chartFilters['line2'].filters[item].length > 1 
                                                ? `${chartFilters['line2'].filters[item][0].label} + ${chartFilters['line2'].filters[item].length-1}`
                                                : chartFilters['line2'].filters[item][0].label
                                            }</h4>
                                        </ChartLineItem>
                                    )
                                }
                            })}
                            <MdRemoveCircleOutline 
                                size={18} 
                                cursor='pointer' 
                                onClick={() => removeLine('line2')} 
                            />
                        </ChartLineFilter>
                    )}

                    {chartFilters['line3'] && (
                        <ChartLineFilter 
                            color='var(--aqua)'
                            selected={selectedLine == 'line3' ? true : false}
                            onClick={() => {
                                setTableDefaultFilters(chartFilters['line3'].filters)
                                setSelectedLine('line3')
                            }}
                        >
                            <PointIcon 
                                backgroundColor='var(--aqua)'
                                size={10}
                            />
                            <h1>Linha verde:</h1>
                            {chartFilters['line3'] && chartFilters['line3'].filters && Object.keys(chartFilters['line3'].filters).map((item, index) => {
                                
                                if(item == 'data'){
                                    return (
                                        <ChartLineItem key={index}>
                                            <h4>{chartFilters['line3'].filters[item].selectedStart.day}</h4>
                                        </ChartLineItem>
                                    )
                                }else{
                                    return (
                                        <ChartLineItem key={index}>
                                            <h4>{chartFilters['line3'].filters[item].length > 1 
                                                ? `${chartFilters['line3'].filters[item][0].label} + ${chartFilters['line3'].filters[item].length-1}`
                                                : chartFilters['line3'].filters[item][0].label
                                            }</h4>
                                        </ChartLineItem>
                                    )
                                }
                            })}
                            <MdRemoveCircleOutline 
                                size={18} 
                                cursor='pointer' 
                                onClick={() => removeLine('line3')} 
                            />
                        </ChartLineFilter>
                    )}
                </ChartLinesContainer>

                {Object.keys(chartFilters).length < 3 && (
                    <AddLineButton 
                        onClick={() => {
                            setTableRows(tableRowsBackup)
                            setClearTable(!clearTable);

                            var nextLine = null

                            for(const line of lines){
                                if(!chartFilters[line] && !nextLine){
                                    nextLine = line
                                }
                            }
                            
                            setSelectedLine(nextLine)
                            setChartFilters({
                                ...chartFilters,
                                [nextLine]: {
                                    filters: null,
                                    data: operatorsDataBackup
                                }
                            })

                            setTableDefaultFilters('');
                        }}
                    >
                        <IoMdAddCircleOutline size={18} />
                        Adicionar linha
                    </AddLineButton>
                )}
            </ChartContainer>
           
            <CustomTable 
                title='Clientes atendidos'
                tableColumns={tableColumnsReturns} 
                tableRows={tableRows} 
                onFilter={(newData, filters) => {
                    if(newData && newData.length > 0){
                        setChartFilters({
                            ...chartFilters,
                            [selectedLine]: {
                                filters: filters,
                                data: newData,
                            }
                        })                        
                    }
                }}
                clearAllFilters={clearTable}
                onClearFilters={() => {
                   
                }}
                tableDefaultFilters={tableDefaultFilters}
                tableRowsBackup={tableRowsBackup}
                filterTypes={tableColumnsReturns} 
                columns={7}
                filtersHighlights={[
                    {
                        field: 'time',
                        headerName: 'Data'
                    },
                    {
                        field: 'operatorName',
                        headerName: 'Operador'
                    },
                    {
                        field: 'result',
                        headerName: 'Resultado'
                    }
                ]}
                dateField='time'
            />

            <Modal
                isOpen={modalDashboardCard}
                onRequestClose={() => setModalDashboardCard(false)}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalDashboardCard data={modalData} onClose={() => setModalDashboardCard(false)}/>
            </Modal>
        </Container>
    )
}

export default DashboardPage;