import React, { useState, createContext } from "react";

import { collection, query, onSnapshot, where } from "firebase/firestore";
import db from '../firebase/config';
import { arrayHelper } from "../helpers/arrayHelper";

export const RoutesContext = createContext();

const date = new Date();

export const RoutesProvider = (props) => {
    const { children } = props;

    const [ routes, setRoutes ] = useState('');
    const [ routesUser, setRoutesUser ] = useState('');

    const clearRoutesContext = async () => {
        setRoutes([])
        setRoutesUser('');
        return
    }

    const getRoutesUser = async (user) => {
        const consulta = query(collection(db, 'rotas'), where("uid", "==", user.uid), where("status", "in", ["aguardando", "iniciado"]));
        await onSnapshot(consulta, (querySnapshot) => {
            const database = [];
            const data = querySnapshot.docs;

            for(const i in data){
                database.push({...data[i].data(), docId: data[i].id});
            }
            console.log("database: ", database)
            setRoutesUser(database); 
        });                  
    }

    //Get routes manager
    const getRoutesManager = async (operatorsManager) => {

        if(operatorsManager.length == 0){
            return setRoutes([]);
        }

        const operatorsUid = arrayHelper.reduceToSimple(operatorsManager, 'uid');

        const consulta = query(collection(db, 'rotas'), where('uid', 'in', operatorsUid));
        await onSnapshot(consulta, (querySnapshot) => {
            const database = [];
            
            const data = querySnapshot.docs;
            
            for(const i in data){
                database.push({...data[i].data(), docId: data[i].id});
            }
        
            setRoutes(database);
        });
    }

    //Get routes admin
    const getRoutesAdmin = async () => {
        const consulta = query(collection(db, 'rotas'));
        await onSnapshot(consulta, (querySnapshot) => {
            const database = [];
            
            const data = querySnapshot.docs;
            
            for(const i in data){
                database.push({...data[i].data(), docId: data[i].id});
            }
            
            setRoutes(database);
        }, error => {
            console.log(error)
        });
    }

    return (
        <RoutesContext.Provider 
            value={{ 
                routes: routes,
                routesUser: routesUser,
                getRoutesAdmin,
                getRoutesManager,
                getRoutesUser,
                clearRoutesContext,
            }}
        >
            {children}
        </RoutesContext.Provider>
    )
}