import styled from 'styled-components';

export const Container = styled.div`
    /* display: flex; */
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 256px;

    padding: 20px 34px;

    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    height: 200vh;
    width: calc(100% - 256px);


    background: var(--background);
    /* z-index: 5000; */
`;

export const Header = styled.div`
    width: 100%;
    height: 67px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 20px;

    h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 38px;
        /* identical to box height */


        /* 1 */

        color: #4A5E83;
    }
`;

export const CardsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(5, 2fr);
    grid-column-gap: 20px;
    /* height: 400px; */
    /* max-height: 200px; */
    margin: 20px 0px;
`;

export const CustomCard = styled.div`
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    /* margin-top: 97px; */

    background: #FFFFFF;

    cursor: pointer;

    box-shadow: 0px 4px 20px rgba(35, 54, 63, 0.05);
    border-radius: 20px;
`;

export const Frame = styled.div`
    width: 22px;
    height: 19px;
`;

export const CardTitle = styled.div`
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;

    h1{   
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        max-width: 80%;
        text-align: center;
        user-select: none;
        
        color: #808B9F;
    }

    .icon{
        color: ${props => props.color};
        fill:  ${props => props.color};
    }
`;

export const CardDivider = styled.div`
    border: 1px solid #ECF2FB;
    margin: 0px 20px;
`;

export const CardContent = styled.div`
    height: 97px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    gap: 10px;

    .value{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 24px;
        /* or 60% */
        user-select: none;

        /* 83DBD6 */

        color: ${props => props.color};
    }
`;
