import React, { useContext } from 'react';

import { Container, Content, ActionsContent, ConfirmButton, ModalText } from './styles';

import { IoWarningOutline } from 'react-icons/io5';

import { getAuth, signOut } from "firebase/auth";

import { AuthContext } from '../../contexts/AuthContext';
import { ClientsContext } from '../../contexts/ClientsContext';
import { DatabaseContext } from '../../contexts/DatabaseContext';
import { MapsContext } from '../../contexts/MapsContext';
import { ReportsContext } from '../../contexts/ReportsContext';
import { ReturnsContext } from '../../contexts/ReturnsContext';
import { RoutesContext } from '../../contexts/RoutesContext';
import { UsersContext } from '../../contexts/UsersContext';
import { useNavigate } from 'react-router-dom';
import { addUserLogs } from '../../firebase/logs/users/addUserLogs';

const NoMapModal = () => {
    const auth = getAuth();

    const { user, userData, clearAuthContext } = useContext(AuthContext);
    const { clearClientsContext }  = useContext(ClientsContext);
    const { clearDatabaseContext }  = useContext(DatabaseContext);
    const { clearMapsContext } = useContext(MapsContext);
    const { clearReportsContext } = useContext(ReportsContext);

    const { clearReturnsContext } = useContext(ReturnsContext);
    const { clearRoutesContext } = useContext(RoutesContext);
    const { clearUsersContext } = useContext(UsersContext);

    const navigate = useNavigate();

    const clearAllContexts = () => {
        clearAuthContext();
        clearClientsContext();
        clearDatabaseContext();
        clearMapsContext();
        clearReportsContext();
        clearReturnsContext();
        clearRoutesContext();
        clearUsersContext();
    }

    const logout = async () => {
        clearAllContexts();
        const logMessage = `${userData.userName} saiu do sistema (Web)`;
                
        await addUserLogs(user.uid, logMessage)
        await signOut(auth).then(async () => {
            navigate('/')
        }).catch((error) => {
            alert('Ocorreu um erro ao sair.')
        });
    }

    return (
        <Container>
            <Content>
                <IoWarningOutline size={80}/>
                <ModalText>
                    Você não possui acesso ao mapa. Entre em contato com o seu gerente ou administrador para configurar sua conta.
                </ModalText>
            </Content>
            <ActionsContent>
                <ConfirmButton onClick={() => logout()}>
                    <text>Sair</text>
                </ConfirmButton>
            </ActionsContent>
        </Container>
    )
}

export default NoMapModal;