import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 10px;
`;

export const Item = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const RowItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 10px;
`;

export const HelpText = styled.h1`
    margin: 5px 0;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    /* 2 */

    color: #808B9F;
`;

export const NewEndBar = styled.div`
    /* width: 400px; */
    display: flex;
    width: 100%;
    /* flex: 1; */
    padding-left: 10px;

    border: 1px solid #B4C1D5;
    border-radius: 100px;

    input {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */
        width: 100%;

        /* 2 */

        color: #808B9F;
    }
`;

export const SaveNewEndButton = styled.div`
    background: ${props => props.disabled ? "#808B9F" : "#DE6161" };
    border-radius: 100px;
    align-self: flex-end;

    cursor: ${props => props.disabled ? "auto" : "pointer"};
    user-select: none;

    padding: 7px 43px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    color: #FFFFFF;
`;