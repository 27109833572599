import styled from "styled-components";

export const ContainerLoading = styled.div`
    height: 100vh;
    width: 100vw;
    background: #9a4749;

    display: flex;
    justify-content: center;
    align-items: center;
`;