import { isEmpty } from "lodash";
import { defaultClientObj } from "../constants/defaultClientObj";
import { defaultRemoveObj } from "../constants/defaultRemoveObj";
import { validEspecies } from "../constants/validEspecies";
import { requiredImportHeader } from "../constants/requiredImportHeader";
import { requiredRemoveHeader } from "../constants/requiredRemoveHeader";

import importCsvModel from '../resources/importCsvModel.json';
import removeCsvModel from '../resources/removeCsvModel.json';

export const importCsvHelper = {
    validateHeader,
    processCsv,
    validateRemoveHeader,
    processRemoveCsv
}

function normalizeText(text){
    if(text){
        return text.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
    }

    return text
}

function formatCpf(cpf){    
    if(cpf.length != 11){
        const zeroToAdd = 11 - cpf.length;

        let newCpf = ''

        for(let i = 0; i < zeroToAdd; i = i+1){
            newCpf = newCpf + '0'
        }

        newCpf = newCpf + cpf

        if(newCpf.length == 11){
            return newCpf;
        }else{
            return null
        }
    }else{
        return cpf
    }
}

function corrigirDDD(tel){
    const valid = ['19', '39', '49'];

    const texto = tel.replace(/\D/g, "");

    const ddd = texto[0] + texto[1];

    const isValid = valid.find(x => x === ddd);

    if(isValid){
        const newDDD = 8 + texto[0];

        return `(${newDDD}) 9${texto.slice(2, texto.length-4)}.${texto.slice(6, texto.length)}`
    }else{
        return tel
    }
}

function calculateAlertBirthday(dataNasc){
    const month = dataNasc.slice(3,5);
    const ano = Number(dataNasc.slice(-4))+65;

    var alertMonth;
    if(Number(month)-6 < 0){
        alertMonth = 12+(Number(month)-6)
    }else{
        alertMonth = month-6
    }

    const dateNow = new Date(`${alertMonth}-20-${ano}`);

    return dateNow.getTime();
}

function validateEspecie(especie){
    return validEspecies.find(x => x === especie);
}

function validateHeader(headerArray){
    let result = {
        valid: true,
        invalidFields: []
    }

    const invalidArray = []

    for(const item of requiredImportHeader){
        if(item){
            const isValid = headerArray.find(x => normalizeText(x) == normalizeText(item));
    
            if(!isValid){
                invalidArray.push(item)
            }
        }
    }

    if(invalidArray.length > 0){
        result['valid'] = false
        result['invalidFields'] = invalidArray
    }

    return result
}

function processCsv(lines, header){
    const errors = []
    const array = []
    
    for(const line of lines){
        var client = {};

        const lineColums = line.split(';')

        for(const index in lineColums){
            const headerName = normalizeText(header[index]);
            const value = lineColums[index];

            if(headerName && headerName.includes('ESTADO-CIDADE') && value && value != 'N/D'){
                const sigla = headerName.slice(headerName.length-2, headerName.length+1);
                
                if(value.includes('-')){
                    const arrayValue = value.split('-') 

                    client[`estado${sigla}`] = arrayValue[0].trim();
                    client[`cidade${sigla}`] = arrayValue[1].trim();

                    if(!client['cidadeMap'] && value){
                        client['cidadeMap'] = `${arrayValue[0].trim()} - ${arrayValue[1].trim()}`;
                    }
                }else{
                    errors.push("Erro na importação! a planilha não está de acordo com o padrão no campo ESTADO-CIDADE.")
                }                
            }else{
                const fieldObj = importCsvModel.find(x => x.headerName == headerName);

                if(fieldObj && value){
                    const { field } = fieldObj;
    
                    switch(headerName){
                        case "CPF":
                            const formatedCpf = formatCpf(value)
                            if(formatedCpf){
                                client[field] = formatedCpf
                            }else{
                                errors.push("Erro na importação! falha na formatação dos cpfs para 11 dígitos")
                            }
                            break
                        case "DATA DE NASCIMENTO":
                            client[field] = value
                            client['alertBirthday'] = calculateAlertBirthday(value)
                            break
                        case "ESPECIE":
                            if(validateEspecie(value)){
                                client[field] = value
                            }else{
                                errors.push("Erro na importação! a planilha não está de acordo com o padrão na coluna 'ESPÉCIE'")
                            }
                            break
                        case "TELEFONE PRINCIPAL":
                            const formatedTelPrinc = corrigirDDD(value);
                            if(formatedTelPrinc){
                                client[field] = formatedTelPrinc
                            }
                            break
                        case "TELEFONE SECUNDARIO":
                            const formatedTelSec = corrigirDDD(value);
                            if(formatedTelSec){
                                client[field] = formatedTelSec
                            }
                            break
                        default:
                            client[field] = value
                            break
                    }
                }
            }
        }

        if(!isEmpty(client['cpf'])){
            array.push({
                ...defaultClientObj,
                ...client
            })
        }
    }
    
    return {
        errors: errors,
        array: array
    }
}

function validateRemoveHeader(headerArray){
    let result = {
        valid: true,
        invalidFields: []
    }

    const invalidArray = []

    for(const item of requiredRemoveHeader){
        if(item){
            const isValid = headerArray.find(x => normalizeText(x) == normalizeText(item));
    
            if(!isValid){
                invalidArray.push(item)
            }
        }
    }

    if(invalidArray.length > 0){
        result['valid'] = false
        result['invalidFields'] = invalidArray
    }

    return result
}

function processRemoveCsv(lines, header){
    const errors = []
    const array = []
    
    for(const line of lines){
        var client = {};

        const lineColums = line.split(';')

        for(const index in lineColums){
            const headerName = normalizeText(header[index]);
            const value = lineColums[index];

            const fieldObj = removeCsvModel.find(x => x.headerName == headerName);

            if(fieldObj && value){
                const { field } = fieldObj;

                if(headerName == 'CPF'){
                    client[field] = formatCpf(value)
                }else{
                    client[field] = value
                }
            }      
        }

        if(!isEmpty(client['cpf'])){
            array.push({
                ...defaultRemoveObj,
                ...client
            })
        }
    }
    
    return {
        errors: errors,
        array: array
    }
}