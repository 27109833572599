import styled from "styled-components";

export const Container = styled.div`
    /* display: flex; */
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 256px;

    padding: 20px 34px;

    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    height: 200vh;
    width: calc(100% - 256px);


    background: var(--background);
    /* z-index: 5000; */
`;

export const Header = styled.div`
    width: 100%;
    height: 67px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 20px;

    div {
        display: flex;
        
        align-items: center;

        gap: 20px;
    }

    h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 38px;
        /* identical to box height */


        /* 1 */

        color: #4A5E83;
    }

    div {
        display: flex;
        flex-direction: row;

        gap: 20px;
        align-items: center;
    }
`;

export const LogsContainer = styled.div`
    height: 92%;
    width: 100%;
    background: #FFFFFF;

    padding: 40px 10px;
    /* shadowcard */

    box-shadow: 0px 4px 20px rgba(35, 54, 63, 0.05);
    border-radius: 20px;
`;

export const LogsList = styled.div`
    height: 100%;

    padding: 20px 0;
    overflow-y: auto;
`;

export const NumberOfLines = styled.div`
    display: flex;
    flex-direction: row;

    align-items: flex-end;
    justify-content: flex-end;
    
    padding: 5px 15px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    color: #808B9F;
`;

export const LogItem = styled.div`
    display: grid;
    grid-template-columns: 0.1fr 1fr;

    align-items: initial;

    min-height: 40px;
    max-width: 90%;

    gap: 10px;

    div {
        display: flex;
        flex-direction: column;

        /* justify-content: center; */
        align-items: center;
    }

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        padding-bottom: 10px;

        /* 1 */

        color: #4A5E83;
    }
`;

export const Circle = styled.div`
    min-width: 11px;
    min-height: 11px;
    background: #68CFC9;

    border-radius: 100px;
`;

export const Line = styled.div`
    width: 1px;
    height: 100%;
    background: #68CFC9;
`;