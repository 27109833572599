import React, { useContext, useState, useEffect } from 'react';

import { 
    Container,
    Options,
    RouteName,
} from './styles';

//Firebase and context
import { UsersContext } from '../../contexts/UsersContext';

//Icons
import { BiMap } from 'react-icons/bi';
import { AiOutlineEye } from 'react-icons/ai';

//MUI
import { Avatar } from '@mui/material';
import StatusIcon from '../StatusIcon';
import ProgressIcon from '../ProgressIcon';

//utils
import { datetime } from '../../helpers/datetime';
import { avatar } from '../../helpers/avatar';

function RouteItem({ data, columns, history, onViewRoute }) {

    const { users } = useContext(UsersContext);

    const [ routeData, setRouteData ] = useState('');

    useEffect(() => {
        var atendido = 0;
        for(const point of data.points){
            if(point.atendido){
                atendido++
            }
        }
        if(atendido < data.points.length && data.status === 'finalizado'){
            setRouteData({
                ...data,
                status: 'parcial'
            })
        }else{
            setRouteData(data)
        }
    }, [data]);

    if(!routeData){
        return null
    }

    return (
        <Container columns={columns}>
            <div>
                <BiMap size={15} color='var(--grey2)' />
                <RouteName>{routeData.name}</RouteName>
            </div>
            <div>
                <Avatar
                    alt="Avatar"
                    src={avatar.getUserAvatar(users.operators, routeData.uid)}
                    sx={{ 
                        width: 20, 
                        height: 20, 
                        borderRadius: 100, 
                        borderColor: 'white', 
                        borderWidth: 10 
                    }}
                />
                <h4>{routeData.operatorName}</h4>
            </div>
            {history && (
                <h4>{routeData.routeCloseDate && (datetime.getDate(routeData.routeCloseDate))}</h4>
            )}
            {!history && (
                <div>
                    <StatusIcon status={routeData.status} />
                </div>
            )}
            
            {!history && (
                <div>
                    <h4>{datetime.getDate(routeData.time)}</h4>
                </div>
            )}
            
            {routeData.status != 'aguardando' && routeData.status != 'cancelado' && !history && (
                <h4>{datetime.getFullDate(routeData.routeStartDate)}</h4>
            )}

            {routeData.status != 'aguardando' && routeData.status != 'iniciado' && !history  && (
                <h4>{datetime.getFullDate(routeData.routeCloseDate)}</h4>
            )}
            <ProgressIcon data={routeData} />
            <Options>
                <AiOutlineEye 
                    onClick={onViewRoute} 
                    cursor='pointer' 
                    size={20} 
                    color='#808B9F' 
                    fill='#808B9F'
                />
            </Options>
        </Container>
    )
}

export default RouteItem;