import React, { Component } from 'react';

import { uniqueId } from 'lodash';

import Select from 'react-select'

import { Input, Form, FieldText, InputContainer } from './styles';

import importIcon from '../../assets/importIcon.svg'
import { alertErrorStyle } from '../../resources/alertErrorStyle';
import { toast } from 'react-toastify';
import { importCsvHelper } from '../../helpers/importCsvHelper';
import { arrayHelper } from '../../helpers/arrayHelper';

export default class Upload extends Component {
    state = {
        csvFile: '',
        loading: false,
        selectedOption: '',
    }

    setCsvFile = (csvFile) => {
        this.setState({ csvFile });
    }

    importCsvFile = (csvFile) => {
        const reader = new FileReader();

        const dateNow = new Date();

        const { selectedOption } = this.state;

        this.setState({ loading: true });

        reader.onload = (e) => {
            const text = e.target.result;

            const splitFile = text.split('\r\n');
            const [header, ...files] = splitFile;

            const headerSplit = header.split(';');

            const { valid, invalidFields } = importCsvHelper.validateHeader(headerSplit);

            if(!valid){
                return toast(`A planilha selecionada não possui as colunas obrigatórias: ${
                    arrayHelper.arrayToString(invalidFields, ', ')
                }`, alertErrorStyle)
            }

            const { array, errors } = importCsvHelper.processCsv(files, headerSplit);

            if(errors.length == 0){
                this.props.setData(selectedOption, array);
                this.setState({ loading: false });
            }else{
                for(const errorMessage of errors){
                    toast(errorMessage, alertErrorStyle)
                }
            }
            
        }

        reader.readAsText(csvFile);
    }

    importCsvFileAuxBrasil = (csvFile) => {
        const reader = new FileReader();

        this.setState({ loading: true });

        reader.onload = (e) => {
            const text = e.target.result;

            const splitFile = text.split('\r\n');
            const [header, ...files] = splitFile;
            const array = [];

            for(const i of files){
                if(i === ''){
                    break
                }
                const column = i.split(';')
                
                array.push({
                    id: uniqueId(),
                    cpf: column[2],
                    nome: column[1],
                })
            }

            this.props.setData('auxBrasil', array);
            this.setState({ loading: false });
        }

        reader.readAsText(csvFile);
    }

    importCsvFileDeferidos = (csvFile) => {
        const reader = new FileReader();

        this.setState({ loading: true });

        reader.onload = (e) => {
            const text = e.target.result;

            const splitFile = text.split('\r\n');
            const [header, ...files] = splitFile;
            const array = [];

            for(const i of files){
                if(i === ''){
                    break
                }
                const column = i.split(';')
                
                array.push({
                    id: uniqueId(),
                    cpf: column[1],
                    nome: column[3],
                })
            }

            this.props.setData('deferidos', array);
            this.setState({ loading: false });
        }

        reader.readAsText(csvFile);
    }

    render(){

        const { csvFile, loading, selectedOption } = this.state;

        const options = [
            { value: 'indeferidos', label: 'Indeferidos Mapa' },
            { value: 'indeferidosDb', label: 'Indeferidos Banco de Dados'},
            { value: 'deferidos', label: 'Deferidos' },
            { value: 'auxBrasil', label: 'Auxílio Brasil' }
        ]

        return (
            <Form>
                <FieldText>
                    Selecione o tipo de importação
                </FieldText>
                <Select 
                    id='select-import'  
                    options={options} 
                    styles={{      
                        container: (base) => ({
                            ...base,
                            borderRadius: 40
                        }),
                        control: (base) => ({
                            ...base,
                            padding: 3,
                            borderRadius: 40            
                        }),
                        multiValue: (base) => ({
                            ...base,
                            borderRadius: 40,
                            backgroundColor: '#ECF2FB'
                        }),
                    }}
                    onChange={(selectedOption) => {
                        if(csvFile){
                            this.fileInput.value = "";
                            this.setCsvFile('');
                            this.props.optionChanged()
                        }
                        this.setState({selectedOption: selectedOption.value})
                    }}
                />
                {selectedOption
                    ? <InputContainer>
                        <div>
                            <img src={importIcon} alt='img img' />
                            <h3>{this.state.csvFile ? 'Arquivo selecionado' : 'Escolher arquivo...'}</h3>
                        </div>
                        
                        <Input
                            type='file'
                            accept='.csv'
                            id='csvfile'
                            ref={ref=> this.fileInput = ref}
                            placeholder=''
                            value={""}
                            onChange={async (e) => {
                                this.setCsvFile(e.target.files[0])
                                if(selectedOption === 'indeferidos'){
                                    return this.importCsvFile(e.target.files[0]);
                                }

                                if(selectedOption === 'indeferidosDb'){
                                    return this.importCsvFile(e.target.files[0]);
                                }

                                if(selectedOption === 'auxBrasil'){
                                    return this.importCsvFileAuxBrasil(e.target.files[0]);
                                }

                                if(selectedOption === 'deferidos'){
                                    return this.importCsvFileDeferidos(e.target.files[0]);
                                }
                                
                            }}
                        /> 
                    </InputContainer>
                    : null
                }
            </Form>
            
            );

    }
  
}