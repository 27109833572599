import React from 'react';

import { Container, Text } from './styles';

import DotLoader from "react-spinners/ClipLoader";

const CustomLoading = ({ text, background }) => {
    return (
        <Container background={background}>
            <DotLoader
                size={60}
                color={"var(--red)"}
                loading={true}
                speedMultiplier={1.5}
            />
            <Text>{text}</Text>
        </Container>
    )
}

export default CustomLoading;