import React, { useMemo, useEffect, useState, useContext } from 'react'

import { 
    Container, 
    RouteBar,
    MapContainer,
    LogoContainer,
    RouteBarHeader,
    HeaderTitle,
    NewRouteButton,
    HeaderLeft,
    RouteBarContent,
    NewRouteContainer,
    FieldText,
    TextInput,
    TextSection,
    RoutePointsContainer,
    Divider,
    ActionsContent,
    CancelButton,
    SaveButton,
    CustomInfoWindow,
    AddRouteButton,
    InfoWindowButtonsContainer,
    InfoWindowTextContainer,
    MapDivRight,
    ReturnMapButton,
    SavedRoutesContainer,
    ReturnMapDiv,
    ReturnMapDivHeader,
    DividerReturn,
    ReturnsContainer,
    ReturnCard,
    DividerReturnItem,
    ReturnsContent,
    NoRoutesContainer,
    NoRouteButton,
    HelpButton,
    MarkersLegend,
    LegendItem,
} from './styles';

// import './styles.css';

//Firebase and context
import { AuthContext } from '../../contexts/AuthContext';
import { RoutesContext } from '../../contexts/RoutesContext';
import { ReportsContext } from '../../contexts/ReportsContext';
import { collection, getDoc, doc, updateDoc, addDoc, arrayUnion, writeBatch } from "firebase/firestore";
import db from '../../firebase/config';

//Google Maps API
import { GoogleMap, useJsApiLoader, Marker, DirectionsRenderer, InfoWindow, StreetViewPanorama } from '@react-google-maps/api';

//Utils
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Switch from '@mui/material/Switch';
import Modal from 'react-modal';
import { toast } from 'react-toastify';

//Icons
import { MdAddCircleOutline, MdOutlineAdd, MdOutlineRemove } from 'react-icons/md';
import { AiOutlineCloseSquare } from 'react-icons/ai';
import { BsArrowLeft } from 'react-icons/bs';
import { FiCheckSquare } from 'react-icons/fi';
import { BiHelpCircle } from 'react-icons/bi';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import { markerIcons } from '../../resources/markerIcons';

//Images
import logo from '../../assets/central.png';
import emptyRoutes from '../../assets/emptyRoutes.svg';

//Custom Components
import SavedRouteCard from '../../components/SavedRouteCard';
import CustomLoading from '../../components/CustomLoading';
import ModalConfirm from '../../components/ModalConfirm';
import ClientDetailsOperator from '../../components/ClientDetailsOperator';
import ModalDetailsOperator from '../../components/ModalDetailsOperator';
import RoutePointItem from '../../components/RoutePointItem';
import ProfileButton from '../../components/ProfileButton';
import PointIcon from '../../components/PointIcon';
import ModalReport from '../../components/ModalReport';
import ModalReturnsOperator from '../../components/ModalReturnsOperator';
import ModalHeader from '../../components/ModalHeader';

//utils
import { address } from '../../helpers/address';
import { datetime } from '../../helpers/datetime';
import { markers as markersHelper } from '../../helpers/markers';
import { addUserLogs } from '../../firebase/logs/users/addUserLogs';
import { ClientsContext } from '../../contexts/ClientsContext';
import { addClientLogs } from '../../firebase/logs/clients/addClientLogs';
import { addRouteLogs } from '../../firebase/logs/routes/addRouteLogs';
import { ReturnsContext } from '../../contexts/ReturnsContext';
import { alertErrorStyle } from '../../resources/alertErrorStyle';
import NoMapModal from '../../components/NoMapModal';
import { alertWarningStyle } from '../../resources/alertWarningStyle';
import { arrayHelper } from '../../helpers/arrayHelper';
import ClipLoader from 'react-spinners/ClipLoader';

//Variables
const letras = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

const dateNow = new Date();

const MapPage = props => {

    const { user, userData } = useContext(AuthContext);
    const { routesUser, getRoutesUser } = useContext(RoutesContext);
    const { reportsUser, getReportsUser } = useContext(ReportsContext);
    const { clientsUser, getClientsUser } = useContext(ClientsContext);
    const { returnsUser, getReturnsUser } = useContext(ReturnsContext);

    const [ loadingData, setLoadingData ] = useState(true);

    //Modal
    const [ modalData, setModalData ] = useState('');
    const [ modalConfirm, setModalConfirm ] = useState(false);
    const [ modalDetails, setModalDetails ] = useState(false);
    const [ modalReturns, setModalReturns ] = useState(false);
    const [ modalReport, setModalReport ] = useState(false);
    const [ modalNoMap, setModalNoMap ] = useState(false);

    //Route creation
    const [ routeForm, setRouteForm ] = useState('');
    const [ routeInEdit, setRouteInEdit ] = useState('');
    const [ routeToDelete, setRouteToDelete ] = useState('');
    const [ routeName, setRouteName ] = useState('');
    const [ points, setPoints ] = useState([]);

    //Client
    const [ clientes, setClientes ] = useState('');
    const [ currentClient, setCurrentClient ] = useState('');
 
    //Map
    const [ mapRef, setMapRef ] = useState(null);
    const [ lastZoom, setLastZoom ] = useState('');
    const [ lastCenter, setLastCenter ] = useState('');
    const [ centerMap, setCenterMap ] = useState('');
    const [ mapZoom, setMapZoom ] = useState(12);
    const [ showInfoWindow, setShowInfoWindow ] = useState({});
    const [ streetView, setStreetView ] = useState(false);
    const [ markers, setMarkers ] = useState(false);
    const [ viewDetails, setViewDetails ] = useState(false);
    const [ directionsResponse, setDirectionsResponse ] = useState(null);
    const [ onlyReturns, setOnlyReturns ] = useState(false);
    const [ returnExpanded, setReturnExpanded ] = useState(false);
    const [ routeExpanded, setRouteExpanded ] = useState(false);
    const [ returns, setReturns ] = useState('');
    const [ legend, setLegend ] = useState(false);
    const [ saveLoading, setSaveLoading ] = useState(false);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    useEffect(() => {
        (async () => {
            setLoadingData(true);

            if(!clientes && !clientsUser && !returns){
                if(userData){
                    if(userData.mapsIds.length == 0){
                        setLoadingData(false)
                        setModalNoMap(true)
                    }else{
                        setModalNoMap(false)
                        await getClientsUser(userData.mapsIds, userData.especies, user.uid)
                        await getReportsUser(user);
                        await getRoutesUser(user);
                    }
                }
            }else{
                setOnlyReturns(false);
                await getReturnsUser(user);

                await calculateMapCenter(clientsUser)
        
                if(routeForm == 'Editar'){
                    // getToEdit(clientsUser);
                    //Não é necessário pois a edição não é salva no last route
                }else{
                    getLastRoute();
                }

                setLoadingData(false);
            }
        })();
    }, [userData, clientsUser, reportsUser]);

    useEffect(() => {
        if(returnsUser && reportsUser){
            processReturns();
        }
    }, [returnsUser, reportsUser])

    const calculateMapCenter = async (clientes) => {
        const arrayLat = [];
        const arrayLng = [];
        const valueBase = 9.897150820000008;

        for(const cliente of clientes){
            if(cliente.cidadeMap && cliente.lat && cliente.lng){
                arrayLat.push(cliente.lat);
                arrayLng.push(cliente.lng);
            }
        }

        if(arrayLat.length > 1 && arrayLng.length > 1){
            const centerLat = (getMaxOfArray(arrayLat) + getMinOfArray(arrayLat)) / 2
            const centerLng = (getMaxOfArray(arrayLng) + getMinOfArray(arrayLng)) / 2

            const dif = getMaxOfArray(arrayLat) - getMinOfArray(arrayLat)

            setCenterMap({lat: centerLat, lng: centerLng})
            setMapZoom((valueBase / dif)*dif)
            setClientes(clientes); 
        }else{    
            setCenterMap({lat: -8.113334, lng: -35.059530})
            setMapZoom(valueBase)
            setClientes(clientes); 
        }
    }

    function getMaxOfArray(numArray) {
        return Math.max.apply(null, numArray);
    }

    function getMinOfArray(numArray) {
        return Math.min.apply(null, numArray);
    }

    const getLastRoute = async () => {
        const docRef = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists() && docSnap.data().lastRoute.length != 0) {
            setRouteForm('Nova')
            setPoints(docSnap.data().lastRoute);
            setMarkers(true);
        } else {
            setPoints([]);            
            setMarkers(true);
        } 
    }

    const processReturns = async () => {
        const arrayReturns = [];

        for(const client of returnsUser){
            const report = reportsUser.find(x => x.docId === client.reportId);

            if(report) {
                arrayReturns.push({
                    ...client,
                    ...report,
                    reportTime: report.time,
                    retornos: client.retornos,
                })
            } else {
                arrayReturns.push({
                    ...client,
                    clientName: client.nome,
                    operatorName: userData.userName,
                    reportTime: client.closeDate,
                    retornos: client.retornos,
                })
            }
        }

        const ordenatedReturns= arrayReturns.sort((a, b) => {
            if(a.reportTime > b.reportTime){
                return -1;
            }
            if(a.reportTime < b.reportTime){
                return 1
            };
            return 0;
        });

        setReturns(ordenatedReturns);

        if(onlyReturns){
            setClientes(ordenatedReturns)
        }
    }

    const handleChangeOnlyReturns = (event) => {
        if(event.target.checked){
            setMarkers(false);
            setClientes([])
            setClientes(returns);
            setMarkers(true);
            setOnlyReturns(event.target.checked);
        }else{
            setClientes(clientsUser);
            setOnlyReturns(event.target.checked);
        }
    };

    const getToEdit = async (route) => {
        setRouteName(route.name);
        setPoints(route.points);
        setRouteForm('Editar');
        setMarkers(true);
    }

    const cancelRouteForm = async (setClients) => {
        const lastRouteRef = doc(db, "users", user.uid);

        await updateDoc(lastRouteRef, {
            lastRoute: [],
        });

        setDirectionsResponse(null);
        setPoints([]);
        setRouteName('');
        setRouteForm('');
        setRouteInEdit('');
        setOnlyReturns(false);
        setModalConfirm(false);

        if(setClients){
            setClientes(clientsUser)
        }

        setSaveLoading(false);
    }

    const handleInputChange = (event) => {
        setRouteName(event.target.value);
    }

    //Clientes salvos durante a criação da rota
    const addToLastRoute = async (item) => {

        const docRef = doc(db, "users", user.uid);

        await updateDoc(docRef, {
            lastRoute: [...points, item],
        });
    }

    const removeLastRoute = async (item) => {

        const result = points.findIndex(x => x.cpf === item.cpf);

        const newArray1 = points.slice(0, result);
        const newArray2 = points.slice(result+1, points.length);

        const docRef = doc(db, "users", user.uid);

        await updateDoc(docRef, {
            lastRoute: newArray1.concat(newArray2),
        });
    }

    const removeFromRoute = async (item) => {
        const result = points.findIndex(x => x.cpf === item.cpf);

        const newArray1 = points.slice(0, result);
        const newArray2 = points.slice(result+1, points.length);

        if(routeForm != 'Editar'){
            await removeLastRoute(item);
        }
        
        setPoints(newArray1.concat(newArray2))
        setClientes([...clientsUser, {...points[result], selected: ""}])
        setDirectionsResponse(null);
        setViewDetails(false);
    }

    const addOnRoute = async (item) => {

        if(routeForm != 'Editar'){
            await addToLastRoute(item);
        }

        setStreetView(false);
        setViewDetails(false);
        setDirectionsResponse(null);                                                              
        setPoints([...points, item]);
    }

    const verifyAvailability = async (points) => {
        const availableArray = []
        const unavailableArray = []

        for(const client of points){
            const find = clientsUser.find(x => x.cpf == client.cpf);

            if(find && find?.selected == ""){
                
                availableArray.push(client)
            }else{
                unavailableArray.push(client)
            }
        }

        return { unavailable: unavailableArray, available: availableArray }
    }

    async function traceRoute(points){
        const formatedOrigin = {
            lat: points[0].lat,
            lng: points[0].lng,
        }

        const formatedDestination = {
            lat: points.slice(-1)[0].lat,
            lng: points.slice(-1)[0].lng,
        }

        const waypoints = points.slice(1, -1);
        const formatedWaypoints = [];
        

        for(const p in waypoints){
            formatedWaypoints.push({
                location: {lat: waypoints[p].lat, lng: waypoints[p].lng },
            })
        }

        const google = window.google;
        const directionsService = new google.maps.DirectionsService();
        const results = await directionsService.route({
            origin: formatedOrigin,
            destination: formatedDestination,
            waypoints: formatedWaypoints,
            travelMode: google.maps.TravelMode.DRIVING,
        })

        setDirectionsResponse(results);
    }

    const saveSelectedClients = async (route) => {
        var success = false
        const routeLogArray = []

        const batch = writeBatch(db);

        const dateNow = new Date();
        const stringDate = datetime.getFullDate(dateNow.getTime()); 

        for(const point of route.points){
            const docRef = doc(db, "clientes", point.cpf);

            batch.update(docRef, {
                selected: user.uid,
            });

            const logMessage = `${point.nome} foi adicionado(a) à rota pelo operador ${userData.userName}`

            const log = `${stringDate} -> ${logMessage}` 

            const docRefLogs = doc(db, "clientesLogs", point.cpf);

            batch.update(docRefLogs, {
                logs: arrayUnion(log)
            });

            routeLogArray.push(log)
        }

        const routeLogsRef = doc(db, "rotasLogs", route.docId);

            const logMessageRoute = `${routeName} criada pelo operador ${userData.userName}`

            const log = `${stringDate} -> ${logMessageRoute}`
    
            routeLogArray.push(log) 
    
            batch.set(routeLogsRef, {
                logs: arrayUnion(...routeLogArray)
            });
    
            await batch.commit().then(async () => {
                success = true
            }).catch(async (error) => {    
                toast(`Erro ao selecionar clientes da rota ${error.code}`, alertErrorStyle)
        })

        return success
    }

    const editSelectedClients = async (added, removed, route) => {
        var success = false
        const routeLogArray = []

        const batch = writeBatch(db);

        const dateNow = new Date();
        const stringDate = datetime.getFullDate(dateNow.getTime()); 

        for(const point of added){
            const docRef = doc(db, "clientes", point.cpf);
            const docRefLogs = doc(db, "clientesLogs", point.cpf);

            batch.update(docRef, {
                selected: user.uid,
            });

            const logMessage = `${point.nome} foi adicionado(a) à rota pelo operador ${userData.userName}`

            const log = `${stringDate} -> ${logMessage}` 

            batch.update(docRefLogs, {
                logs: arrayUnion(log)
            });

            routeLogArray.push(log)
        }

        for(const point of removed){
            const docRef = doc(db, "clientes", point.cpf);
            const docRefLogs = doc(db, "clientesLogs", point.cpf);

            batch.update(docRef, {
                selected: "",
            });

            const logMessage = `${point.nome} foi removido(a) da rota pelo operador ${userData.userName}`

            const log = `${stringDate} -> ${logMessage}` 

            batch.update(docRefLogs, {
                logs: arrayUnion(log)
            });

            routeLogArray.push(log)
        }

        const routeLogsRef = doc(db, "rotasLogs", route.docId);

        const logMessageRoute = `${routeName} atualizada pelo operador ${userData.userName}`

        routeLogArray.push(logMessageRoute) 

        batch.update(routeLogsRef, {
            logs: arrayUnion(...routeLogArray)
        });

        await batch.commit().then(async () => {
            success = true
        }).catch(async (error) => {    
            toast(`Erro ao selecionar clientes da rota ${error.code}`, alertErrorStyle)
        })

        return success
    }

    const createRoute = async () => {
        var nome;

        const dateNow = new Date();

        if(routeName === ''){
            nome = `Rota ${dateNow.toLocaleDateString()} - ${dateNow.toLocaleTimeString()}`;
        }else{
            nome = routeName;
        }

        const { unavailable, available } = await verifyAvailability(points);

        if(unavailable.length > 0){
            toast(`Alguns clientes selecionados não estão mais disponíveis, o sistema irá salvar apenas os disponíveis.`, alertWarningStyle)
        }

        const routeData = {
            uid: user.uid,
            name: nome,
            points: available,
            operatorName: userData.userName,
            operatorPosition: null,
            time: dateNow.getTime(),
            status: "aguardando",
        }

        await addDoc(collection(db, "rotas"), routeData).then(async (doc) => {
            const logMessageUser = `${userData.userName} criou a rota ${nome}`

            const success = await saveSelectedClients({...routeData, docId: doc.id });

            if(success){
                await addUserLogs(userData.uid, logMessageUser)

                toast("Rota salva com sucesso", { 
                    style: {
                        background: 'var(--success)',
                        color: 'white',
                    },
                    progressStyle: {
                        background: 'white'
                    },
                    icon: <FiCheckSquare />
                })
            }
        }).catch(async (error) => {
            const logMessageUser = `${userData.userName} Erro ao criar rota: ${nome} - ${error.code}`

            await addUserLogs(userData.uid, logMessageUser)
            return toast(`Erro ao salvar rota ${error.code}`, { 
                style: {
                    background: 'var(--red)',
                    color: 'white',
                },
                progressStyle: {
                    background: 'white'
                },
                icon: <AiOutlineCloseSquare />
            })
        })

        await cancelRouteForm();
    }

    const updateRoute = async () => {

        const date = new Date();
        var docRef;

        if(routeInEdit){
            docRef = doc(db, "rotas", routeInEdit.docId);
        }else{
            return;
        }

        const { added, removed } = arrayHelper.getBalance(routeInEdit.points, points, 'cpf');

        //Verifica disponibilidade dos clientes adicionados
        const { unavailable, available } = await verifyAvailability(added);

        if(unavailable.length > 0){
            toast(`Alguns clientes selecionados não estão mais disponíveis, o sistema irá salvar apenas os disponíveis.`, alertWarningStyle)
        }

        //Separa apenas os que serão atualizados
        const unavailableCpfs = arrayHelper.reduceToSimple(unavailable, 'cpf')
        const newPoints = points.filter(x => !unavailableCpfs.includes(x.cpf))

        const routeData  = {
            name: routeName,
            points: newPoints,
            timeUpdated: date.getTime(),
        }

        await updateDoc(docRef, routeData).then(async () => {
            const logMessageUser = `${userData.userName} atualizou a rota ${routeName}`

            const success = await editSelectedClients(available, removed, routeInEdit);

            if(success){
                await addUserLogs(userData.uid, logMessageUser)
    
                toast(`Rota ${routeName} atualizada com sucesso`, { 
                    style: {
                        background: 'var(--success)',
                        color: 'white',
                    },
                    progressStyle: {
                        background: 'white'
                    },
                    icon: <FiCheckSquare />
                })
            }
        }).catch(async (error) => {
            const logMessage = `${routeName} falha ao atualizar rota do operador ${
                userData.userName}: ${error.code}`
            const logMessageUser = `${userData.userName} Erro ao atualizar rota: ${routeName} - ${error.code}`

            await addRouteLogs(routeInEdit.docId, logMessage)
            await addUserLogs(userData.uid, logMessageUser)
            return toast(`Erro ao atualizar rota ${routeName} ${error.code}`, { 
                style: {
                    background: 'var(--red)',
                    color: 'white',
                },
                progressStyle: {
                    background: 'white'
                },
                icon: <AiOutlineCloseSquare />
            })
        })

        await cancelRouteForm();
    }

    const tryDeleteRoute = async (item) => {

        const batch = writeBatch(db);

        for(const point of item.points){
            const docRef = doc(db, "clientes", point.cpf);

            batch.update(docRef, {
                selected: "",
            });

            const dateNow = new Date();
            const stringDate = datetime.getFullDate(dateNow.getTime()); 

            const logMessage = `${point.nome} foi liberado para o mapa pelo operador ${
                userData.userName} devido ao cancelamento da rota ${item.name}`

            const log = `${stringDate} -> ${logMessage}` 

            const docRefLogs = doc(db, "clientesLogs", point.cpf);

            const routeLogsRef = doc(db, "rotasLogs", item.docId);

            batch.update(docRefLogs, {
                logs: arrayUnion(log)
            });

            batch.update(routeLogsRef, {
                logs: arrayUnion(log)
            });
        }
        
        const routesRef = doc(db, "rotas", item.docId);
        batch.update(routesRef, {
            status: "cancelado",
            routeCloseDate: dateNow.getTime(),
        });

        batch.commit().then(async () => {
            const logMessage = `${item.name} cancelada pelo operador ${
                userData.userName}`
            const logMessageUser = `${userData.userName} cancelou a rota ${item.name}`
    
            await addRouteLogs(item.docId, logMessage)
            await addUserLogs(userData.uid, logMessageUser)
    
            toast("Rota cancelada com sucesso", { 
                style: {
                    background: 'var(--success)',
                    color: 'white',
                },
                progressStyle: {
                    background: 'white'
                },
                icon: <FiCheckSquare />
            })
        }).catch(async (error) => {
            const logMessage = `${item.name} Erro ao cancelar rota ${item.name}: ${error.code}`
            const logMessageUser = `${userData.userName} Erro ao cancelar rota ${item.name}: ${error.code}`
    
            await addRouteLogs(item.docId, logMessage)
            await addUserLogs(userData.uid, logMessageUser)
    
            toast(`Erro ao cancelar rota ${error.code}`, { 
                style: {
                    background: 'var(--red)',
                    color: 'white',
                },
                progressStyle: {
                    background: 'white'
                },
                icon: <AiOutlineCloseSquare />
            })
        })

        setPoints([]);
        setDirectionsResponse(null);

        setModalConfirm(false);
        setModalData('');
        setRouteToDelete('');
    }

    const center = useMemo(() => ({lat: -8.113334, lng: -35.059530}), []);

    window.addEventListener("beforeunload", function(event) { 
        event.preventDefault();
    
    
        event.returnValue = "Mensagem de aviso"; 
        return "Mensagem de aviso";
    });

    if(loadingData){
        return <CustomLoading />
    }

    return (
        <Container>
            <RouteBar>
                <RouteBarHeader>
                    <HeaderLeft>
                        <LogoContainer>
                            <img style={{ width: 60 }} src={logo} alt="Login Logo"/>
                        </LogoContainer>
                        <HeaderTitle>
                            {routeForm && (
                                <BsArrowLeft 
                                    style={{cursor: 'pointer'}} 
                                    size={20} 
                                    onClick={() => {
                                        setModalData({
                                            text: 'Tem certeza que deseja cancelar a edição/criação da rota?',
                                            option: 'Sim'
                                        })
                                        setModalConfirm(true);
                                    }}
                                />
                            )}
                            
                            <text>{routeForm ? `${routeForm} rota` : 'Rotas'}</text>
                        </HeaderTitle>
                    </HeaderLeft>
                    {!routeForm && (
                        <NewRouteButton onClick={() => {
                            setDirectionsResponse(null)
                            setPoints([]);
                            setRouteForm('Nova')
                        }}>
                            <MdAddCircleOutline />
                            <text>Nova rota</text>
                        </NewRouteButton>)
                    }
                </RouteBarHeader>
                <RouteBarContent>
                    {routeForm
                        ? <NewRouteContainer>
                            <FieldText>
                                Nome
                            </FieldText>
                            <TextInput>
                                <input 
                                    name="routeName" 
                                    value={routeName}
                                    onChange={event => handleInputChange(event)}
                                    placeholder='Nome da rota'
                                />
                            </TextInput>

                            <TextSection>
                                Roteiro
                            </TextSection>
                            
                            <RoutePointsContainer>
                                
                                {points.length != 0 && points.map((item, index) => (
                                    <RoutePointItem 
                                        key={index}
                                        letter={letras[index]} 
                                        clientName={item.nome}
                                        clientEnd={address.formatEnd(item)}
                                        especie={item.especie}
                                        onRemove={async () => {
                                            await removeFromRoute(item)
                                        }} 
                                    />
                                ))}
                            </RoutePointsContainer>
                            <Divider />
                            <ActionsContent>
                                <CancelButton onClick={() => {
                                    setModalData({
                                        text: 'Tem certeza que deseja cancelar a edição/criação da rota?',
                                        option: 'Sim'
                                    })
                                    setModalConfirm(true);
                                }}>
                                    <text>Cancelar</text>
                                </CancelButton>
                                {saveLoading
                                    ?<SaveButton>
                                        <ClipLoader
                                            size={20}
                                            color={"var(--white)"}
                                            loading={true}
                                            speedMultiplier={1.5}
                                        />
                                    </SaveButton>
                                    :<SaveButton onClick={async () => {
                                        setSaveLoading(true);
                                        await traceRoute(points);
    
                                        if(routeForm == 'Nova'){
                                            await createRoute();
                                        }
                                        if(routeForm == 'Editar'){
                                            await updateRoute()
                                        }
                                    }}>
                                        <text>Salvar</text>
                                    </SaveButton>
                                }
                            </ActionsContent>
                        </NewRouteContainer>
                        : <SavedRoutesContainer>
                            {routesUser && routesUser.length != 0 
                                ?routesUser.map((item, index) => (
                                    <SavedRouteCard 
                                        route={item} 
                                        key={index}
                                        onEdit={() => {
                                            getToEdit(item);
                                            setRouteInEdit(item);
                                        }}
                                        onCancel={async () => {
                                            setRouteToDelete(item);
                                            setModalData({
                                                text: `Tem certeza que deseja cancelar a rota ${item.name}`,
                                                option: 'Sim'
                                            })
                                            setModalConfirm(true);
                                        }}
                                        onTrace={async (expanded) => {
                                            setRouteExpanded(expanded)
                                            if(expanded && item.points.length != 0){
                                                await traceRoute(item.points);
                                                setPoints(item.points)
                                            }else{
                                                setDirectionsResponse(null);
                                                setPoints([]);
                                            }
                                        }}
                                    />
                                ))
                                :<NoRoutesContainer>
                                    <img src={emptyRoutes} />
                                    <h3>Nenhuma rota cadastrada.</h3>
                                    <NoRouteButton onClick={() => {
                                        setDirectionsResponse(null)
                                        setPoints([]);
                                        setRouteForm('Nova')
                                    }}>
                                        <MdOutlineAdd />
                                        <text>Criar rota</text>
                                    </NoRouteButton>
                                </NoRoutesContainer>
                            }
                        </SavedRoutesContainer>
                    }
                </RouteBarContent>
            </RouteBar>
            {isLoaded && loadingData === false && centerMap && mapZoom ? (
                <MapContainer>
                <GoogleMap
                    onLoad={map => {
                        setMapRef(map)
                    }}
                    mapContainerStyle={{width: '100%', height: '100%'}}
                    center={lastCenter ? lastCenter : centerMap}
                    zoom={lastZoom ? lastZoom : mapZoom}
                    clickableIcons={false}
                    options={{
                        disableDefaultUI: true,
                    }}
                >
                    {routeForm != '' && (
                        <ReturnMapDiv>
                            <ReturnMapDivHeader>
                                <Switch
                                    checked={onlyReturns}
                                    onChange={handleChangeOnlyReturns}
                                    color='red'
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                                <text>Mostrar somente retornos</text>
                                {returnExpanded
                                    ? <IoIosArrowUp cursor='pointer' onClick={() => setReturnExpanded(false)} />
                                    : <IoIosArrowDown cursor='pointer' onClick={() => setReturnExpanded(true)} />
                                }
                            </ReturnMapDivHeader>
                            {returnExpanded && (
                                <ReturnsContainer>
                                    <DividerReturn />

                                    <ReturnsContent>
                                        <h3>Próximos retornos</h3>
                                        {returns && returns.length != 0 && returns.map((item, index) => (
                                            <div key={index}>
                                                <ReturnCard
                                                    onClick={async () => {
                                                        setShowInfoWindow({ status: true, cliente: item.cpf});
                                                        setCurrentClient(item);
                                                        setViewDetails(true);
                                                    }}
                                                >
                                                    <div className='header'>
                                                        <h3>{item.nome}</h3>
                                                        <text>{`${item.dataRetorno} - ${item.horaRetorno}`}</text>
                                                    </div>
                                                    <div className='description'>
                                                        {item.description}
                                                    </div>
                                                </ReturnCard>
                                                {index != returns.length && (
                                                    <DividerReturnItem />
                                                )}
                                            </div>
                                        ))}
                                    </ReturnsContent>
                                    
                                </ReturnsContainer>
                            )}
                        </ReturnMapDiv>
                    )}
                    <MapDivRight>
                        <ReturnMapButton onClick={() => setModalReturns(true)}>
                            <text>Retornos</text>
                            <PointIcon text={returns ?returns.length : 0} size={24} fontSize={12} fontWeight='300' />
                        </ReturnMapButton>
                        <ProfileButton arrowColor='white' />
                    </MapDivRight>
                    {!legend && (
                        <HelpButton onClick={() => setLegend(true)}>
                            <BiHelpCircle size={40} cursor='pointer' />
                        </HelpButton>
                    )}
                    {legend && (
                        <MarkersLegend>
                            <ModalHeader title='Legenda' onClose={() => setLegend(false)} />
                            {markerIcons && markerIcons.map((item, index) => (
                                <LegendItem key={index}>
                                    <img src={item.icon} alt='img img' />
                                    <h1>{item.title}</h1>
                                </LegendItem>
                            ))}
                        </MarkersLegend>
                    )}
                    {markers === true && clientes                     
                        ?( clientes.map((cliente) => (
                            cliente.lat != null && cliente.lng != null && !arrayHelper.includeOnArrayObject(points, 'cpf', cliente.cpf)
                            ?
                            <Marker
                                key={cliente.cpf} 
                                position={{lat: cliente.lat, lng: cliente.lng }}
                                onClick={async () => {
                                    setShowInfoWindow({ status: true, cliente: cliente.cpf});
                                    setViewDetails(true);
                                    setCurrentClient(cliente);    
                                    const logMessage = `${cliente.nome} teve suas informações visualizadas pelo operador ${userData.userName}`
                                    const logMessageUser = `${userData.userName} visualizou as informações do cliente ${cliente.nome}`

                                    await addClientLogs(cliente.cpf, logMessage);
                                    await addUserLogs(userData.uid, logMessageUser);                                
                                }}
                                options={{
                                    icon: markersHelper.getMarkerIcons(cliente),
                                    optimized: false
                                }}
                            >
                                {showInfoWindow.status === true && showInfoWindow.cliente === cliente.cpf 
                                && (<InfoWindow
                                            position={{lat: currentClient.lat, lng: currentClient.lng}}
                                            clickable={true}
                                            onCloseClick={() => {
                                                setShowInfoWindow({ status: false, cliente: null})
                                                setCurrentClient('');
                                            }}
                                        >
                                                <CustomInfoWindow>
                                                    <InfoWindowTextContainer>
                                                        <h4>{currentClient.nome}</h4>
                                                    </InfoWindowTextContainer>
                                                    {routeForm != '' && (
                                                        <InfoWindowButtonsContainer>
                                                            <AddRouteButton  
                                                                onClick={async () => {  
                                                                    setLastCenter(mapRef.center)
                                                                    setLastZoom(mapRef.zoom)
                                                                    
                                                                    await addOnRoute(cliente)
                                                                }}
                                                            >
                                                                <MdOutlineAdd />
                                                                <text>Adicionar à rota</text>
                                                            </AddRouteButton>
                                                        </InfoWindowButtonsContainer>
                                                    )}
                                                </CustomInfoWindow>
                                        </InfoWindow>
                                   )
                                }
                                
                            </Marker>
                            : null
                        )))
                        : (
                            <></>
                        )
                    }
                    {points.length != 0                   
                        ?( points.map((point, index) => (
                            <Marker 
                                key={point.cpf} 
                                position={{lat: point.lat, lng: point.lng }}
                                onClick={async () => {
                                    setShowInfoWindow({ status: true, point: point.cpf});
                                    setViewDetails(true);
                                    setCurrentClient(point);                             
                                }}
                                zIndex={1000}
                                options={directionsResponse === null
                                    ? {
                                    label: {
                                        text: letras[index],
                                        color: 'white',
                                        fontWeight: 'bold',
                                        padding: 50,
                                    }}
                                    : {
                                    label: {
                                        text: letras[index],
                                        color: 'white',
                                        fontWeight: 'bold',
                                        opacity: 0,
                                    }}
                                }
                            >
                                {showInfoWindow.status === true && showInfoWindow.point === point.cpf 
                                &&  (<InfoWindow
                                        position={{lat: currentClient.lat, lng: currentClient.lng}}
                                        clickable={true}
                                        onCloseClick={() => {
                                            setShowInfoWindow({ status: false, cliente: null})
                                            setCurrentClient('');
                                        }}
                                    >
                                            <CustomInfoWindow>
                                                <InfoWindowTextContainer>
                                                    <h4>{currentClient.nome}</h4>
                                                    <text>{address.formatEnd(currentClient)}</text>
                                                </InfoWindowTextContainer>
                                                {routeForm != '' && (
                                                    <InfoWindowButtonsContainer>
                                                        <AddRouteButton  
                                                            onClick={async () => {  
                                                                setLastCenter(mapRef.center)
                                                                setLastZoom(mapRef.zoom)
                                                                
                                                                await removeFromRoute(point)
                                                            }}
                                                        >
                                                            <MdOutlineRemove />
                                                            <text>Remover da rota</text>
                                                        </AddRouteButton>
                                                    </InfoWindowButtonsContainer>
                                                )}                                                
                                            </CustomInfoWindow>
                                    </InfoWindow>
                            )}
                            </Marker>
                        )))
                        : (
                            <></>
                        )
                    }
                    {streetView === true && (
                        <StreetViewPanorama onCloseclick={() => alert('fechou')} position={{lat: currentClient.lat, lng: currentClient.lng}} visible={streetView}/>
                    )}           
                    {directionsResponse && <DirectionsRenderer options={{suppressMarkers: true}} directions={directionsResponse} />}         
                </GoogleMap>
                </MapContainer>
            ) : (
                <Container>
                    <CustomLoading />
                </Container>
            )}
            {currentClient && viewDetails && (
                <ClientDetailsOperator 
                    data={currentClient} 
                    points={points}
                    onClose={() => setViewDetails(false)} 
                    onStreetView={async () => {
                        if(streetView == true){
                            setStreetView(false);
                            setTimeout(async () => {
                                const logMessage = `${currentClient.nome} teve seu street view visualizado pelo operador ${userData.userName}`
                                const logMessageUser = `${userData.userName} visualizou o street view do cliente ${currentClient.nome} `

                                await addClientLogs(currentClient.cpf, logMessage)
                                await addUserLogs(userData.uid, logMessageUser)
                                setStreetView(true)
                            }, 500)
                        }else{
                            const logMessage = `${currentClient.nome} teve seu street view visualizado pelo operador ${userData.userName}`
                            const logMessageUser = `${userData.userName} visualizou o street view do cliente ${currentClient.nome} `

                            await addClientLogs(currentClient.cpf, logMessage)
                            await addUserLogs(userData.uid, logMessageUser)
                            setStreetView(!streetView)
                        }
                    }}
                    onGenerateReport={() => {
                        setModalReport(true);
                    }}
                    onViewFullData={() => {
                        setModalDetails(true);
                    }}
                />
            )}
            <Modal
                isOpen={modalReturns}
                onRequestClose={() => setModalReturns(false)}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalReturnsOperator 
                    reports={returns} 
                    onClose={() => setModalReturns(false)} 
                    onClickView={(item) => {
                        setCurrentClient(item)
                        setModalDetails(true);
                    }}
                />
            </Modal>
            <Modal
                isOpen={modalDetails}
                onRequestClose={() => setModalDetails(false)}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalDetailsOperator 
                    data={currentClient} 
                    onClose={() => setModalDetails(false)} 
                    onStreetView={async (type) => {
                        setModalDetails(false)
                        setModalReturns(false);
                        if(streetView == true){
                            setStreetView(false);
                            setTimeout(async () => {
                                const logMessage = `${currentClient.nome} teve seu street view visualizado pelo operador ${userData.userName}`
                                const logMessageUser = `${userData.userName} visualizou o street view do cliente ${currentClient.nome} `

                                await addClientLogs(currentClient.cpf, logMessage)
                                await addUserLogs(userData.uid, logMessageUser)
                                setStreetView(true)
                            }, 500)
                        }else{
                            setStreetView(!streetView)
                            const logMessage = `${currentClient.nome} teve seu street view visualizado pelo operador ${userData.userName}`
                            const logMessageUser = `${userData.userName} visualizou o street view do cliente ${currentClient.nome} `

                            await addClientLogs(currentClient.cpf, logMessage)
                            await addUserLogs(userData.uid, logMessageUser)
                        }
                        const logMessage = `${currentClient.nome} teve suas informações visualizadas pelo operador ${userData.userName}`
                        const logMessageUser = `${userData.userName} visualizou as informações do cliente ${currentClient.nome}`

                        await addClientLogs(currentClient.cpf, logMessage);
                        await addUserLogs(userData.uid, logMessageUser);
                        setViewDetails(true);
                        setShowInfoWindow({ status: true, cliente: currentClient.cpf});
                    }}
                />
            </Modal>
            <Modal
                isOpen={modalReport}
                onRequestClose={() => setModalReport(false)}
                overlayClassName="modal-overlay"
                className="modal-content-auto"
                contentLabel="Example Modal"
                style={{overlay: {
                    overflowY:"scroll"
                }}}
            >
                <ModalReport 
                    data={currentClient} 
                    onClose={() => setModalReport(false)} 
                    onSaved={() => {
                        setStreetView(false);
                        setShowInfoWindow({ status: true, cliente: null});
                        setCurrentClient('')
                        setViewDetails(false);
                    }}
                />
            </Modal>
            <Modal
                isOpen={modalConfirm}
                onRequestClose={() => setModalConfirm(false)}
                overlayClassName="modal-overlay"
                className="modal-content-alert"
                contentLabel="Example Modal"
            >
                <ModalConfirm 
                    data={modalData} 
                    onCancel={() => {
                        setModalConfirm(false);
                    }}
                    onConfirm={async () => {
                        await cancelRouteForm(true)

                        if(routeToDelete){
                            await tryDeleteRoute(routeToDelete);
                        }
                    }}
                />
            </Modal>
            <Modal
                isOpen={modalNoMap}
                onRequestClose={() => {}}
                overlayClassName="modal-overlay"
                className="modal-content-auto"
                contentLabel="Example Modal"
            >
                <NoMapModal />
            </Modal>
        </Container>
    )
};

export default MapPage;