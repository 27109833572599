import React, { useContext, useEffect, useState } from 'react';

import { 
    Container,
    Header,
    ListContainer,
} from './styles';

//Firebase and context
import { doc, updateDoc } from "firebase/firestore";
import db from '../../firebase/config';

//Icons
import { AiOutlineCloseCircle, AiOutlineCloseSquare } from 'react-icons/ai';
import { FiCheckSquare } from 'react-icons/fi';

//Custom components
import DownloadButton from '../DownloadButton';
import ModalReturnItem from '../ModalReturnItem';
import CustomFilterSimple from '../CustomFilterSimple';
import CustomLoading from '../CustomLoading';
import ModalReleaseMap from '../ModalReleaseMap';

//Utils
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import ModalAssignReturn from '../ModalAssignReturn';
import ModalDetailsAdmin from '../ModalDetailsAdmin';
import { alertSuccessStyle } from '../../resources/alertSuccessStyle';
import { alertErrorStyle } from '../../resources/alertErrorStyle';

function ModalReturnCard({ data, onClose }) {

    const dateNow = new Date();

    const [ modalData, setModalData ] = useState('');
    const [ modalDataBackup, setModalDataBackup ] = useState('');

    const [ currentClient, setCurrentClient ] = useState('');

    //Modal
    const [ modalReleaseMap, setModalReleaseMap ] = useState(false);
    const [ modalAssignReturn, setModalAssignReturn ] = useState(false);
    const [ modalFullData, setModalFullData ] = useState(false);

    useEffect(() => {
        setModalData(data.array);
        setModalDataBackup(data.array);
    }, [data]);

    const releaseToMap = async () => {
        const docRef = doc(db, "clientes", currentClient.clientCpf);

        await updateDoc(docRef, {
            selected: '',
            operatorUid: '',
            atraso: false,
            retornoPremium: true,
        }).then(() => {
            toast(`Cliente ${currentClient.clientName} devolvido ao mapa com sucesso`, alertSuccessStyle)
        }).catch((error) => {
            toast(`Erro ao devolver cliente ${currentClient.clientName} ao mapa: ${error.code}`, alertErrorStyle)
        })
        
        setModalReleaseMap(false);
    }

    const assignToOperator = async (operator) => {
        const docRef = doc(db, "clientes", currentClient.clientCpf);

        await updateDoc(docRef, {
            operatorUid: operator.value,
            atraso: false,
            retornoPremium: true,
            forwardTime: dateNow.getTime()
        }).then(() => {
            updateModalData(currentClient.clientCpf);
            const logMessage = `Cliente ${currentClient.clientName} encaminhado para o operador ${operator.label} com sucesso`
            toast(logMessage, alertSuccessStyle)
        }).catch((error) => {
            const logMessage = `Erro ao encaminhar cliente ${currentClient.clientName} para o operador ${operator.label}: ${error.code}`
            toast(logMessage, alertErrorStyle)
        })
        setModalAssignReturn(false);
    }

    const updateModalData = (cpf) => {
        const index = modalData.findIndex(x => x.clientCpf === cpf);

        if(index != -1){
            const array1 = modalDataBackup.slice(0, index);
            const array2 = modalDataBackup.slice(index+1, modalDataBackup.length);

            setModalData(array1.concat(array2))
        }
    }

    if(!modalData){
        return (
            <Container>
                <CustomLoading />
            </Container>
        )
    }

    return (
        <Container>
            <Header>
                <h3>{data.title}</h3>

                    <div>
                    {data.filterTypes && (
                        <CustomFilterSimple 
                            filterTypes={data.filterTypes}
                            data={modalData}
                            tableRowsBackup={data.array}
                            clearFilters={() => {
                                setModalData(data.array);
                                setModalDataBackup(data.array);
                            }}
                            onFilter={(arrayValues) => {
                                setModalData(arrayValues)
                                setModalDataBackup(arrayValues)
                            }}
                            filterType='atendimentos'
                        />
                    )}
                    <DownloadButton tableColumns={data.tableColumns} tableRows={modalData} /> 
                    <AiOutlineCloseCircle style={{ marginLeft: 10 }} onClick={onClose} strokeWidth={0.5} cursor='pointer' size={22} />
                </div>
            </Header>
            <ListContainer>
                {modalData && modalData.map((item, index) => (
                    <ModalReturnItem 
                        key={index} 
                        data={item} 
                        type={data.type} 
                        columns={data.columns} 
                        onViewFullData={() => {
                            setCurrentClient(item);
                            setModalFullData(true);
                        }}
                        onReleaseMap={() => {
                            setCurrentClient(item)
                            setModalReleaseMap(true)
                        }}
                        onAssignReturn={() => {
                            setCurrentClient(item);
                            setModalAssignReturn(true);
                        }}
                    />
                ))}
            </ListContainer>

            <Modal
                isOpen={modalFullData}
                onRequestClose={() => setModalFullData(false)}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalDetailsAdmin 
                    data={currentClient} 
                    onClose={() => setModalFullData(false)}
                    onStreetView={() => {
                        
                    }}
                />
            </Modal>

            <Modal
                isOpen={modalReleaseMap}
                onRequestClose={() => setModalReleaseMap(false)}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalReleaseMap 
                    clientName={currentClient.clientName} 
                    onCancel={() => setModalReleaseMap(false)}
                    onConfirm={() => releaseToMap()}
                />
            </Modal>

            <Modal
                isOpen={modalAssignReturn}
                onRequestClose={() => setModalAssignReturn(false)}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalAssignReturn 
                    client={currentClient}
                    onCancel={() => setModalAssignReturn(false)}
                    onConfirm={(operator) => assignToOperator(operator)}
                />
            </Modal>
        </Container>
    )
}

export default ModalReturnCard;