import React from 'react';

import { Container, Content, ActionsContent, CancelButton, ConfirmButton, ModalConfirmHeader, ModalConfirmText, Divider } from './styles';

import mapIcon from '../../assets/mapIcon.svg'

const ModalReleaseMap = ({ clientName, onCancel, onConfirm }) => {

    return (
        <Container>
            <Content>
                <img src={mapIcon} style={{ height: 80, width: 80 }} alt='map map'/>
                <ModalConfirmText>
                    Deseja devolver o cliente {clientName} ao mapa?
                </ModalConfirmText>
            </Content>
            <Divider />
            <ActionsContent>
                <CancelButton onClick={onCancel}>
                    <text>Voltar</text>
                </CancelButton>
                <ConfirmButton onClick={onConfirm}>
                    <text>Devolver ao mapa</text>
                </ConfirmButton>
            </ActionsContent>
        </Container>
    )
}

export default ModalReleaseMap;