import React, { useState } from 'react';

import { Container, ButtonFilter, TextClearFilters } from './styles';

//icons
import { IoMdArrowDropdown } from 'react-icons/io';
import { BsEraser } from 'react-icons/bs';

//Utils
import Select from 'react-select'

//Mui components
import Menu from '@mui/material/Menu';
import DateFilter from '../DateFilter';

const validStatus = ['aguardando', 'iniciado', 'parcial', 'finalizado', 'cancelado']
const statusName = ['Aguardando', 'Em andamento', 'Parcialmente concluída', 'Concluída', 'Cancelada']

const monthNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

const CustomFilterHeader = ({ filterTypes, data, tableRowsBackup, clearFilters, onFilter, filterType, defaultTitle, dateField }) => {

    const [ filterOptions, setFilterOptions ] = useState([]);
    const [ filteredItems, setFilteredItems ] = useState({});
    const [ selectedField, setSelectedField ] = useState('');

    const [ filterCalendar, setFilterCalendar ] = useState(false);
    const [ calendarValues, setCalendarValues ] = useState('');

    const [ arrayDateFilter, setArrayDateFilter ] = useState('');

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const clearAllFilters = () => {
        setFilterOptions([]);
        setFilteredItems({});
        setSelectedField('');
        setArrayDateFilter('')
        setCalendarValues('');
        clearFilters()
    }

    const processFilterOptions = (field) => {
        const array = [];

        const keys = Object.keys(filteredItems);

        if(keys.length == 1 && keys[0] == field){
            for(const item of tableRowsBackup){
                const result = array.find(x => x.value === item[field]);
    
                if(!result){
                    array.push({ label: item[field], value: item[field] })
                }
                
            }
        }else{
            for(const item of data){
                const result = array.find(x => x.value === item[field]);

                if(field == 'status'){
                    const index = validStatus.findIndex(x => x === item[field])
                    
                    if(!result){
                        array.push({ label: statusName[index], value: item[field] })
                    }
                }else{
                    if(!result){
                        array.push({ label: item[field], value: item[field] })
                    }
                }
    
                
                
            }
        }
        const ordenatedArray = array.sort(function(a,b) {
            return a.label > b.label ? 1 : a.label < b.label ? -1 : 0;
        });
        setFilterOptions(ordenatedArray);
    }

    const handleMenuClose = (field) => {
        if(filterCalendar && arrayDateFilter){
            setSelectedField('');
            setAnchorEl(null);
            onFilter(arrayDateFilter)
        }else{
            setSelectedField('');
            setAnchorEl(null);
            filterTable(null);
        }
    }

    const handleMenuOpen = (event, headerName) => {
        if(headerName == 'Data'){
            setFilterCalendar(true);
        }else{
            setFilterCalendar(false)
        }

        setSelectedField(event.currentTarget.id)
        setAnchorEl(event.currentTarget);
    };

    const onChangeFilter = async (newValue, field) => {
        if(newValue){
            const obj = {
                ...filteredItems,
                [field]: newValue,
            }
            setFilteredItems(obj);
        }
        
        if(newValue.length == 0){

            const array = [];

            const keys = Object.keys(filteredItems);
            var obj = {};
            for(const key of keys){
                if(key != field){
                    obj[key] = filteredItems[key];
                }
            }
          
            setFilteredItems(obj);
            filterTable(obj);

            for(const item of tableRowsBackup){
                const result = array.find(x => x.value === item[field]);

                if(!result){
                    array.push({ label: item[field], value: item[field] })
                }
                
            }
            setFilterOptions(array);
        }
    }

    const filterTable = (filters) => {

        var values = filteredItems;
        
        if(filters){
            values = filters;
        }

        const keys = Object.keys(values);

        var filterCount = 0;
        var arrayValues = [];

        if(keys.length == 0 && !arrayDateFilter){
            clearFilters()
            return setFilterOptions([])
        }

        for(const key of keys){
            if(values[key] != null){
                if(filterCount == 0){    
                    for(const item of values[key]){
                        const result = tableRowsBackup.filter(x => x[key] === item.value);
    
                        if(result){
                            const newArray = arrayValues.concat(result);
                            arrayValues = newArray;
                        }  
                    }
                }else{
                    if(values[key].length > 1){
                        var arrayAux = [];
                        for(const item of values[key]){
                            

                            const result = arrayValues.filter(x => x[key] === item.value);
        
                            if(result){
                                arrayAux = arrayAux.concat(result);
                            }else{
                                alert('não encontrado dentro')
                            }
                        }
                        arrayValues = arrayAux;
                    }else{
                        for(const item of values[key]){
                            const result = arrayValues.filter(x => x[key] === item.value);
        
                            if(result){
                                arrayValues = result;
                            }else{
                                alert('não encontrado dentro')
                            }
                        }
                    }
                }
                filterCount++
            }            
        }

        if(arrayValues.length != 0){
            onFilter(arrayValues);       
        }else{
            alert("Não encontrado");
        }

        if(filters){
            const array = [];

            for(const item of arrayValues){
                const result = array.find(x => x.value === item[selectedField]);

                if(!result){
                    array.push({ label: item[selectedField], value: item[selectedField] })
                }
                
            }
            setFilterOptions(array);
        }
        
    }

    const getFilterDescription = () => {

        if(!calendarValues.selectedEnd.day){
            return `${calendarValues.selectedStart.day} de ${
                    monthNames[calendarValues.selectedStart.month-1]} de ${
                    calendarValues.selectedStart.year
                }`
        }

        if(calendarValues.selectedStart.year == calendarValues.selectedEnd.year){
            if(calendarValues.selectedStart.month == calendarValues.selectedEnd.month){
                return `${calendarValues.selectedStart.day} a ${
                        calendarValues.selectedEnd.day} de ${
                        monthNames[calendarValues.selectedEnd.month-1]} de ${
                        calendarValues.selectedEnd.year
                    }`
            }else{
                return `${calendarValues.selectedStart.day} de ${
                            monthNames[calendarValues.selectedStart.month-1]} a ${
                            calendarValues.selectedEnd.day} de ${
                            monthNames[calendarValues.selectedEnd.month-1]} de ${
                            calendarValues.selectedEnd.year
                        }`
            }
        }else{
            return `${calendarValues.selectedStart.day} de ${
                    monthNames[calendarValues.selectedStart.month-1]} de ${
                    calendarValues.selectedStart.year} a ${
                    calendarValues.selectedEnd.day} de ${
                    monthNames[calendarValues.selectedEnd.month-1]} de ${
                    calendarValues.selectedEnd.year
                }`
        }
    }

    const buttonHandle = (label, field) => {
            if(filteredItems[field]){
                if(field == 'status'){
                    if(filteredItems[field].length > 1){
                        return `${filteredItems[field][0].label} +${filteredItems[field].length -1}`
                    }else{
                        return `${filteredItems[field][0].label}`
                    }
                }else{
                    if(filteredItems[field].length > 1){
                        return `${filteredItems[field][0].value} +${filteredItems[field].length -1}`
                    }else{
                        return `${filteredItems[field][0].value}`
                    }
                }
            }else{
                if(label == 'Data' && calendarValues){
                    // return `${calendarValues[0]} a ${calendarValues.slice(0)[-1]}`
                    return getFilterDescription()
                }else{
                    return `${label}`
                }
            }
    }

    const customStyle = {      
        container: (base) => ({
            ...base,
            borderRadius: 40
        }),
        control: (base) => ({
            ...base,
            padding: 3,
            borderRadius: 40
        }),
        multiValue: (base) => ({
            ...base,
            borderRadius: 40
        }),
    }  

    return <Container>
            {filterTypes.map((item, index) => (
                <ButtonFilter key={index} id={item.field} onClick={(event) => handleMenuOpen(event, item.headerName)}>
                    <h4>{!calendarValues && defaultTitle ? defaultTitle : buttonHandle(item.headerName, item.field)}</h4>
                    <IoMdArrowDropdown style={{ cursor: 'pointer'}} size={18} color='#808B9F' fill='#808B9F' />
                </ButtonFilter>
            ))}

            <TextClearFilters onClick={() => clearAllFilters()}>
                <BsEraser style={{ cursor: 'pointer', marginRight: -5 }} />
                Limpar
            </TextClearFilters>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                style={{  zIndex: 100000, }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                PaperProps={{  
                    style: {  
                        width: 400,
                        height: 400,
                        padding: 10,
                        borderRadius: 15,
                    },  
                }} 
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {filterCalendar
                    ?<DateFilter 
                        data={data} 
                        tableRowsBackup={tableRowsBackup} 
                        defaultValues={calendarValues}
                        onFilterDate={(newData, values) => {
                            setArrayDateFilter(newData)
                            setCalendarValues(values);
                        }} 
                        clearDateFilter={() => {
                            setArrayDateFilter('')
                            setCalendarValues('');
                        }}
                        filterType={filterType}
                        dateField={dateField}
                        onClose={handleMenuClose}
                    />
                    :<Select 
                        isClearable={false} 
                        isSearchable={true} 
                        defaultValue={selectedField ? filteredItems[selectedField] : null}
                        closeMenuOnSelect={false} 
                        options={filterOptions} 
                        isMulti={true} 
                        styles={customStyle}
                        autoFocus={true}
                        openMenuOnFocus={true}
                        onMenuOpen={() => processFilterOptions(selectedField)}
                        onChange={(item) => onChangeFilter(item, selectedField)}
                    />
                }
                
            </Menu>
    </Container>
}

export default CustomFilterHeader;