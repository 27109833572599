import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    margin-top: 10px;
`;

export const ContainerModalDetails = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 10px;
`;

export const ClientBadgeItem = styled.img`
    height: 64px;
`;

export const ClientBadgeFake = styled.div`
    height: 64px;
    width: 64px;
`;