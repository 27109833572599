import React, { useState } from 'react';

import { 
    Container,
    ClientName,
    ActionButtons,
    CancelButton,
    SaveButton,
    AddressItem
} from './styles';

import { MdRadioButtonUnchecked, MdRadioButtonChecked } from 'react-icons/md';

//Custom components
import ModalHeader from '../ModalHeader';
import CustomLoading from '../CustomLoading';
import CustomDivider from '../CustomDivider';
import { address } from '../../helpers/address';

const validEnds = ['S1', 'S2', 'O1', 'O2'];

function ModalChangeAddress({ data, title, loading, onSave, onClose }) {

    const [ selectedAddress, setSelectedAddress ] = useState(data.validEnd);

    return(
        <Container>
            <ModalHeader title={title} onClose={onClose} />

            <ClientName>
                {data.nome}
            </ClientName>

            {validEnds.map((item, index) => {

                if(address.formatValidEnd(data, item) != 'N/D'){
                    return (
                        <AddressItem 
                            key={index} 
                            color={selectedAddress == item ? 'var(--blue4)' : 'var(--grey2)'}
                        >
                            {selectedAddress == item 
                                ? <MdRadioButtonChecked 
                                    size={35} 
                                    color='var(--red)' 
                                    cursor='pointer'
                                    onClick={() => setSelectedAddress(item)}
                                />
                                : <MdRadioButtonUnchecked 
                                    size={35} 
                                    // style={{ marginLeft: 5}}
                                    color='var(--grey2)' 
                                    cursor='pointer'
                                    onClick={() => setSelectedAddress(item)}
                                />
                            }
                            
                            <h3>
                                <span>
                                    {`Endereço ${item}${data.validEnd == item ? ' (atual): ' : ': '}`}
                                </span>
                                {address.formatValidEnd(data, item)}
                            </h3>
                        </AddressItem>
                    )
                }
            })}
            
            <CustomDivider />
            <ActionButtons>
                {loading
                    ? <CustomLoading />
                    : <>
                        <CancelButton onClick={() => {
                            onClose()
                        }}>
                            <text>Cancelar</text>
                        </CancelButton>
                        <SaveButton onClick={async () => {
                            await onSave(selectedAddress)                               
                        }}>
                            <text>Salvar</text>
                        </SaveButton>
                    </>
                }
             </ActionButtons> 
        </Container>
    )
}

export default ModalChangeAddress;