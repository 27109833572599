import React, { useEffect, useState } from 'react';

import { 
    CancelButton,
    Container,
    SearchBar,
    SearchButton,
    SearchContainer,
    TableColumns,
    TableDivider,
    TableHeader,
    TableFooter,
    TableRows,
    TableTypeButton,
    ClearSearch
} from './styles';

//Icons
import { TbArrowWaveRightUp } from 'react-icons/tb';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FiSearch } from 'react-icons/fi';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

//Custom components
import ReturnItem from '../ReturnItem';
import MapItem from '../MapItem';
import CustomFilter from '../CustomFilter';
import DownloadButton from '../DownloadButton';
import ModalDetailsAdmin from '../ModalDetailsAdmin';

//MUI
import Menu from '@mui/material/Menu';

//Utils
import Modal from 'react-modal';
import { searchHelper } from '../../helpers/searchHelper';
import { toast } from 'react-toastify';
import { alertErrorStyle } from '../../resources/alertErrorStyle';

const alertOptions = [
    {
        title: 'não encontrados pelo sistema',
        value: 'endNotFound',
    },
    {
        title: 'não encontrados pelos operadores',
        value: 'endNotFoundOperator',
    },
    {
        title: 'localidade diversa',
        value: 'differentLocation', 
    }
]

const perPage = 25;

function CustomTable({ title, tableColumns, tableRows, tableRowsBackup, filterTypes, itemType, 
    filtersHighlights, columns, tableType, onChangeTableType, onChangeAddress, onDeleteClient, onFilter, 
    dateField, clearAllFilters, tableDefaultFilters, onClearFilters, onClick }) {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const [ defaultEdit, setDefaultEdit ] = useState(false);

    //Table
    const [ tableData, setTableData ] = useState('');
    const [ rangeOfList, setRangeOfList ] = useState({ init: 0, finish: perPage })

    //Search
    const [ search, setSearch ] = useState('');

    //Modal
    const [ modalFullData, setModalFullData ] = useState(false);
    const [ modalData, setModalData ] = useState('');

    useEffect(() => {
        setTableData(tableRows);
    }, [tableRows]);

    useEffect(() => {
        setRangeOfList({ init: 0, finish: perPage })
    }, [tableData])

    useEffect(() => {
        if(!search){
            clearSearch()
        }
    }, [search])

    const handleMenuClose = (field) => {
        // setSearch('');
        setAnchorEl(null);
    }

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const clearSearch = () => {
        setSearch('');
        setTableData(tableRowsBackup);
    }

    const searchTable = () => {
        var arrayResult = [];

        if(search){
            for(const column of tableColumns){
                const result = tableRowsBackup.filter(x => {
                    if(['cpf', 'clientCpf', 'nome', 'clientName'].includes(column.field)){
                        if(searchHelper.searchText(x[column.field], search) 
                            || searchHelper.searchText(x[column.field], search))
                        {
                            return true
                        }
                    }
                    

                    return false
                })
    
                if(result.length > 0){
                    arrayResult = arrayResult.concat(result)
                }
            }
        }else{
            return
        }

        if(arrayResult.length > 0){
            setTableData(arrayResult);
            handleMenuClose();
        }else{
            toast('Não encontrado', alertErrorStyle)
        }
    }

    return (
        <Container>
            <TableHeader>
                <div>
                    {!tableType && (
                        <>
                            <TbArrowWaveRightUp size={26} color='var(--red)' />
                            <h3>{title}</h3>
                        </>
                    )}
                    {tableType && (
                        <>
                            <TableTypeButton 
                                active={tableType == 'clientsMap' ? true : false}
                                onClick={() => onChangeTableType('clientsMap')}
                            >
                                Clientes no mapa
                            </TableTypeButton>
                            <TableTypeButton 
                                active={tableType == alertOptions[0].value ? true : false}
                                onClick={() => onChangeTableType(alertOptions[0].value)}
                            >
                                Endereços não encontrados
                            </TableTypeButton>
                            <TableTypeButton 
                                active={tableType == alertOptions[2].value ? true : false}
                                onClick={() => onChangeTableType(alertOptions[2].value)}
                            >
                                Localidade diversa
                            </TableTypeButton>
                        </>
                        
                    )}
                    <FiSearch 
                        onClick={handleMenuOpen}
                        size={20} 
                        cursor='pointer' 
                        color='var(--grey2)' 
                    />
                    <ClearSearch>
                        {search && (
                            <>
                                {search}
                                <AiOutlineCloseCircle cursor='pointer' onClick={() => {
                                    clearSearch()
                                }} />
                            </>
                        )}
                    </ClearSearch>
                </div>
                <div>
                    <CustomFilter 
                        filterTypes={filterTypes}
                        data={tableData}
                        tableRowsBackup={tableRowsBackup}
                        clearFilters={(arrayValues, filters) => {
                            setTableData(tableRows);
                            onClearFilters();
                            onFilter(arrayValues, filters)
                        }}
                        onFilter={(arrayValues, filters) => {
                            setTableData(arrayValues)
                            onFilter(arrayValues, filters)
                        }}
                        filterType='atendimentos'
                        filtersHighlights={filtersHighlights}
                        dateField={dateField}
                        clearAll={clearAllFilters}
                        tableDefaultFilters={tableDefaultFilters}
                    />
                    <DownloadButton tableColumns={tableColumns} tableRows={tableData} />
                </div>
            </TableHeader>
            <TableColumns columns={columns}>
                {tableColumns && tableColumns.map((item, index) => (
                    <h3 key={index}>{item.headerName}</h3>
                ))}
            </TableColumns>
            <TableRows>
                {tableData && tableData.slice(rangeOfList.init, rangeOfList.finish).map((item, index) => {

                    if(itemType == 'map'){
                        return (
                            <MapItem 
                                key={index} 
                                data={item} 
                                tableType={tableType}
                                onClick={() => onClick(item)}
                                onChangeAddress={() => onChangeAddress(item)}
                                onView={() => {
                                    setDefaultEdit(false);
                                    setModalData(item);
                                    setModalFullData(true)
                                }}
                                onEdit={() => {
                                    setDefaultEdit(true);
                                    setModalData(item);
                                    setModalFullData(true)
                                }}
                            />
                        )
                    }else{
                        return(
                            <ReturnItem 
                                key={index} 
                                data={item} 
                                onClick={() => {
                                    setModalData(item);
                                    setModalFullData(true)
                                }}
                            />
                        )
                    }
                })}
            </TableRows>
            <TableFooter>
                <h3>{rangeOfList.init} - {rangeOfList.finish}</h3>
                <div>
                    <MdKeyboardArrowLeft 
                        size={20}
                        cursor='pointer'
                        onClick={() => {
                            if(rangeOfList.finish == tableData.length){
                                const value = (rangeOfList.finish - rangeOfList.init) + perPage;

                                const newFinish = rangeOfList.init;

                                setRangeOfList({ init: rangeOfList.finish - value, finish: newFinish})
                            }else{
                                if((rangeOfList.init - perPage) >= 0){
                                    setRangeOfList({ init: rangeOfList.init - perPage, finish: rangeOfList.finish-perPage})
                                }
                            }
                        }}
                    />
                    <MdKeyboardArrowRight 
                        size={20}
                        cursor='pointer'
                        onClick={() => {
                            if(tableData.length > perPage){
                                if((rangeOfList.finish + perPage) <= tableData.length){
                                    setRangeOfList({ init: rangeOfList.finish, finish: rangeOfList.finish+perPage})
                                }else{
                                    if(rangeOfList.finish != tableData.length){
                                        const value = rangeOfList.finish+(tableData.length - rangeOfList.finish);
    
                                        setRangeOfList({ init: rangeOfList.finish, finish: value})
                                    }
                                }
                            }
                        }}
                    />
                </div>
                <h3>{tableData ? tableData.length : 0} clientes</h3>
            </TableFooter>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                PaperProps={{
                    style: {
                        width: 600,
                        height: 40,
                        maxHeight: 40,
                        borderRadius: 20,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        overflow: 'hidden',
                    },
                }}
            >
                <SearchContainer onSubmit={(e) => { 
                    e.preventDefault();
                    searchTable()
                }}>
                    <FiSearch 
                        size={18} 
                        cursor='pointer' 
                        color='#626F87'
                        strokeWidth='3'
                    />
                     <text>&#8288;</text>
                    <SearchBar>
                        <input 
                            name="search" 
                            onChange={event => setSearch(event.target.value)}
                            value={search}
                            placeholder='Buscar...'
                            autoFocus={true}
                        />
                    </SearchBar>
                    <CancelButton onClick={handleMenuClose}>
                        Cancelar
                    </CancelButton>
                    <SearchButton onClick={() => searchTable()}>
                        Buscar
                    </SearchButton>
                </SearchContainer>
            </Menu>

            <Modal
                isOpen={modalFullData}
                onRequestClose={() => setModalFullData(false)}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalDetailsAdmin 
                    data={modalData} 
                    onClose={() => setModalFullData(false)}
                    defaultEdit={defaultEdit}
                    onStreetView={() => {
                        
                    }}
                />
            </Modal>
        </Container>
    )
}

export default CustomTable;