import React, { useContext, useEffect, useState } from 'react';

import { Container, Header, Table, TableColumns, TableContainer, TableList } from './styles';

import { CancelButton, SearchBar, SearchButton, SearchContainer, ClearSearch, TableFooter } from '../../components/CustomTable/styles';

//Firebase and context
import { DatabaseContext } from '../../contexts/DatabaseContext';
import { ReportsContext } from '../../contexts/ReportsContext';

//Icons
import { FiSearch } from 'react-icons/fi';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

//Custom components
import ProfileButton from '../../components/ProfileButton'
import CustomLoadingPage from '../../components/CustomLoadingPage'
import DatabaseItem from '../../components/DatabaseItem'
import ModalDetailsAdmin from '../../components/ModalDetailsAdmin';

//Utils
import Modal from 'react-modal'

//MUI
import Menu from '@mui/material/Menu';
import CustomFilterHeader from '../../components/CustomFilterHeader';
import { reportResults } from '../../constants/reportResults';
import { arrayHelper } from '../../helpers/arrayHelper';
import { searchHelper } from '../../helpers/searchHelper';
import { alertErrorStyle } from '../../resources/alertErrorStyle';
import { toast } from 'react-toastify';

const columns = [
    'nome',
    'cpf',
    'operatorName',
    'especie',
    'result',
    'cidadeMap',
]

const perPage = 25;

function DatabasePage() {

    const { clientsDb, getClientsDb } = useContext(DatabaseContext);
    const { reports, getReportsAdmin } = useContext(ReportsContext);

    const [ loadingData, setLoadingData ] = useState(true);
    const [ tableData, setTableData ] = useState('');
    const [ tableDataBackup, setTableDataBackup ] = useState('');

    const [ modalFullData, setModalFullData ] = useState(false);
    const [ currentClient, setCurrentClient ] = useState('');

    const [ rangeOfList, setRangeOfList ] = useState({ init: 0, finish: perPage })

    const [ search, setSearch ] = useState('');
    const [ searchBar, setSearchBar ] = useState(null);
    const openSearch = Boolean(searchBar);

    useEffect(() => {
        (async () => {
            if(!clientsDb || !reports){
                if(!clientsDb){
                    await getClientsDb();
                }

                if(!reports){
                    await getReportsAdmin();
                }
            }else{
                let array = [];
                const semReport = [];

                for(const cliente of clientsDb){
                    if(cliente.reportId){

                        const report = reports.find(x => x.docId == cliente.reportId);

                        if(report){
                            array.push({
                                ...cliente,
                                ...report,
                                dataInclusao: cliente.time,
                                reportOperatorUid: report.operatorUid
                            })
                        }else{
                            semReport.push(cliente)
                        }
                    }else{
                        array.push({
                            ...cliente,
                            clientName: cliente.nome,
                            clientCpf: cliente.cpf,
                            operatorName: 'Sem operador',
                            dataInclusao: cliente.time,
                            reportOperatorUid: '',
                            result: "Não atendido"
                        })
                    }
                }

                const indicacao = reports.filter(x => x.result == reportResults[1])

                array = array.concat(indicacao)
                array = arrayHelper.ordenateArrayDesc(array, 'time');
                
                setTableData(array)
                setTableDataBackup(array)
                setLoadingData(false)
            }
        })();
    }, [clientsDb, reports])

    useEffect(() => {
        setRangeOfList({ init: 0, finish: perPage })
    }, [tableData])

    useEffect(() => {
        if(!search && tableData){
            clearSearch()
        }
    }, [search])

    const clearSearch = () => {
        setTableData(tableDataBackup)
        setSearch('');
    }

    const searchTable = () => {
        var arrayResult = [];

        if(search){
            const array = [];

            for(const item of tableDataBackup){
                for(const column of columns){
                    if(item[column]){
                        if(searchHelper.searchText(item[column], search) 
                                || searchHelper.searchText(item[column], search))
                        {
                            array.push(item)
                        }
                    }
                }
            }
            
            arrayResult = array
        }else{
            return
        }

        if(arrayResult.length > 0){
            setTableData(arrayResult);
            setSearchBar(null);
        }else{
            toast('Não encontrado', alertErrorStyle)
        }
    }

    if(loadingData){
        return (
            <Container>
                <CustomLoadingPage />
            </Container>
        )
    }

    return (
        <Container>
            <Header>
                <div>
                    <h1>Banco de dados</h1>
                    <FiSearch 
                        onClick={(event) => {
                            setSearchBar(event.currentTarget);
                        }}
                        size={20} 
                        cursor='pointer' 
                        color='var(--grey2)' 
                    />
                    <ClearSearch>
                        {search && (
                            <>
                                {search}
                                <AiOutlineCloseCircle cursor='pointer' onClick={() => {
                                    clearSearch()
                                }} />
                            </>
                        )}
                    </ClearSearch>
                </div>
                <div>
                    <CustomFilterHeader 
                        data={tableData}
                        tableRowsBackup={tableDataBackup}
                        clearFilters={() => {
                            setTableData(tableDataBackup)
                        }}
                        onFilter={(arrayValues, filters) => {
                            setTableData(arrayValues)
                        }}
                        filterType='atendimentos'
                        filterTypes={[
                            { headerName: 'Operador', field: 'operatorName'},
                            { headerName: 'Resultado', field: 'result'}
                        ]}
                        dateField='time'
                    />
                    <ProfileButton arrowColor='var(grey2)' />
                </div>
            </Header>

            <TableContainer>
                <Table>
                    <TableColumns>
                        <h3>Cliente</h3>
                        <h3>CPF</h3>
                        <h3>Operador</h3>
                        <h3>Benefício</h3>
                        <h3>Resultado</h3>
                        <h3>Cidade</h3>
                        <h3>Data</h3>
                    </TableColumns>
                    <TableList>
                        {tableData && tableData.slice(rangeOfList.init, rangeOfList.finish).map((item, index) => (
                            <DatabaseItem 
                                key={index}
                                onView={() => {
                                    setCurrentClient(item)
                                    setModalFullData(true)
                                }}
                                data={item}
                            />
                        ))}
                    </TableList>
                    <TableFooter>
                        <h3>{rangeOfList.init} - {rangeOfList.finish}</h3>
                        <div>
                            <MdKeyboardArrowLeft 
                                size={20}
                                cursor='pointer'
                                onClick={() => {
                                    if(rangeOfList.finish == tableData.length){
                                        const value = (rangeOfList.finish - rangeOfList.init) + perPage;

                                        const newFinish = rangeOfList.init;

                                        setRangeOfList({ init: rangeOfList.finish - value, finish: newFinish})
                                    }else{
                                        if((rangeOfList.init - perPage) >= 0){
                                            setRangeOfList({ init: rangeOfList.init - perPage, finish: rangeOfList.finish-perPage})
                                        }
                                    }
                                }}
                            />
                            <MdKeyboardArrowRight 
                                size={20}
                                cursor='pointer'
                                onClick={() => {
                                    if(tableData.length > perPage){
                                        if((rangeOfList.finish + perPage) <= tableData.length){
                                            setRangeOfList({ init: rangeOfList.finish, finish: rangeOfList.finish+perPage})
                                        }else{
                                            if(rangeOfList.finish != tableData.length){
                                                const value = rangeOfList.finish+(tableData.length - rangeOfList.finish);
            
                                                setRangeOfList({ init: rangeOfList.finish, finish: value})
                                            }
                                        }
                                    }
                                }}
                            />
                        </div>
                        <h3>{tableData ? tableData.length : 0} clientes</h3>
                    </TableFooter>
                </Table>
            </TableContainer>

            <Modal
                isOpen={modalFullData}
                onRequestClose={() => setModalFullData(false)}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalDetailsAdmin 
                    data={currentClient} 
                    onClose={() => setModalFullData(false)}
                    onStreetView={() => {

                    }}
                />
            </Modal>

            <Menu
                id="basic-menu"
                anchorEl={searchBar}
                open={openSearch}
                onClose={() => {
                    setSearchBar(null);
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                PaperProps={{
                    style: {
                        width: 600,
                        height: 40,
                        maxHeight: 40,
                        borderRadius: 20,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        overflow: 'hidden',
                    },
                }}
            >
                <SearchContainer onSubmit={(e) => { 
                    e.preventDefault();
                    searchTable()
                }}>
                    <FiSearch 
                        size={18} 
                        cursor='pointer' 
                        color='#626F87'
                        strokeWidth='3'
                    />
                     <text>&#8288;</text>
                    <SearchBar>
                        <input 
                            name="search" 
                            onChange={event => setSearch(event.target.value)}
                            value={search}
                            placeholder='Buscar...'
                            autoFocus={true}
                        />
                    </SearchBar>
                    <CancelButton onClick={() => {
                        setSearchBar(null);
                    }}>
                        Cancelar
                    </CancelButton>
                    <SearchButton onClick={() => searchTable()}>
                        Buscar
                    </SearchButton>
                </SearchContainer>
            </Menu>
        </Container>
    )
}

export default DatabasePage;