import styled from 'styled-components';

export const Container = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    column-gap: 20px;
    width: 100%;
    max-height: 582px;

    background: var(--white);
    /* shadowcard */

    box-shadow: 0px 4px 20px rgba(35, 54, 63, 0.05);
    border-radius: 20px;
`;

export const Title = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    margin-bottom: 20px;
`;

export const OperatorsList = styled.div`
    display: flex;
    flex-direction: column;

    overflow-y: auto;
    max-height: 552px;

    color: var(--red);

    padding: 20px;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        color: var(--grey2);
    }
`;

export const HeaderList = styled.div`
    display: grid;
    grid-template-columns: 2fr repeat(3, 1fr);
    height: 60px;
    width: 100%;

    padding: 0 10px;

    align-items: center;
    /* justify-content: center; */

    background: rgba(236, 242, 251, 0.5);
    border-radius: 10px;
`;

export const ListContainer = styled.div`
    display: flex;
    flex-direction: column;

    overflow-y: auto;
    width: 100%;
`;

export const OperatorItem = styled.div`
    display: grid;
    grid-template-columns: 2fr repeat(3, 1fr);

    padding: 0 15px;
    cursor: pointer;
    user-select: none;

    align-items: center;
    min-height: 59px;

    background: ${props => props.active ? 'rgba(104, 207, 201, 0.2)' : 'var(--white)'};

    div {
        display: flex;
        flex-direction: row;
        align-items: center;

        gap: 10px
    }

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        /* identical to box height */


        /* 2 */

        color: #808B9F;
    }
`;

export const OperatorsInfo = styled.div`
    display: flex;
    flex-direction: column;

    padding: 60px 20px 20px 20px;

    overflow-y: auto;
    max-height: 552px;

    background: #F8FBFF;

    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    gap: 10px
`;

export const HeaderInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    height: 121px;

    background: #68CFC9;
    border-radius: 20px;

    gap: 10px;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;

        color: #FFFFFF;
    }
`;

export const InfoItem = styled.div`
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    align-items: center;
    padding: 20px;

    cursor: pointer;
    user-select: none;

    min-height: 61px;

    background: var(--white);
    box-shadow: 0px 4px 10px rgba(173, 173, 173, 0.25);
    border-radius: 20px;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        color: #808B9F;
    }
`;

export const InfoItemHeader = styled.div`
    display: flex;
    justify-content: space-between;

    align-items: center;

    width: 100%;

    div {
        display: flex;
        flex-direction: row;
        align-items: center;

        gap: 10px;
    }

    h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 24px;
        /* or 75% */

        text-align: center;

        color: ${props => props.color};
    }
`;

export const InfoItemDescription = styled.div`
    display: flex;
    flex-flow: wrap;

    gap: 5px;

    width: 100%;
    padding: 20px 0;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 0.8rem;
        line-height: 24px;

        color: #808B9F;
    }

    span {
        font-weight: 600;
    }
`;

export const Circle = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 41px;
    height: 41px;

    color: var(--white);

    border-radius: 100px;

    background: ${props => props.color};
`;