import styled from "styled-components";

export const Container = styled.div`
    padding: 20px;
    background: #fff;

    border-radius: 20px;

    width: 55vw;
`;

export const Header = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-between;

    padding-bottom: 10px;

    height: 20%;

    user-select: none;

    color: #4A5E83;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 19px;

        color: #4A5E83;
    }

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;

        color: #808B9F;
    }

    div {
        display: flex;
        flex-direction: row;
        align-items: center;

        gap: 10px
    }
`;

export const ModalLogsContent = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
`;

export const LogsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;

    height: 75vh;

    overflow-y: auto;
    /* justify-content: center;
    align-items: center; */
`;

export const NumberOfLines = styled.div`
    display: flex;
    flex-direction: row;

    align-items: flex-end;
    justify-content: flex-end;
    
    padding: 5px 15px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    color: #808B9F;
`;

export const LogItem = styled.div`
    display: grid;
    grid-template-columns: 0.1fr 1fr;

    align-items: initial;

    min-height: 40px;

    gap: 10px;

    div {
        display: flex;
        flex-direction: column;

        /* justify-content: center; */
        align-items: center;
    }

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        padding-bottom: 10px;

        /* 1 */

        color: #4A5E83;
    }
`;

export const Circle = styled.div`
    min-width: 11px;
    min-height: 11px;
    background: #68CFC9;

    border-radius: 100px;
`;

export const Line = styled.div`
    width: 1px;
    height: 100%;
    background: #68CFC9;
`;