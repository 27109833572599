import React from 'react';

//pages
import MapPage from "../Pages/MapPage";
import LoginPage from '../Pages/LoginPage';
import DashboardPage from "../Pages/DashboardPage";
import UploadPage from "../Pages/UploadPage";
import ManageUsersPage from '../Pages/ManageUsersPage';
import AdminRoutesPage from "../Pages/AdminRoutesPage";
import AdminMapPage from "../Pages/AdminMapPage/index";
import PjeLogsPage from "../Pages/PjeLogsPage";
import CloseRoutesLogsPage from "../Pages/CloseRoutesLogsPage";
import AdminReturnsPage from '../Pages/AdminReturnsPage';
import DatabasePage from '../Pages/DatabasePage';
import SystemLogsPage from '../Pages/SystemLogsPage';
import RemovePage from '../Pages/RemovePage';

//react route
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PrivateRoute } from '../auth/PrivateRoute';
import { AdminRoute } from '../auth/AdminRoute';

//Util
import MenuBar from '../components/MenuBar';
import GlobalStyles from '../GlobalStyles';

const Router = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path='/dashboard' element={<AdminRoute redirectTo={"/"}>
                    <GlobalStyles />
                    <MenuBar />
                    <DashboardPage />
                </AdminRoute>} />

                <Route path='/pjeLogs' element={<AdminRoute redirectTo={"/"}>
                    <GlobalStyles/>
                    <MenuBar />
                    <PjeLogsPage />
                </AdminRoute>} />

                <Route path='/closeRoutesLogs' element={<AdminRoute redirectTo={"/"}>
                    <GlobalStyles/>
                    <MenuBar />
                    <CloseRoutesLogsPage />
                </AdminRoute>} />

                <Route path='/map' element={<PrivateRoute redirectTo={"/"}>
                    <GlobalStyles />
                    <MapPage />
                </PrivateRoute>} />

                <Route path='/upload' element={<AdminRoute redirectTo={"/"}>
                    <GlobalStyles/>
                    <MenuBar />
                    <UploadPage />
                </AdminRoute>} />

                <Route path='/remove' element={<AdminRoute redirectTo={"/"}>
                    <GlobalStyles/>
                    <MenuBar />
                    <RemovePage />
                </AdminRoute>} />

                <Route path='/manageUsers' element={<AdminRoute redirectTo={"/"}>
                    <GlobalStyles/>
                    <MenuBar />
                    <ManageUsersPage />
                </AdminRoute>} />

                <Route path='/adminRoutesPage' element={<AdminRoute redirectTo={"/"}>
                    <GlobalStyles/>
                    <MenuBar />
                    <AdminRoutesPage />
                </AdminRoute>} />

                <Route path='/adminMapPage' element={<AdminRoute redirectTo={"/"}>
                    <GlobalStyles/>
                    <MenuBar />
                    <AdminMapPage />
                </AdminRoute>} />

                <Route path='/adminReturnsPage' element={<AdminRoute redirectTo={"/"}>
                    <GlobalStyles/>
                    <MenuBar />
                    <AdminReturnsPage />
                </AdminRoute>} />

                <Route path='/databasePage' element={<AdminRoute redirectTo={"/"}>
                    <GlobalStyles/>
                    <MenuBar />
                    <DatabasePage />
                </AdminRoute>} />

                <Route path='/systemLogs' element={<AdminRoute redirectTo={"/"}>
                    <GlobalStyles/>
                    <MenuBar />
                    <SystemLogsPage />
                </AdminRoute>} />
                
                <Route path="/" element={<>
                    <GlobalStyles />
                    <LoginPage />
                </>}  />
            </Routes>
        </BrowserRouter>
    )
}

export default Router;