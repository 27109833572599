import React, { useContext } from 'react';

import { 
    Container,
    Name,
    Especie,
    TextResult
} from './styles';

//Firebase and context
import { UsersContext } from '../../contexts/UsersContext';

//Icons
import cpfIcon from '../../assets/cpfIcon.svg';
import { GoPrimitiveDot } from 'react-icons/go';
import { BiMap } from 'react-icons/bi';
import { AiOutlineCalendar } from 'react-icons/ai';
import { AiOutlineEye } from 'react-icons/ai';

//MUI
import { Avatar } from '@mui/material';

//utils
import { avatar } from '../../helpers/avatar';
import { datetime } from '../../helpers/datetime';
import { especiesHelper } from '../../helpers/especiesHelper';

const options = [
    "Contrato fechado",
    "Contrato fechado por indicação",
    "Endereço não encontrado",
    "Litispendência",
    "Já tem advogado",
    "Retornar",
    "Não tem direito",
    "Não tem interesse",
    "Localidade diversa",
    "Não atendido"
]

const optionsColors = [
    "var(--success)",
    "var(--success)",
    "var(--red)",
    "var(--red)",
    "var(--red)",
    "var(--yellow)",
    "var(--red)",
    "var(--red)",
    "var(--red)",
    "var(--grey2)"
]

function DatabaseItem({ data, onView }) {

    const { users } = useContext(UsersContext);

    return (
        <Container>
            <Name>{data.clientName}</Name>
            <div>
                <img src={cpfIcon} alt='cpf' />
                <h4>{data.clientCpf}</h4>
            </div>
            <div>
                <Avatar
                    alt="Avatar"
                    src={avatar.getUserAvatar(users.operators, data.reportOperatorUid)}
                    sx={{ 
                        width: 20, 
                        height: 20, 
                        borderRadius: 100, 
                        borderColor: 'white', 
                        borderWidth: 10 
                    }}
                />
                <h4>{data.operatorName}</h4>
            </div>
            <Especie 
                color={especiesHelper.getEspecieTag(data.especie).tagColor}
                textColor={especiesHelper.getEspecieTag(data.especie).textColor}
            >
                {data.especie}
            </Especie>
            {data.result && (
                <div>
                    <GoPrimitiveDot size={10} color={optionsColors[options.findIndex(x => x == data.result)]} />
                    <TextResult color={optionsColors[options.findIndex(x => x == data.result)]}>{data.result}</TextResult>
                </div>
            )}

            {data.cidade && (
                <div>
                    <BiMap size={15} color='var(--grey2)' />
                    <h4>{data.cidade}</h4>
                </div>
            )}

            {!data.cidade && (
                <div>
                    <BiMap size={15} color='var(--grey2)' />
                    <h4>{data[`cidade${data.validEnd}`]}</h4>
                </div>
            )}
            
            <div>
                <AiOutlineCalendar color='var(--grey2)' />
                <h4>{datetime.getDate(data.time)}</h4>
            </div>
            <div>
                <AiOutlineEye 
                    onClick={onView} 
                    cursor='pointer' 
                    size={18} 
                    color='#808B9F' 
                    fill='#808B9F'
                />
            </div>
        </Container>
    )
}

export default DatabaseItem;