import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    min-height: 575px;
    max-height: 575px;
    height: 575px;

    padding: 20px;
    margin-top: 20px;

    background: #FFFFFF;
    /* shadowcard */

    box-shadow: 0px 4px 20px rgba(35, 54, 63, 0.05);
    border-radius: 20px;
`;

export const TableHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 20px;

    div {
        display: flex;
        align-items: center;

        gap: 15px;
    }

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        color: #808B9F;
    }
`;

export const TableColumns = styled.div`
    display: grid;
    grid-template-columns: 1.5fr ${props => `repeat(${props.columns-3}, 1fr)`} repeat(2, 0.75fr);
    height: 46px;
    width: 100%;

    padding: 0 10px;

    align-items: center;
    /* justify-content: center; */

    background: rgba(236, 242, 251, 0.5);
    border-radius: 10px;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;

        color: #808B9F;
    }
`;

export const TableRows = styled.div`
    overflow-y: auto;
    width: 100%;
    height: 78%;
`;

export const TableDivider = styled.div`
    border: 1px solid #ECF2FB;
    width: 100%;
`;

export const TableFooter = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 2px 20px;

    user-select: none;

    gap: 10px;

    color: #808B9F;

    div {
        display: flex;
        flex-direction: row;

        align-items: center;

        gap: 10px;
    }

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        color: #808B9F;
    }
    
`;

export const SearchContainer = styled.form`
    display: flex;
    flex-direction: row;

    user-select: none;

    align-items: center;
    padding: 10px 0px 10px 10px;
    gap: 10px;
`;

export const SearchBar = styled.div`
    width: 400px;

    input {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */


        /* 2 */

        color: #808B9F;
    }
`;

export const CancelButton = styled.div`
    cursor: pointer;
    user-select: none;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */


    /* 2 */

    color: #808B9F;
`;

export const SearchButton = styled.div`
    background: #DE6161;
    border-radius: 100px;

    cursor: pointer;
    user-select: none;

    padding: 7px 40px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;

    color: #FFFFFF;
`;

export const TableTypeButton = styled.div`
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
    padding: 9px 12px;

    cursor: pointer;
    user-select: none;

    color: ${props => props.active ? 'var(--white)' : '#626F87'};

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    background: ${props => props.active ? 'var(--red)' : 'transparent'};
    border-radius: 100px;
`;

export const ClearSearch = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */


    /* 2 */

    color: #808B9F;
`;
