import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;

    align-items: center;
    justify-content: center;
    align-self: center;
    background: ${props => props.background ? props.background : 'transparent'};
`;

export const Text = styled.text`
    font-size: 16px;
    color: var(--red);
    text-align: center;
    margin-top: 20px;
`;