import React, { useState, useContext, useEffect } from 'react';

import { 
    Container,
    Title,
    OperatorsList,
    HeaderList,
    ListContainer,
    OperatorItem,
    OperatorsInfo,
    HeaderInfo,
    InfoItem,
    Circle
} from './styles';

//Firebase and context
import { UsersContext } from '../../contexts/UsersContext';

//Icons
import checkedIcon from '../../assets/checkedIcon.svg';
import clockIcon from '../../assets/clockIcon.svg';
import expiredIcon from '../../assets/expiredIcon.svg';
import expiredIconWhite from '../../assets/expiredIconWhite.svg';
import centralLogo from '../../assets/centralLogo.png';

import { FiUsers, FiCheckSquare } from 'react-icons/fi';
import { TbRoute, TbFileInvoice } from 'react-icons/tb';
import { AiOutlineClockCircle } from 'react-icons/ai';

//MUI
import { Avatar } from '@mui/material';
import CustomLoading from '../CustomLoading';

function OperatorsCard({ data, onFilter, unFilter }) {

    const { users } = useContext(UsersContext);

    const [ loadingData, setLoadingData ] = useState(true);
    const [ selected, setSelected ] = useState(0);
    const [ arrayData, setArrayData ] = useState('');

    useEffect(() => {
        (async () => {
            setLoadingData(true);
            if(data){
                const array = [];
                var arrayUsers = [];

                arrayUsers = users.operators.filter(x => x.disabled != true);

                array.push({
                    userName: 'Geral',
                    img64: null,
                    realizedReturns: data.realizedReturns,
                    pendingReturns: data.pendingReturns,
                    inRouteReturns: data.inRouteReturns,
                    expiredReturns: data.expiredReturns,
                    closedReturns: data.closedByReturns,
                })

                for(const user of arrayUsers){
                    const findRealized = data.realizedReturns.filter(x => x.operatorUid === user.uid);
                    const findPending = data.pendingReturns.filter(x => x.operatorUid === user.uid);
                    const findInRoute = data.inRouteReturns.filter(x => x.operatorUid === user.uid);
                    const findExpired = data.expiredReturns.filter(x => x.operatorUid === user.uid);
                    const findClosedByReturn = data.closedByReturns.filter(x => x.operatorUid === user.uid);
                    
                    array.push({
                        ...user,
                        realizedReturns: findRealized,
                        pendingReturns: findPending,
                        inRouteReturns: findInRoute,
                        expiredReturns: findExpired,
                        closedReturns: findClosedByReturn,
                    })
                }
                
                setArrayData(array);
                setLoadingData(false)
            }else{
                // if(!returnsAdmin){
                //     await getReturnsAdmin();
                // }
                // if(!routesAdmin){
                //     await getRoutesAdmin();
                // }
            }
        })();
    }, [data, users]);

    const handleSelect = (newValue) => {
        if(newValue == selected){
            unFilter()
            setSelected(0)
        }else{
            setSelected(newValue)
            proccessToFilter(newValue)
        }
    }

    const proccessToFilter = (newValue) => {
        const aux = arrayData[newValue];

        const dataToFilter = aux.realizedReturns.concat(aux.pendingReturns).concat(aux.inRouteReturns).concat(aux.expiredReturns)
        onFilter(dataToFilter);
    }

    if(loadingData){
        return (
            <Container>
                <CustomLoading />
            </Container>
        )
    }
   
    return (
        <Container>
            <OperatorsList>
                <Title>
                    <FiUsers />
                    <h3>Operadores</h3>
                </Title>
                <HeaderList>
                    <h3>Operador</h3>
                    <h3>Realizados</h3>
                    <h3>Pendentes</h3>
                    <h3>Expirados</h3>
                </HeaderList>
                <ListContainer>
                    {arrayData.map((item, index) => {

                        if(item.userName != 'Geral'){
                            return (
                                <OperatorItem key={index} active={selected == index ? true : false} onClick={() => handleSelect(index)}>
                                    <div>
                                        <Avatar
                                            alt="Avatar"
                                            src={item.img64  ? 
                                                `data:image;base64,${item.img64}`
                                                : centralLogo
                                            }
                                            sx={{ width: 30, height: 30, borderRadius: 100, borderColor: 'white', borderWidth: 20 }}
                                        />
                                        <h4>{item.userName}</h4>
                                    </div>
                                    <div>
                                        <img src={checkedIcon} alt='icon icon' />
                                        <h4>{item.realizedReturns.length}</h4>
                                    </div>
                                    <div>
                                        <img src={clockIcon} alt='icon icon' />
                                        <h4>{item.pendingReturns.length}</h4>
                                    </div>
                                    <div>
                                        <img src={expiredIcon} alt='icon icon' />
                                        <h4>{item.expiredReturns.length}</h4>
                                    </div>
                                </OperatorItem>
                            )
                        }
                    })}
                </ListContainer>
            </OperatorsList>
            {arrayData.length > 0 && (
                <OperatorsInfo>
                    <HeaderInfo>
                        <Avatar
                            alt="Avatar"
                            src={arrayData[selected].img64 ? 
                                `data:image;base64,${arrayData[selected].img64}`
                                : centralLogo
                            }
                            sx={{ 
                                marginTop: -5, 
                                width: 100, 
                                height: 100, 
                                borderRadius: 100, 
                                borderColor: 'white', 
                                borderWidth: 10 
                            }}
                        />
                        <h3>{arrayData[selected].userName}</h3>
                    </HeaderInfo>
                    <InfoItem color='var(--success)' onClick={() => {
                        onFilter(arrayData[selected].realizedReturns)
                    }}>
                        <div>
                            <Circle color='var(--success)'>
                                <FiCheckSquare />
                            </Circle>
                            <h3>Retornos realizados</h3>
                        </div>
                        <h1>{arrayData[selected].realizedReturns.length}</h1>
                    </InfoItem>
                    <InfoItem color='var(--yellow)' onClick={() => {
                        onFilter(arrayData[selected].pendingReturns)
                    }}>
                        <div>
                            <Circle color='var(--yellow)'>
                                <AiOutlineClockCircle />
                            </Circle>
                            <h3>Retornos a realizar</h3>
                        </div>
                        <h1>{arrayData[selected].pendingReturns.length}</h1>
                    </InfoItem>
                    <InfoItem color='var(--blue)' onClick={() => {
                        onFilter(arrayData[selected].inRouteReturns)
                    }}>
                        <div>
                            <Circle color='var(--blue)'>
                                <TbRoute />
                            </Circle>
                            <h3>Retornos em rota</h3>
                        </div>
                        <h1>{arrayData[selected].inRouteReturns.length}</h1>
                    </InfoItem>
                    <InfoItem color='var(--red)' onClick={() => {
                        onFilter(arrayData[selected].expiredReturns)
                    }}>
                        <div>
                            <Circle color='var(--red)'>
                                <img src={expiredIconWhite} alt='icon' />
                            </Circle>
                            <h3>Retornos expirados</h3>
                        </div>
                        <h1>{arrayData[selected].expiredReturns.length}</h1>
                    </InfoItem>
                    <InfoItem color='var(--success)' onClick={() => {
                        onFilter(arrayData[selected].closedReturns)
                    }}>
                        <div>
                            <Circle color='var(--success)'>
                                <TbFileInvoice />
                            </Circle>
                            <h3>Contratos fechados após retorno</h3>
                        </div>
                        <h1>{arrayData[selected].closedReturns.length}</h1>
                    </InfoItem>
                </OperatorsInfo>
            )}
        </Container>
    )
}

export default OperatorsCard;