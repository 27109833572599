export const especiesColor = [
    {
        especie: "LOAS DEF",
        tagColor: '#808B9F',
        textColor: "#FFFFFF"
    },
    {
        especie: "LOAS IDOSO",
        tagColor: '#9BBEFF',
        textColor: "#FFFFFF"
    },
    {
        especie: "AUX. DOENÇA",
        tagColor: "#DE6161",
        textColor: "#FFFFFF"
    },
    {
        especie: "AUX. BRASIL",
        tagColor: "#ffd41f",
        textColor: "#FFFFFF"
    },
    {
        especie: "PENSÃO POR MORTE",
        tagColor: "#8a2f62",
        textColor: "#FFFFFF"
    },
    {
        especie: "AUX. DOENÇA POR ACIDENTE DO TRABALHO",
        tagColor: "#482c21",
        textColor: "#FFFFFF"
    },
    {
        especie: "AUX. ACIDENTE",
        tagColor: "#6ca6a3",
        textColor: "#FFFFFF"
    },
]