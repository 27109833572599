import React, { useContext, useEffect, useState } from 'react';

import { 
    ClientHeader, 
    ClientNameContent, 
    RowContent, 
    RowItem, 
    Container, 
    DetailsContainer, 
    Divider, 
    ImageEspecie, 
    ImageStreetView, 
    InfoHeader,
    ReportDescription,
    ReportHeader,
    ReportItem,
    ReportsContent,
    Content,
    ResultText,
    AnaliseText,
    ClientHeaderLeft,
} from './styles';

//Firebase and context
import { AuthContext } from '../../contexts/AuthContext';
import { collection, query, where, getDocs } from "firebase/firestore";
import db from '../../firebase/config';

//Icons
import { FiMapPin } from 'react-icons/fi';
import { TbPhoneCall, TbHammer, TbBriefcase, TbFileInvoice, TbFileSearch } from 'react-icons/tb';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

//Custom components
import CustomLoading from '../CustomLoading';
import ModalHeader from '../ModalHeader';
import axios from 'axios';

//utils
import { markers } from '../../helpers/markers';
import { address } from '../../helpers/address';
import { datetime } from '../../helpers/datetime';
import { addUserLogs } from '../../firebase/logs/users/addUserLogs';
import { addClientLogs } from '../../firebase/logs/clients/addClientLogs';
import ClientBadges from '../ClientBadges';


const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

function ModalDetailsOperator({ data, onClose, onStreetView }) {

    const { isAdmin, user, userData } = useContext(AuthContext);

    const [ loadingData, setLoadingData ] = useState(true);
    const [ client, setClient ] = useState('');
    const [ clientReports, setClientReports ] = useState('');
    const [ imageStreetView, setImageStreetView ] = useState('');
    const [ expanded, setExpanded ] = useState(null);

    useEffect(() => {
        (async () => {
            setLoadingData(true);
            const arrayReports = [];

            await axios.get(`https://maps.googleapis.com/maps/api/streetview?size=724x144&location=${data.lat},${data.lng}&fov=80&heading=70&pitch=0&key=${apiKey}`, { responseType: "blob" })
                .then((response) => {
                    setImageStreetView(response.data);
                })
                .catch((error) => {
                    
                })

            const logMessage = `${data.nome} teve suas informações visualizadas pelo operador ${userData.userName}`
            const logMessageUser = `${userData.userName} visualizou as informações do cliente ${data.nome}`

            await addClientLogs(data.cpf, logMessage);
            await addUserLogs(userData.uid, logMessageUser); 
            
            const consulta = query(collection(db, "reports"), where("operatorUid", "==", user.uid), where("clientCpf", "==", data.cpf));

            const querySnapshot = await getDocs(consulta);
            querySnapshot.forEach((doc) => {
                arrayReports.push({...doc.data(), docId: doc.id})
            });

            const ordenatedReports= arrayReports.sort((a, b) => {
				if(a.time > b.time){
					return -1;
				}
				if(a.time < b.time){
					return 1
				};
				return 0;
			}); 

            setClient(data);
            setClientReports(ordenatedReports)
            setLoadingData(false);
        })();
    }, []);

    if(loadingData || !client){
        return (
            <Container>
                <CustomLoading background='transparent' />
            </Container>
        )
    }
    
    return (
        <Container>
            <ModalHeader 
                title='Detalhes do cliente'
                onClose={onClose}
            />
            <Content>
                <ImageStreetView
                    src={URL.createObjectURL(imageStreetView)}
                    onClick={() => onStreetView('Modal')}
                />

                <ClientHeader>
                    <ClientHeaderLeft>
                        <ImageEspecie>
                            <img 
                                src={markers.getMarkerIconsEspecie(client.especie)} 
                                style={{ width: 30, height: 30, objectFit: 'cover'}} 
                            />
                        </ImageEspecie>
                        <ClientNameContent>
                            <h4>{client.nome}</h4>
                            <text>{client.especie}</text>
                        </ClientNameContent>
                    </ClientHeaderLeft>
                    <ClientBadges data={client} isModalDetails />
                </ClientHeader>

                <Divider />

                <DetailsContainer>
                    <InfoHeader>
                        <TbFileInvoice size={24} color='var(--red)'  style={{ strokeWidth: 1.8 }} />
                        <text>Dados</text>
                    </InfoHeader>
                    <RowContent>
                        {client.dataNasc && (
                            <RowItem>
                                <h3>Data de nascimento</h3>
                                <text>{client.dataNasc}</text>
                            </RowItem>
                        )}
                        
                        {client.idade && (
                            <RowItem>
                                <h3>Idade</h3>
                                <text>{client.idade}</text>
                            </RowItem>
                        )}
                        
                        {client.cpf && (
                            <RowItem>
                                <h3>CPF</h3>
                                <text>{client.cpf}</text>
                            </RowItem>
                        )}
                        
                        {client.nomeMae && (
                            <RowItem>
                                <h3>Nome da mãe</h3>
                                <text>{client.nomeMae}</text>
                            </RowItem>
                        )}
                    </RowContent>
                </DetailsContainer>

                <Divider />

                <DetailsContainer>
                    <InfoHeader>
                        <FiMapPin size={22} color='var(--red)' />
                        <text>Endereço</text>
                    </InfoHeader>
                    <text>{address.formatEnd(client)}</text>
                </DetailsContainer>

                <Divider />

                <DetailsContainer>
                    <InfoHeader>
                        <TbPhoneCall size={22} color='var(--red)' />
                        <text>Contato</text>
                    </InfoHeader>
                    <RowContent>
                        {client.telPrincipal && client.telPrincipal != 'N/D' && (
                            <RowItem>
                                <h3>Telefone principal</h3>
                                <text>{client.telPrincipal}</text>
                            </RowItem>
                        )}
                        
                        {client.telSecundario && client.telSecundario != 'N/D'  && (
                            <RowItem>
                                <h3>Telefone secundário</h3>
                                <text>{client.telSecundario}</text>
                            </RowItem>
                        )}
                        
                        {client.celular && client.celular != 'N/D'  && (
                            <RowItem>
                                <h3>Celular</h3>
                                <text>{client.celular}</text>
                            </RowItem>
                        )}

                        {client.tel0 && client.tel0 != 'N/D'  && (
                            <RowItem>
                                <h3>Telefone 0</h3>
                                <text>{client.tel0}</text>
                            </RowItem>
                        )}

                        {client.tel1 && client.tel1 != 'N/D'  && (
                            <RowItem>
                                <h3>Telefone 1</h3>
                                <text>{client.tel1}</text>
                            </RowItem>
                        )}

                        {client.tel2 && client.tel2 != 'N/D'  && (
                            <RowItem>
                                <h3>Telefone 2</h3>
                                <text>{client.tel2}</text>
                            </RowItem>
                        )}
                        
                        {client.celular0 && client.celular0 != 'N/D'  && (
                            <RowItem>
                                <h3>Celular 0</h3>
                                <text>{client.celular0}</text>
                            </RowItem>
                        )}

                        {client.celular1 && client.celular1 != 'N/D'  && (
                            <RowItem>
                                <h3>Celular 1</h3>
                                <text>{client.celular1}</text>
                            </RowItem>
                        )}

                        {client.celular2 && client.celular2 != 'N/D'  && (
                            <RowItem>
                                <h3>Celular 2</h3>
                                <text>{client.celular2}</text>
                            </RowItem>
                        )}
                    </RowContent>
                </DetailsContainer>

                <Divider />

                <DetailsContainer>
                    <InfoHeader>
                        <TbHammer size={22} color='var(--red)' style={{transform: 'rotate(-90deg)' }} />
                        <text>Processos arquivados</text>
                    </InfoHeader>
                    <text>{client.processos}</text>
                </DetailsContainer>

                <Divider />

                <DetailsContainer>
                    <InfoHeader>
                        <TbFileSearch size={22} color='var(--red)' />
                        <text>Análise</text>
                    </InfoHeader>
                    {client.analise && client.analise.length > 0 &&
                        client.analise.split('<br/>').map((item, index) => (
                            <AnaliseText key={index}>{item}</AnaliseText>
                        ))
                    }
                </DetailsContainer>

                <Divider />

                <DetailsContainer>
                    <InfoHeader>
                        <TbBriefcase size={22} color='var(--red)' />
                        <text>Atendimentos</text>
                    </InfoHeader>
                    <ReportsContent>
                        {clientReports && clientReports.map((item, index) => (
                            <ReportItem index={index}>
                                <ReportHeader>
                                    <div className='left'>
                                        <text>{`${datetime.getFullDate(item.time)} - `}<span>{item.operatorName}</span></text>
                                        <ResultText>{item.result}</ResultText>
                                    </div>
                                    
                                    {expanded == index
                                        ? <IoIosArrowUp cursor='pointer' onClick={() => setExpanded(null)} />
                                        : <IoIosArrowDown cursor='pointer' onClick={() => setExpanded(index)} />
                                    }
                                </ReportHeader>
                                {expanded == index && (
                                    <ReportDescription>
                                        <text>{item.description}</text>
                                    </ReportDescription>
                                )}
                            </ReportItem>
                        ))}
                    </ReportsContent>
                </DetailsContainer>
            </Content>
        </Container>
    )
}

export default ModalDetailsOperator;