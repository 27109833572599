import styled, {css} from "styled-components";

const dragActive = css`
    border-color: #78e5d5;
`;

const dragReject = css`
    border-color: #e57876;
`;

export const Form = styled.form`
    width: 100%;
`;

export const InputContainer = styled.div`
    width: 100%;
    height: 292px;

    margin-top: 20px;

    background: rgba(236, 242, 251, 0.4);
    border: 1px dashed #808B9F;
    border-radius: 20px;

    div {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 292px;
        
        width: 40%;
        height: 292px;
        gap: 20px;

        align-items: center;
        justify-content: center;
    }

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        /* 1 */

        color: #4A5E83;

    }
`;

export const Input = styled.input`
    /* width: 100%;
    max-width: 600px;
    height: 200px;
    margin-top: 20px; */
    /* position: relative; 
    width: ...px; 
    height: ...px; overflow: hidden;  */
    width: 100%;
    height: 292px;
    position: relative; 
    right: 0; 
    font-size: 14px; 
    opacity: 0; 
    margin: 0; 
    padding: 0; 
    border: none; 
`;

export const FieldText = styled.text`
    height: 24px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 24px;

    margin-bottom: 5px;

    color: #4A5E83;
`;

export const DropContainer = styled.div.attrs({
    className: 'dropzone'
})`
    border: 1px dashed #ddd;
    border-radius: 4px;
    cursor: pointer;

    transition: height 0.2s ease;

    ${props => props.isDragActive && dragActive}
    ${props => props.isDragReject && dragReject}
`;

const messageColors = {
    default: '#999',
    error: '#e57876',
    success: '#78e5d5'
}

export const UploadMessage = styled.p`
    display: flex;
    color: ${props => messageColors[props.type || 'default']};
    justify-content: center;
    align-items: center;
    padding: 15px 0;
`;

export const UploadButton = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 13px;
    margin-top: 20px;

    button {
        font-size: 15px;
        border: none;
        border-radius: 10px;
        color: #fff;

        line-height: 1.2;

        text-transform: uppercase;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 50px;

        background: #9a4749;
    }

    &:hover .button-import {
        cursor: pointer;
        background: #ca787a;
        color: #fff;
        border: 1px solid #adadad;
    }
`;

export const LoadingContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 13px;
    margin-top: 20px;
`;