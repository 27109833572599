import React, { useEffect, useState, useContext, useRef } from 'react';

import {
    Container,
    Table,
    TableTitleContainer,
    UploadButton,
    DownloadButton,
    Header,
    UploadContent,
    UploadSide,
    ModelSide,
    TableColumns,
    TableList,
    TableItem,
    ActionsContent,
    CancelButton,
    ConfirmButton,
    InputContainer,
    Input
} from './styles';

//Firebase and context
import { DatabaseContext } from '../../contexts/DatabaseContext';
import { doc, writeBatch } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import db from '../../firebase/config';

//API
import Geocode from "react-geocode";

//Custom components
import ProfileButton from '../../components/ProfileButton';
import CustomLoadingPage from '../../components/CustomLoadingPage';

//Icons
import importIcon from '../../assets/importIcon.svg'
import { FiDownload } from 'react-icons/fi';
import cpfIcon from '../../assets/cpfIcon.svg'

//Utils
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import { alertErrorStyle } from '../../resources/alertErrorStyle'
import { alertSuccessStyle } from '../../resources/alertSuccessStyle'
import { datetime } from '../../helpers/datetime';
import { addMapLogs } from '../../firebase/logs/maps/addMapLogs';
import { importCsvHelper } from '../../helpers/importCsvHelper';
import { arrayHelper } from '../../helpers/arrayHelper';
import { AuthContext } from '../../contexts/AuthContext';
import { MapsContext } from '../../contexts/MapsContext';
import CustomLoading from '../../components/CustomLoading';

const dateNow = new Date();

const RemovePage = () => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    Geocode.setLanguage('pt-BR');

    const { userData } = useContext(AuthContext);

    const { clientsDb, getClientsDb } = useContext(DatabaseContext);
    const { maps } = useContext(MapsContext);

    const inputRef = useRef();

    const [loadingData, setLoadingData] = useState(true);
    const [arrayTable, setArrayTable] = useState([]);
    const [showTable, setShowTable] = useState(false);
    const [loading, setLoading] = useState(false);
    const [removeData, setRemoveData] = useState('');
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        (async () => {
            if (clientsDb) {
                setLoadingData(false);
            } else {
                await getClientsDb();
            }
        })();
    }, [clientsDb])

    const clearOperation = () => {
        setInputValue('');
        setArrayTable([]);
        setShowTable(false);
        setRemoveData('')
    }

    const importCsvFile = (csvFile) => {
        const reader = new FileReader();

        setLoading(true)

        reader.onload = (e) => {
            const text = e.target.result;

            const splitFile = text.split('\r\n');
            const [header, ...files] = splitFile;

            const headerSplit = header.split(';');

            const { valid, invalidFields } = importCsvHelper.validateRemoveHeader(headerSplit);

            if (!valid) {
                return toast(`A planilha selecionada não possui as colunas obrigatórias: ${arrayHelper.arrayToString(invalidFields, ', ')
                    }`, alertErrorStyle)
            }

            const { array, errors } = importCsvHelper.processRemoveCsv(files, headerSplit)

            console.log("ARRAY: ", array)
            console.log("errors: ", errors)

            if (errors.length == 0) {
                // this.props.setData(selectedOption, array);
                setRemoveData(array)
                setLoading(false)
            } else {
                for (const errorMessage of errors) {
                    toast(errorMessage, alertErrorStyle)
                }
            }

        }

        reader.readAsText(csvFile);
    }

    const removeClientsFromMap = async () => {
        const batchArray = [];
        batchArray.push(writeBatch(db));
        var operationCounter = 0;
        var batchIndex = 0;
        const arrayNoExists = []

        var mapsClientsQtd = [];

        setLoading(true);

        for (const client of removeData) {
            const result = clientsDb.find(x => x.cpf === client.cpf);

            if(!result){
                arrayNoExists.push(client)
            }else{
                for(const map of maps){
                    for(const city of map.cities){
                        if(city.value == result.cidadeMap){
                            result['mapId'] = map.docId
                            const index = mapsClientsQtd.findIndex(x => x.mapId === map.docId);

                            if(index == -1){
                                mapsClientsQtd.push({
                                    mapId: map.docId,
                                    mapName: map.mapName,
                                    qtd: 1
                                })
                            }else{
                                const array1 = mapsClientsQtd.slice(0, index);
                                const array2 = mapsClientsQtd.slice(index+1, mapsClientsQtd.length)

                                array1.push({
                                    mapId: map.docId,
                                    mapName: map.mapName,
                                    qtd: mapsClientsQtd[index].qtd + 1
                                })
                                mapsClientsQtd = array1.concat(array2);
                            }
                        }
                    }
                }
                
                const newData = {
                    selected: 'foraDoMapa',
                    deleteType: 'admin',
                    deleteTime: dateNow.getTime()
                }
                const docRef = doc(db, "clientes", client.cpf);

                batchArray[batchIndex].update(docRef, newData);

                const docRefLog = doc(db, "clientesLogs", client.cpf);

                const stringDate = datetime.getFullDate(dateNow.getTime());

                const logMessage = `${stringDate} -> ${client.nome} foi removido do mapa pelo admin ${userData.userName}`

                batchArray[batchIndex].update(docRefLog, {
                    logs: [logMessage]
                });

                operationCounter++;

                if (operationCounter >= 248) {
                    batchArray.push(writeBatch(db));
                    batchIndex++;
                    operationCounter = 0;
                }
            }
        }

        for (const batch of batchArray) {
            batch.commit();
        }

        clearOperation()

        const removedClients = removeData.length - arrayNoExists.length

        if (arrayNoExists.length != 0) {
            setArrayTable(arrayNoExists)
            setShowTable(true)
            toast(`${arrayNoExists.length} clientes não removidos, pois não estão no mapa.`, alertErrorStyle)
        }

        if(removedClients > 0){
            for (const item of mapsClientsQtd) {
                const logMessageMap = `${item.mapName} ${item.qtd} clientes foram excluídos do mapa pelo admin ${userData.userName}`
    
                await addMapLogs(item.mapId, logMessageMap)
            }
            toast(`${removedClients} clientes foram excluídos do mapa com sucesso`, alertSuccessStyle)
        }
        // this.fileInput.value = "";
    }

    const downloadModelos = async (name) => {
        const storage = getStorage();
        const pathReference = ref(storage, `gs://central-juridica.appspot.com/${name}.csv`);

        getDownloadURL(pathReference)
        .then((url) => {
            // `url` is the download URL for 'images/stars.jpg'
            window.open(url, '_blank', 'noopener,noreferrer');
        })
        .catch((error) => {
            alert('Ocorreu um erro');
        });
    }

    if (loadingData) {
        return <Container>
            <CustomLoadingPage />
        </Container>
    }

    return (
        <Container>
            <Header>
                <h1>Remover clientes do mapa</h1>
                <ProfileButton arrowColor='var(grey2)' />
            </Header>

            <UploadContent>
                {!showTable
                    ? <>
                        <UploadSide>
                            <h3>Remoção de clientes do mapa</h3>

                            <InputContainer>
                                <div>
                                    <img src={importIcon} alt='img img' />
                                    <h3>{removeData ? 'Arquivo selecionado' : 'Escolher arquivo...'}</h3>
                                </div>

                                <Input
                                    type='file'
                                    accept='.csv'
                                    id='csvfile'
                                    ref={inputRef}
                                    placeholder=''
                                    value={inputValue}
                                    onChange={async (e) => {
                                        setInputValue(e.target.value)
                                        importCsvFile(e.target.files[0])
                                    }}
                                />

                                
                            </InputContainer>
                            {removeData && (
                                <h4>{removeData.length} clientes selecionados</h4>
                            )}
                            {removeData && (
                                <ActionsContent>
                                    <CancelButton onClick={() => clearOperation()}>
                                        <text>Cancelar remoção</text>
                                    </CancelButton>
                                    {loading
                                        ? <CustomLoading />
                                        : <ConfirmButton onClick={(e) => {
                                            e.preventDefault();
                                            removeClientsFromMap();
                                        }}>
                                            <text>Remover do mapa</text>
                                        </ConfirmButton>
                                    }
                                </ActionsContent>
                            )}
                        </UploadSide>
                        <ModelSide>
                            <h3>Baixar modelo</h3>

                            <DownloadButton onClick={async () => downloadModelos('remocao')}>
                                <FiDownload />
                                Modelo Remoção do Mapa
                            </DownloadButton>
                        </ModelSide>
                    </>
                    : <Table>
                        <TableTitleContainer>
                            Os clientes a seguir não foram removidos do mapa, pois não estão nele:
                        </TableTitleContainer>

                        <TableColumns>
                            <h3>CPF</h3>
                            <h3>Nome</h3>
                            <h3>Processos</h3>
                        </TableColumns>
                        <TableList>
                            {arrayTable.map((item, index) => (
                                <TableItem key={index}>
                                    <div>
                                        <img src={cpfIcon} alt='img img' />
                                        <h3>{item.cpf}</h3>
                                    </div>
                                    <h1>{item.nome}</h1>
                                    <h1>{item.processos}</h1>
                                </TableItem>
                            ))}
                        </TableList>
                        <ActionsContent>
                            <CancelButton onClick={() => clearOperation()}>
                                <text>Voltar</text>
                            </CancelButton>
                        </ActionsContent>
                    </Table>
                }

            </UploadContent>
        </Container>

    )
};

export default RemovePage;