import { doc, setDoc, updateDoc, arrayUnion } from "firebase/firestore";
import db from '../../config';
import { datetime } from "../../../helpers/datetime";

export async function addRouteLogs(routeDocId, data){

    const dateNow = new Date();
    
    const routeRef = doc(db, "rotasLogs", routeDocId);

    const stringDate = datetime.getFullDate(dateNow.getTime());

    const log = `${stringDate} -> ${data}`

    await updateDoc(routeRef, {
        logs: arrayUnion(log)
    }).then(() => {
        
    }).catch(async (error) => {
        if(error.code == 'not-found'){
            await setDoc(routeRef, {
                logs: arrayUnion(log)
            })
        }else{
            await setDoc(routeRef, {
                logs: arrayUnion(`Erro ao salvar log: ${error.code}`)
            })
        }
    })
}