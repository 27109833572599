import { doc, updateDoc } from "firebase/firestore";
import db from '../config';
import { toast } from "react-toastify";
import { alertSuccessStyle } from "../../resources/alertSuccessStyle";
import { alertErrorStyle } from "../../resources/alertErrorStyle";
import { addClientLogs } from "../logs/clients/addClientLogs";

export const specialIcon = {
    enableSpecialIcon,
    disableSpecialIcon
}

async function enableSpecialIcon(clientCpf, clientName){
    const clientRef = doc(db, "clientes", clientCpf);

    await updateDoc(clientRef, {
        specialIcon: true,
    }).then(() => {
        const logMessage = `${clientName} recebeu um ícone especial`

        addClientLogs(clientCpf, logMessage)
        
        toast(`Ícone especial definido para o cliente ${clientName}`, alertSuccessStyle)
    }).catch(async (error) => {
        toast(`Erro ao definir ícone especial para o cliente ${clientName}: ${error.code}`, alertErrorStyle)
    })
}

async function disableSpecialIcon(clientCpf, clientName){
    const clientRef = doc(db, "clientes", clientCpf);

    await updateDoc(clientRef, {
        specialIcon: false,
    }).then(() => {
        const logMessage = `${clientName} teve seu ícone especial removido`

        addClientLogs(clientCpf, logMessage)

        toast(`Ícone especial removido do cliente ${clientName}`, alertSuccessStyle)
    }).catch(async (error) => {
        toast(`Erro ao remover ícone especial do cliente ${clientName}: ${error.code}`, alertErrorStyle)
    })
}