import React, { useContext, useEffect, useState } from "react";

import {
    Container, 
    Header,
    LogsContainer,
    LogItem,
    Circle,
    Line,
    LogsList,
} from './styles';

import { CancelButton, SearchBar, SearchButton, SearchContainer, ClearSearch, TableFooter } from '../../components/CustomTable/styles';

//Firebase and context
import { AuthContext } from '../../contexts/AuthContext';

//Icons
import { FiSearch } from 'react-icons/fi';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

//Custom Components
import ProfileButton from "../../components/ProfileButton";
import CustomLoadingPage from "../../components/CustomLoadingPage";
import CustomFilterLogs from "../../components/CustomFilterLogs";

//MUI
import Menu from '@mui/material/Menu';
import { getSystemLogs } from "../../firebase/logs/system/getSystemLogs";
import { searchHelper } from "../../helpers/searchHelper";

const perPage = 40;

const SystemLogsPage = () => {

    const { user } = useContext(AuthContext)
    const [ loadingData, setLoadingData ] = useState(true);

    const [ systemLogs, setSystemLogs ] = useState('');
    const [ logs, setLogs ] = useState('');
    const [ logsBackup, setLogsBackup ] = useState('');

    const [ rangeOfList, setRangeOfList ] = useState({ init: 0, finish: perPage })

    const [ search, setSearch ] = useState('');
    const [ searchBar, setSearchBar ] = useState(null);
    const openSearch = Boolean(searchBar);

    useEffect(() => {
        (async () => {
            const logs = await getSystemLogs(user);

            setSystemLogs(logs);
        })();
    }, [])

    const ordenateLogs = (logs) => {
        const ordenatedArray = logs.sort(function (a, b) {
            if (a > b) {
                return -1;
            }
            if (b > a) {
                return 1;
            } 
            return 0;
        });

        return ordenatedArray
    }
    
    useEffect(() => {
        (async () => {
            if(systemLogs){

                var arrayLogs = [];

                for(const log of systemLogs){
                    if(log.logs){
                        
                        arrayLogs = arrayLogs.concat(ordenateLogs(log.logs))
                    }
                }

                setLogs(arrayLogs)
                setLogsBackup(systemLogs)
                setLoadingData(false);
            }
        })();
    }, [systemLogs]);

    useEffect(() => {
        setRangeOfList({ init: 0, finish: perPage })
    }, [logs])

    useEffect(() => {
        if(!search){
            clearSearch()
        }
    }, [search])

    const onFilterData = (arrayValues) => {
        var arrayLogs = [];

        for(const log of arrayValues){
            arrayLogs = arrayLogs.concat(ordenateLogs(log.logs))
        }
        setLogs(arrayLogs)
    }

    const clearSearch = () => {
        var arrayLogs = [];

        for(const log of systemLogs){
            arrayLogs = arrayLogs.concat(ordenateLogs(log.logs))
        }
        setLogs(arrayLogs)
        setSearch('');
    }

    const searchTable = () => {
        if(search){
            const arrayLogs = [];

            for(const log of logs){
                if(searchHelper.searchText(log, search)){
                    arrayLogs.push(log)
                }
            }
            setLogs(arrayLogs)
            setSearchBar(null);
        }
    }

    if(loadingData){
        return <Container>
            <CustomLoadingPage />
        </Container>
    }

    return (
        <Container>
            <Header>
                <div>
                    <h1>Log do Sistema</h1>
                    <FiSearch 
                        onClick={(event) => {
                            setSearchBar(event.currentTarget);
                        }}
                        size={20} 
                        cursor='pointer' 
                        color='var(--grey2)' 
                    />
                    <ClearSearch>
                        {search && (
                            <>
                                {search}
                                <AiOutlineCloseCircle cursor='pointer' onClick={() => {
                                    clearSearch()
                                }} />
                            </>
                        )}
                    </ClearSearch>
                </div>

                <div>
                    <CustomFilterLogs
                        data={systemLogs}
                        filterOptions={[ 
                            { label: 'Entrou no sistema', value: 'acessou' },
                            { label: 'Visualizou as informações', value: 'visualizou' },
                            { label: 'Atendimentos', value: 'atendeu' },
                            { label: 'Criou uma rota', value: 'criou' },
                            { label: 'Erros', value: 'erro' },
                        ]}
                        onFilter={(arrayValues) => {
                            onFilterData(arrayValues)
                        }}
                        dateField='time'
                        filterType='atendimentos'
                        filterTypes={[
                            { headerName: 'Ação', field: 'text' },
                            { headerName: 'Data', field: 'time' },
                            { headerName: 'Operador', field: 'operator' }
                        ]}
                        tableRowsBackup={logsBackup}
                        clearFilters={() => {
                            var arrayLogs = [];

                            for(const log of systemLogs){
                                arrayLogs = arrayLogs.concat(ordenateLogs(log.logs))
                            }
                            setLogs(arrayLogs);
                        }}
                       
                    />
                    <ProfileButton arrowColor='var(grey2)' />
                </div>
            </Header>
            
            <LogsContainer>
                <LogsList>
                    {logs && logs.slice(rangeOfList.init, rangeOfList.finish).map((item, index) => (
                        <LogItem key={index}>
                            <div>
                                <Circle />
                                <Line />
                            </div>
                            <h3>{item}</h3>
                        </LogItem>
                    ))}
                </LogsList>
                <TableFooter>
                    <h3>{rangeOfList.init} - {rangeOfList.finish}</h3>
                    <div>
                        <MdKeyboardArrowLeft 
                            size={20}
                            cursor='pointer'
                            onClick={() => {
                                if(rangeOfList.finish == logs.length){
                                    const value = (rangeOfList.finish - rangeOfList.init) + perPage;

                                    const newFinish = rangeOfList.init;

                                    setRangeOfList({ init: rangeOfList.finish - value, finish: newFinish})
                                }else{
                                    if((rangeOfList.init - perPage) >= 0){
                                        setRangeOfList({ init: rangeOfList.init - perPage, finish: rangeOfList.finish-perPage})
                                    }
                                }
                            }}
                        />
                        <MdKeyboardArrowRight 
                            size={20}
                            cursor='pointer'
                            onClick={() => {
                                if(logs.length > perPage){
                                    if((rangeOfList.finish + perPage) <= logs.length){
                                        setRangeOfList({ init: rangeOfList.finish, finish: rangeOfList.finish+perPage})
                                    }else{
                                        if(rangeOfList.finish != logs.length){
                                            const value = rangeOfList.finish+(logs.length - rangeOfList.finish);
        
                                            setRangeOfList({ init: rangeOfList.finish, finish: value})
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                    <h3>{logs ? logs.length : 0} clientes</h3>
                </TableFooter> 
            </LogsContainer>

            <Menu
                id="basic-menu"
                anchorEl={searchBar}
                open={openSearch}
                onClose={() => {
                    // setSearch('');
                    setSearchBar(null);
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                PaperProps={{
                    style: {
                        width: 600,
                        height: 40,
                        maxHeight: 40,
                        borderRadius: 20,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        overflow: 'hidden',
                    },
                }}
            >
                <SearchContainer onSubmit={(e) => { 
                    e.preventDefault();
                    searchTable()
                }}>
                    <FiSearch 
                        size={18} 
                        cursor='pointer' 
                        color='#626F87'
                        strokeWidth='3'
                    />
                     <text>&#8288;</text>
                    <SearchBar>
                        <input 
                            name="search" 
                            onChange={event => setSearch(event.target.value)}
                            value={search}
                            placeholder='Buscar...'
                            autoFocus={true}
                        />
                    </SearchBar>
                    <CancelButton onClick={() => {
                        setSearchBar(null);
                    }}>
                        Cancelar
                    </CancelButton>
                    <SearchButton onClick={() => searchTable()}>
                        Buscar
                    </SearchButton>
                </SearchContainer>
            </Menu>
        </Container>
    )
}

export default SystemLogsPage;