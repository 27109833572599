import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 256px;

    padding: 20px 34px;

    overflow-y: auto;
    height: 200vh;

    gap: 20px;

    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    
    width: calc(100% - 256px);


    background: var(--background);
    /* z-index: 5000; */
`;

export const Header = styled.div`
    width: 100%;
    height: 67px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 20px;

    div {
        display: flex;
        
        align-items: center;

        gap: 20px;
    }

    h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 38px;
        /* identical to box height */


        /* 1 */

        color: #4A5E83;
    }
`;

export const CustomCard = styled.div`
    box-sizing: border-box;

    /* width: 250.86px; */
    /* height: 142.38px; */
    /* margin-top: 97px; */

    display: flex;
    flex-direction: column;

    /* align-items: center; */
    justify-content: center;

    background: #FFFFFF;

    cursor: pointer;
    user-select: none;

    box-shadow: 0px 4px 20px rgba(35, 54, 63, 0.05);
    border-radius: 20px;
`;

export const Frame = styled.div`
    width: 22px;
    height: 19px;
`;

export const CardTitle = styled.div`
    height: 56px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    

    h1{   
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* or 171% */


        /* 2 */

        color: #808B9F;
    }

    .icon{
        color: ${props => props.type == 'contratos' ? '#68CFC9' : '#DE6161' };
        fill:  ${props => props.type == 'contratos' ? '#68CFC9' : '#DE6161' };
    }
`;

export const CardDivider = styled.div`
    border: 1px solid #ECF2FB;
    margin: 0 20px;
`;

export const CardContent = styled.div`
    height: 97px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;

    .value{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 24px;
        /* or 60% */


        /* 83DBD6 */

        color: ${props => props.type == 'contratos' ? '#68CFC9' : '#4A5E83' };
    }

    .description{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */


        /* 83DBD6 */

        color: ${props => props.type == 'contratos' ? '#68CFC9' : '#4A5E83' };
    }

    .operatorName{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* or 171% */


        /* 2 */

        color: #808B9F;
    }
`;

export const CardsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 20px;
    /* height: 400px; */
    /* max-height: 200px; */
    margin: 20px 0px;
`;

export const ChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    margin-top: 10px;

    padding: 20px;

    background: #FFFFFF;
    /* shadowcard */

    box-shadow: 0px 4px 20px rgba(35, 54, 63, 0.05);
    border-radius: 20px;
`;

export const ChartHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    gap: 15px;
    /* margin-bottom: 20px; */

    div {
        display: flex;

        align-items: center;

        gap: 15px;
    }

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        color: #808B9F;
    }
`;

export const RangeSelectContainer = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: right;

    gap: 5px;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        margin-right: 5px;
    
        color: #808B9F;
    }
`;

export const RangeSelectButton = styled.div`
    display: flex;
    align-items: center;
    padding: 7px 18px;

    cursor: pointer;
    user-select: none;

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        
        color: ${props => props.selected ? 'var(--white)' : 'var(--grey2)'};
    }
    /* 3 */

    background: ${props => props.selected ? 'var(--red)' : 'var(--white)'};
    
    border: ${props => props.selected ? 'none' : '1px solid #B4C1D5'};
    border-radius: 100px;

`;

export const ChartLinesContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 10px;

    gap: 10px;
`;

export const ChartLineFilter = styled.div`
    display: flex;
    
    align-items: center;
    gap: 10px;

    padding: 5px 0;
    color: #808B9F;

    cursor: pointer;
    user-select: none;
    
    h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        
        /* 2 */
        border-bottom: ${props => props.selected ? `2px solid ${props.color}` : 'none'};
        color: #808B9F;
    }
`;

export const ChartLineItem = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 7px 18px;

    /* width: 180px; */
    height: 38px;
    cursor: pointer;

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        margin-right: 5px;
    
        color: #808B9F;
    }
    /* 3 */
    
    border: 1px solid #B4C1D5;
    border-radius: 100px;
`;

export const AddLineButton = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    gap: 10px;

    cursor: pointer;
    user-select: none;

    margin-left: 20px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */


    /* 2 */

    color: #808B9F;
`;

export const InforCardRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
`;

export const PercentContainer = styled.div`
    width: 80%;
`;

export const FirstChartHorizontal = styled.div`
    height: 600px;
    width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ChartsContainer = styled.div`
    border-radius: 20px;
    /* padding: 20px; */
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
`;

export const FilteredChartContainer = styled.div`
    padding: 20px;
    border-radius: 20px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    background: #fff;
`;

export const ChartVerticalContainer = styled.div`
    padding: 5px;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;
`;

export const LegendContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;

    h2 {
        text-align: center;
        margin-bottom: 10px;
    }

    div {
        align-self: center;
        width: 80%;
    }
`;

export const LegendContent = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr;
    margin-top: 10px;
    
    h5 {
        align-self: center;
    }
`;