import React, { useEffect, useState, useContext } from 'react';

import { 
    Container, 
    Table, 
    TableTitleContainer, 
    UploadButton,
    DownloadButton,
    Header,
    UploadContent,
    UploadSide,
    ModelSide,
    TableColumns,
    TableList,
    TableItem,
    ActionsContent,
    CancelButton,
    ConfirmButton,
    ModelSideColumn
} from './styles';

//Firebase and context
import { AuthContext } from '../../contexts/AuthContext';
import { DatabaseContext } from '../../contexts/DatabaseContext';
import { ReportsContext } from '../../contexts/ReportsContext';
import { doc, writeBatch, onSnapshot, collection, query, limit, arrayUnion } from "firebase/firestore";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import db from '../../firebase/config';

//API
import Geocode from "react-geocode";

//Custom components
import Upload from '../../components/Upload';
import ProfileButton from '../../components/ProfileButton';
import CustomLoadingPage from '../../components/CustomLoadingPage';
import ModalHeader from '../../components/ModalHeader';

//Icons
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import { FiDownload } from 'react-icons/fi';
import cpfIcon from '../../assets/cpfIcon.svg'

//Utils
import Modal from 'react-modal';
import DotLoader from "react-spinners/ClipLoader";
import { toast } from 'react-toastify';
import { alertErrorStyle } from '../../resources/alertErrorStyle'
import { alertSuccessStyle } from '../../resources/alertSuccessStyle'
import { datetime } from '../../helpers/datetime';
import { MapsContext } from '../../contexts/MapsContext';
import { addMapLogs } from '../../firebase/logs/maps/addMapLogs';
import ModalConfirmImport from './components/ModalConfirmImport';
import { AiOutlineEye } from 'react-icons/ai';
import ModalDetailsAdmin from '../../components/ModalDetailsAdmin';
import { requiredImportHeader } from '../../constants/requiredImportHeader';

Modal.setAppElement('#root');

const dateNow = new Date();

const UploadPage = () => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    Geocode.setLanguage('pt-BR');

    const { userData } = useContext(AuthContext);
    const { clientsDb, getClientsDb } = useContext(DatabaseContext);
    const { reports, getReportsAdmin } = useContext(ReportsContext);
    const { maps } = useContext(MapsContext);

    const [ loadingData, setLoadingData ] = useState(true);
    const [ arrayTable, setArrayTable ] = useState([]);
    const [ showTable, setShowTable ] = useState(false);
    const [ selectAll, setSelectAll ] = useState(false);
    const [ auxBrasil, setAuxBrasil ] = useState('');
    const [ dbClientes, setDbClientes ] = useState('');
    const [ deferidos, setDeferidos ] = useState('');
    const [ indeferidosDb, setIndeferidosDb ] = useState('');
    const [ cancel, setCancel ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ selectedOption, setSelectedOption ] = useState('');
    const [ importData, setImportData ]= useState([]);
    const [ modalVisible, setModalVisible ] = useState(false);
    const [ modalFullData, setModalFullData ] = useState(false);
    const [ currentClient, setCurrentClient ] = useState("")

    useEffect(() => {
        (async () => {
            if(reports && clientsDb && deferidos && indeferidosDb && auxBrasil){
                setLoadingData(false);
                setDbClientes(clientsDb);
            }else{
                const getAuxBrasil = async () => {
                    await onSnapshot(collection(db, 'auxBrasil'), (querySnapshot) => {
                        const database = [];
                        const data = querySnapshot.docs;
            
                        for(const i in data){
                            database.push({...data[i].id});
                        }
            
                        setAuxBrasil(database);
                    });   
                }
        
                const getDeferidos = async () => {
                    await onSnapshot(collection(db, 'deferidos'), (querySnapshot) => {
                        const database = [];
                        const data = querySnapshot.docs;
            
                        for(const i in data){
                            database.push(data[i].id);
                        }
                        
                        setDeferidos(database);
                    });   
                }
        
                const getIndeferidosDb = async () => {
                    await onSnapshot(collection(db, 'indeferidosDb'), (querySnapshot) => {
                        const database = [];
                        const data = querySnapshot.docs;
            
                        for(const i in data){
                            database.push(data[i].id);
                        }
                        
                        setIndeferidosDb(database);
                    });   
                }

                if(!clientsDb){
                    await getClientsDb();
                }

                if(!auxBrasil){
                    await getAuxBrasil();
                }

                if(!indeferidosDb){
                    await getIndeferidosDb();
                }

                if(!deferidos){
                    await getDeferidos();
                }

                if(!reports){
                    getReportsAdmin()
                }
            }
        })();
    }, [reports, clientsDb, deferidos, indeferidosDb, auxBrasil])

    const openModal = () => {
        setModalVisible(true);
    }

    const closeModal = () => {
        setModalVisible(false);
    }

    const clearOperation = () => {
        setCancel(false)
        setArrayTable([]);
        setShowTable(false);  
        setSelectAll(false);   
        setImportData([]) 
    }

    const getLocation = async (address) => {

        try {
            const response = await Geocode.fromAddress(address.toLowerCase());
            const locations = response.results[0].geometry.location;
            return locations;
        }catch{
            return null
        }
        
    }

    const verifyExists = async (data) => {

        var obj;
        const array = [];

        for(const client of data){             
            const result = auxBrasil.find(x => x === client.cpf);
            const resultDeferidos = deferidos.find(x => x === client.cpf);
            const resultIndeferidosDb = indeferidosDb.find(x => x === client.cpf);
            const resultDbClientes = dbClientes.find(x => x.cpf === client.cpf);

            var clientData = {}
            

            if(result){
                clientData['auxBrasil'] = 'Sim';
            }

            if(resultDeferidos){
                clientData['deferidos'] = 'Sim';
            }

            if(resultIndeferidosDb){
                clientData['indeferidos'] = 'Sim';
            }
            
            if(resultDbClientes){

                const report = reports.find(x => x.docId === resultDbClientes.reportId);

                var formatedReport;

                if(report){

                    const date = new Date(report.time);
        
                    const reportDate = `${date.getDate()}/${(date.getMonth()+1)}/${date.getFullYear()}`;

                    formatedReport = `Atendido. ${reportDate} por ${report.operatorName} / Resultado: ${report.result} / Descrição:: ${report.description}`;
                }else{
                    formatedReport = "Atendido.";
                }
                
                clientData['indeferidosMapa'] = resultDbClientes.selected === 'done' ? `${formatedReport}` : "Não atendido";
                clientData['time'] =  resultDbClientes.time;
            }
            
            if(Object.values(clientData).length > 0){
                array.push({...clientData,
                    auxBrasil: clientData['auxBrasil'] ? 'Sim' : 'Não',
                    deferidos: clientData['deferidos'] ? 'Sim' : 'Não',
                    indeferidos: clientData['indeferidos'] ? 'Sim' : 'Não',
                    cpf: client.cpf,
                    nome: client.nome,
                    status: false,
                })
            }
        }
        
        if(array.length > 0){
            setArrayTable(array);
            return setShowTable(true);
        }
    }

    const uploadClients = async () => {
        const batchArray = [];
        batchArray.push(writeBatch(db));
        var operationCounter = 0;
        var batchIndex = 0;
        const arrayError = []

        var mapsClientsQtd = [];

        const arrayExceptions = [];

        setLoading(true);

        for(const item of arrayTable){
            if(item.status === false){
                arrayExceptions.push(item);
            }
        }

        for(const client of importData){ 
            const result = arrayExceptions.find(x => x.cpf === client.cpf);

            if(!result){
                var data = {
                    ...client,
                    validEnd: 'S1',
                    time: dateNow.getTime()
                }

                console.log("MAPS: ", maps)

                for(const map of maps){
                    for(const city of map.cities){
                        if(city.value == data.cidadeMap){
                            data['mapId'] = map.docId
                            const index = mapsClientsQtd.findIndex(x => x.mapId === map.docId);

                            if(index == -1){
                                mapsClientsQtd.push({
                                    mapId: map.docId,
                                    mapName: map.mapName,
                                    qtd: 1
                                })
                            }else{
                                const array1 = mapsClientsQtd.slice(0, index);
                                const array2 = mapsClientsQtd.slice(index+1, mapsClientsQtd.length)

                                array1.push({
                                    mapId: map.docId,
                                    mapName: map.mapName,
                                    qtd: mapsClientsQtd[index].qtd + 1
                                })
                                mapsClientsQtd = array1.concat(array2);
                            }
                        }
                    }
                }

                const end = 'S1';

                const endToLocate = `${client[`logradouro${end}`]}, ${client[`numero${end}`]}, ${client[`cidade${end}`]}-${client[`estado${end}`]}`;

                if(client[`logradouro${end}`] != 'N/D' && client[`estado${end}`] != 'N/D' && client[`cidade${end}`] != 'N/D'){

                    if(client[`complemento${end}`] != 'N/D'){
                        data[`endAll${end}`] = `${client[`logradouro${end}`]}, ${client[`numero${end}`]}, ${
                            client[`bairro${end}`]}, ${client[`complemento${end}`] != "N/D" ? client[`complemento${end}`] : ""},${
                                client[`cidade${end}`]}-${client[`estado${end}`]} - ${client[`cep${end}`]}`;
                    }else{
                        data[`endAll${end}`] = `${client[`logradouro${end}`]}, ${client[`numero${end}`]}, ${
                            client[`bairro${end}`]}, ${
                                client[`cidade${end}`]} - ${client[`estado${end}`]} - ${client[`cep${end}`]}`;
                    }
                                
                    const locations = await getLocation(endToLocate);

                    if(locations){
                        data['lat'] = locations.lat;
                        data['lng'] = locations.lng;
                        data['endNotFound'] = false;
                    }else{
                        data['lat'] = null;
                        data['lng'] = null;
                        data['endNotFound'] = true;
                    }
                }else{
                    data[`endAll${end}`] = 'N/D';
                    data['lat'] = null;
                    data['lng'] = null;
                    data['endNotFound'] = true;
                }

                if(data.endNotFound === true){
                    arrayError.push(client);   
                }

                const docRef = doc(db, "clientes", data.cpf);
        
                batchArray[batchIndex].set(docRef, data);


                const docRefLog = doc(db, "clientesLogs", data.cpf);

                const stringDate = datetime.getFullDate(dateNow.getTime());

                const logMessage = `${stringDate} -> ${data.nome} foi adicionado ao mapa pelo admin ${userData.userName}`
        
                batchArray[batchIndex].set(docRefLog, {
                    logs: [logMessage]
                });

                operationCounter++;
    
                if (operationCounter >= 248) {
                    batchArray.push(writeBatch(db));
                    batchIndex++;
                    operationCounter = 0;
                } 
            }
        }
        
        for(const batch of batchArray){
            batch.commit();
        }

        setLoading(false); 
        clearOperation()
        
        // setTableError(arrayError);

        if(arrayError.length != 0){    
            toast(`${arrayError.length} endereços não encontrados, corrija-os no mapa do admin.`, alertErrorStyle)
        }else{
            console.log("mapsClientsQtd: ", mapsClientsQtd)
            for(const item of mapsClientsQtd){
                const logMessageMap = `${item.mapName} ${item.qtd} clientes foram importados com sucesso pelo admin ${userData.userName}`

                await addMapLogs(item.mapId, logMessageMap)
            }
            toast('Arquivo importado com sucesso', alertSuccessStyle)
        }
        // this.fileInput.value = "";
    }

    const uploadAuxBrasil = async () => {
        setLoading(true);

        const batchArray = [];
        batchArray.push(writeBatch(db));
        var operationCounter = 0;
        var batchIndex = 0;

        for(const client of importData){

            const docRef = doc(db, "auxBrasil", client.cpf);

            batchArray[batchIndex].set(docRef, client);
            operationCounter++;

            if (operationCounter === 499) {
                batchArray.push(writeBatch(db));
                batchIndex++;
                operationCounter = 0;
            }
        }
        
        for(const batch of batchArray){
            batch.commit();
        }
        
        setLoading(false);
        alert('Arquivo importado com sucesso"');   
        // this.fileInput.value = "";
    }

    const uploadDeferidos = async () => {
        setLoading(true);

        const batchArray = [];
        batchArray.push(writeBatch(db));
        var operationCounter = 0;
        var batchIndex = 0;

        for(const client of importData){

            const docRef = doc(db, "deferidos", client.cpf);

            batchArray[batchIndex].set(docRef, client);
            operationCounter++;

            if (operationCounter === 499) {
                batchArray.push(writeBatch(db));
                batchIndex++;
                operationCounter = 0;
            }
        }
        
        for(const batch of batchArray){
            batch.commit();
        }
        
        setLoading(false);
        alert('Arquivo importado com sucesso"');
        // this.fileInput.value = "";
    }

    const uploadIndeferidosDb = async () => {
        setLoading(true);

        const batchArray = [];
        batchArray.push(writeBatch(db));
        var operationCounter = 0;
        var batchIndex = 0;

        for(const client of importData){

            const docRef = doc(db, "indeferidosDb", client.cpf);

            batchArray[batchIndex].set(docRef, client);
            operationCounter++;

            if (operationCounter === 499) {
                batchArray.push(writeBatch(db));
                batchIndex++;
                operationCounter = 0;
            }
        }
        
        for(const batch of batchArray){
            batch.commit();
        }
        
        setLoading(false);
        alert('Arquivo importado com sucesso"');
        // this.fileInput.value = "";
    }

    const removeOld = async () => {
        const arrayToRemove = [];

        var mapsClientsQtd = [];

        const intervalo = (86400*90) * 1000
        const timeNow = dateNow.getTime()

        for(const client of dbClientes){
            const clientDate = new Date(client.time)

            if(Number(client.time) < Number(timeNow - intervalo) && client.selected == ''){
                arrayToRemove.push(client);
            }
        }

        if(arrayToRemove.length > 0){

            const batchArray = [];
            batchArray.push(writeBatch(db));
            var operationCounter = 0;
            var batchIndex = 0;

            for(const client of arrayToRemove){

                const index = mapsClientsQtd.findIndex(x => x.mapId === client.mapId);
                const docMap = maps.find(x => x.docId === client.mapId)

                if(docMap?.mapName){
                    if(index == -1){
                        mapsClientsQtd.push({
                            mapId: client.mapId,
                            mapName: docMap.mapName,
                            qtd: 1
                        })
                    }else{
                        const array1 = mapsClientsQtd.slice(0, index);
                        const array2 = mapsClientsQtd.slice(index+1, mapsClientsQtd.length)
    
                        array1.push({
                            mapId: client.mapId,
                            mapName: docMap.mapName,
                            qtd: mapsClientsQtd[index].qtd + 1
                        })
                        mapsClientsQtd = array1.concat(array2);
                    }
                }

                

                const docRef = doc(db, "clientes", client.cpf);
    
                batchArray[batchIndex].update(docRef, {
                    selected: 'foraDoMapa',
                    deleteType: 'months',
                    deleteTime: dateNow.getTime()
                });

                const docRefLog = doc(db, "clientesLogs", client.cpf);

                const stringDate = datetime.getFullDate(dateNow.getTime());

                const logMessage = `${stringDate} -> ${client.nome} foi removido do mapa pelo admin (3 meses atrás)`
        
                batchArray[batchIndex].update(docRefLog, {
                    logs: arrayUnion(logMessage)
                });

                operationCounter++;
    
                if (operationCounter === 258) {
                    batchArray.push(writeBatch(db));
                    batchIndex++;
                    operationCounter = 0;
                }
            }
            
            for(const batch of batchArray){
                batch.commit();
            }
        }

        for(const item of mapsClientsQtd){
            const logMessageMap = `${item.mapName} ${item.qtd} clientes de 3 meses atrás retirados do mapa`

            await addMapLogs(item.mapId, logMessageMap)
        }

        alert(`${arrayToRemove.length} clientes de 3 meses atrás retirados do mapa`)
    }

    const setData = async (selectedOption, data) => {
        
        if(cancel === false && data.length != 0){
            switch(selectedOption){
                case 'indeferidos':
                    await verifyExists(data);
                    setSelectedOption(selectedOption);
                    setImportData(data);
                    openModal();
                    break;
                case 'indeferidosDb':
                    setSelectedOption(selectedOption);
                    setImportData(data);
                    break;
                case 'auxBrasil':
                    setSelectedOption(selectedOption);
                    setImportData(data);
                    break;
                case 'deferidos':
                    setSelectedOption(selectedOption);
                    setImportData(data);
                    break;
            }
            
        }else{
            alert("Impossível importar! planilha vazia.")
        }
    }

    const handleException = (item) => {

        const result = arrayTable.findIndex(x => x.cpf === item.cpf);

        const array1 = arrayTable.slice(0, result);
        const array2 = arrayTable.slice(result+1, arrayTable.length);

        array1.push({
            ...item,
            status: !item.status,
        })

        setArrayTable(array1.concat(array2))
    }

    const changeSelectAll = () => {
        const newArray = [];

        for(const i in arrayTable){
            newArray.push({
                ...arrayTable[i],
                status: !selectAll,
            })
        }
        setArrayTable(newArray);
        setSelectAll(!selectAll);
    }

    const downloadModelos = async (name) => {
        const storage = getStorage();
        const pathReference = ref(storage, `gs://central-juridica.appspot.com/${name}.csv`);

        getDownloadURL(pathReference)
        .then((url) => {
            // `url` is the download URL for 'images/stars.jpg'
            window.open(url, '_blank', 'noopener,noreferrer');
        })
        .catch((error) => {
            alert('Ocorreu um erro');
        });
    }

    const handleOpenClientModal = (client) => {
        const clientData = clientsDb.find(x => x.cpf == client.cpf);

        if(clientData){
            setCurrentClient(clientData)
            setModalFullData(true)
        }else{
            toast("Não foi possível acessar os dados do cliente.", alertErrorStyle);
        }        
    }

    if(loadingData){
        return <Container>
            <CustomLoadingPage />
        </Container>
    }      

    return (
        <Container>
            <Header>
                <h1>Adicionar clientes</h1>
                <ProfileButton arrowColor='var(grey2)' />
            </Header>

            <UploadContent>
                {!showTable
                    ? <>
                        <UploadSide>
                            <h3>Importação</h3>

                            <Upload 
                                optionChanged={() => clearOperation()}
                                setData={(selectedOption, data) => {
                                    setData(selectedOption, data)
                                }} 
                            />
                            {importData.length > 0 && (
                                <ActionsContent>
                                    <CancelButton onClick={() => clearOperation()}>
                                        <text>Cancelar importação</text>
                                    </CancelButton>
                                    {loading
                                        ?<DotLoader
                                            size={20}
                                            color="var(--red)"
                                            loading={loading}
                                            speedMultiplier={1.5}
                                        />
                                        : <ConfirmButton onClick={(e) => {
                                            e.preventDefault();
                                            if(selectedOption === 'indeferidos'){
                                                return uploadClients();
                                            }
                                            if(selectedOption === 'indeferidosDb'){
                                                return uploadIndeferidosDb();
                                            }
                                            if(selectedOption === 'auxBrasil'){
                                                return uploadAuxBrasil();
                                            }
                                            if(selectedOption === 'deferidos'){
                                                return uploadDeferidos();
                                            }
                                        }}>
                                            <text>Inserir no mapa</text>
                                        </ConfirmButton>
                                    }
                                </ActionsContent>
                            )}
                        </UploadSide>
                        <ModelSide>
                            <ModelSideColumn>
                                <h3>Baixar modelos</h3>

                                <DownloadButton onClick={async () => await downloadModelos('auxBrasil') }>
                                    <FiDownload />
                                    Modelo Aux Brasil
                                </DownloadButton>

                                <DownloadButton onClick={async () => await downloadModelos('deferidos') }>
                                    <FiDownload />
                                    Modelo Deferidos
                                </DownloadButton>

                                <DownloadButton onClick={async () => await downloadModelos('indeferidos') }>
                                    <FiDownload />
                                    Modelo Indeferidos completo
                                </DownloadButton>

                                <DownloadButton onClick={async () => await downloadModelos('indeferidos-obrigatorias') }>
                                    <FiDownload />
                                    Modelo Indeferidos obrigatórias
                                </DownloadButton>
                            </ModelSideColumn>

                            <ModelSideColumn>
                                <h3>Colunas obrigatórias indeferidos</h3>

                                <div>
                                    {requiredImportHeader.map((item, index) => (
                                        <h4 key={index}>{item}</h4>
                                    ))}
                                </div>
                            </ModelSideColumn>
                        </ModelSide>
                    </>
                    : <Table>
                        <TableTitleContainer>
                            Os itens a seguir já estão no banco de dados. Deseja inserir no mapa?
                        </TableTitleContainer>

                        <TableColumns>
                            {selectAll 
                                ? <MdCheckBox 
                                    size={20} 
                                    style={{ borderRadius: 20}}
                                    onClick={() => changeSelectAll()} 
                                /> 
                                : <MdCheckBoxOutlineBlank 
                                    size={20} 
                                    onClick={() => changeSelectAll()}
                                />
                            }
                            <h3>Nome</h3>
                            <h3>CPF</h3>
                            <h3>Indeferidos Mapa</h3>
                            <h3>Indeferidos BD</h3>
                            <h3>Aux Brasil</h3>
                            <h3>Deferidos</h3>
                            <h3>Data de inserção no mapa</h3>
                            <h3>Ações</h3>
                        </TableColumns>
                        <TableList>
                            {arrayTable.map((item, index) => (
                                <TableItem key={index}>
                                    {item.status 
                                        ? <MdCheckBox 
                                            size={20}
                                            cursor='pointer' 
                                            onClick={() => handleException(item)} 
                                        /> 
                                        : <MdCheckBoxOutlineBlank 
                                            size={20}
                                            cursor='pointer' 
                                            onClick={() => handleException(item)} 
                                        />
                                    }
                                    <h1>{item.nome}</h1>
                                    <div>
                                        <img src={cpfIcon} alt='img img' />
                                        <h3>{item.cpf}</h3>
                                    </div>
                                    
                                    <h3>{item.indeferidosMapa}</h3>
                                    <h3>{item.indeferidos}</h3>
                                    <h3>{item.auxBrasil}</h3>
                                    <h3>{item.deferidos}</h3>
                                    <h3>{datetime.getDate(item.time)}</h3>
                                    <div>
                                        {item.indeferidosMapa != 'Não atendido' && (
                                            <AiOutlineEye 
                                                onClick={() => handleOpenClientModal(item)} 
                                                cursor='pointer' 
                                                size={18} 
                                                color='#808B9F' 
                                                fill='#808B9F'
                                            />
                                        )}
                                    </div>
                                </TableItem>
                            ))}
                        </TableList>
                        <ActionsContent>
                            <CancelButton onClick={() => clearOperation()}>
                                <text>Cancelar importação</text>
                            </CancelButton>
                            {loading
                                ?<DotLoader
                                    size={20}
                                    color="var(--red)"
                                    loading={loading}
                                    speedMultiplier={1.5}
                                />
                                : <ConfirmButton onClick={async () => await uploadClients()}>
                                    <text>Inserir no mapa</text>
                                </ConfirmButton>
                            }
                        </ActionsContent>
                    </Table>
                }
                
            </UploadContent>
            <Modal
                isOpen={modalVisible}
                onRequestClose={closeModal}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalConfirmImport
                    data={{
                        text: 'Deseja excluir os clientes adicionados há 3 meses atrás?',
                        option: 'Sim'
                    }}
                    onCancel={() => clearOperation()}
                    onClose={closeModal}
                    onConfirm={() => {
                        removeOld();
                        closeModal();
                    }}
                />
            </Modal>

            <Modal
                isOpen={modalFullData}
                onRequestClose={() => setModalFullData(false)}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalDetailsAdmin 
                    data={currentClient} 
                    onClose={() => setModalFullData(false)}
                    onStreetView={() => {
                        setModalFullData(false)
                    }}
                />
            </Modal>
        </Container>
        
    )
};

export default UploadPage;