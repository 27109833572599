import React, { useContext, useEffect, useState } from 'react';

import { 
    Circle,
    Container,
    Header,
    ListContainer,
    RankingHeader,
} from './styles';

//Icons
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { RiMedal2Line } from 'react-icons/ri'

//Custom components
import DownloadButton from '../DownloadButton';
import CustomFilterSimple from '../CustomFilterSimple';
import CustomLoading from '../CustomLoading';

//Utils
import Modal from 'react-modal';
import ModalDetailsAdmin from '../ModalDetailsAdmin';
import ModalDashboardItem from '../ModalDashboardItem';
import { UsersContext } from '../../contexts/UsersContext';

//MUI
import { Avatar } from '@mui/material';
import CustomNoRowsOverlay from '../CustomNoRowsOverlay';
import { avatar } from '../../helpers/avatar';

function ModalDashboardCard({ data, onClose }) {

    const { users } = useContext(UsersContext)

    const [ modalData, setModalData ] = useState('');
    const [ currentClient, setCurrentClient ] = useState('');

    //Modal
    const [ modalFullData, setModalFullData ] = useState(false);

    useEffect(() => {
        if(data.array.length > 0){
            setModalData(data.array);
        }
    }, [data]);

    if(data.array.length == 0){
        return (
            <Container>
                <CustomNoRowsOverlay />
            </Container>
        )
    }

    if(!modalData){
        return (
            <Container>
                <CustomLoading />
            </Container>
        )
    }

    return (
        <Container>
            <Header>
                <h3>{data.title}</h3>

                    <div>
                    {data.filterTypes && (
                        <CustomFilterSimple 
                            filterTypes={data.filterTypes}
                            data={modalData}
                            tableRowsBackup={data.array}
                            clearFilters={() => {
                                setModalData(data.array);
                            }}
                            onFilter={(arrayValues) => {
                                setModalData(arrayValues)
                            }}
                            filterType='atendimentos'
                        />
                    )}
                    <DownloadButton tableColumns={data.tableColumns} tableRows={modalData} /> 
                    <AiOutlineCloseCircle style={{ marginLeft: 10 }} onClick={onClose} strokeWidth={0.5} cursor='pointer' size={22} />
                </div>
            </Header>
            {data.type && (
                <RankingHeader>
                    <Circle>
                        <RiMedal2Line color='white' />
                    </Circle>
                    {modalData.length > 0 && (
                        <Avatar
                            alt="Avatar"
                            src={avatar.getUserAvatar(users.operators, modalData[0].operatorUid)}
                            sx={{ 
                                width: 70, 
                                height: 70, 
                                borderRadius: 100, 
                                borderColor: 'white', 
                                borderWidth: 10 
                            }}
                        />
                    )}
                    
                    <h1>{modalData[0].operatorName}</h1>
                    <h3>{modalData.length} {data.type}</h3>
                </RankingHeader>
            )}
            <ListContainer height={data.type ? '55vh' : '75vh'}>
                {modalData && modalData.map((item, index) => (
                    <ModalDashboardItem 
                        key={index} 
                        data={item} 
                        type={data.type} 
                        columns={data.columns} 
                        onViewFullData={() => {
                            setCurrentClient(item);
                            setModalFullData(true);
                        }}
                    />
                ))}
            </ListContainer>

            <Modal
                isOpen={modalFullData}
                onRequestClose={() => setModalFullData(false)}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalDetailsAdmin 
                    data={currentClient} 
                    onClose={() => setModalFullData(false)}
                    onStreetView={() => {
                        
                    }}
                />
            </Modal>
        </Container>
    )
}

export default ModalDashboardCard;