export const searchHelper = {
    searchText
}

function isNumeric(str) {
    var er = /^[0-9]+$/;
    return (er.test(str));
}

function searchText(baseWord, searchWord){
    if(!baseWord || !searchWord) return false
    
    if(isNumeric(searchWord)){
       if(baseWord.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(
        searchWord.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()))
        {
            return true
        }
    }else{
        if(baseWord.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(
            searchWord.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase())
            || searchWord.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(
                baseWord.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()))
        {
            return true
        }
    }

    return false
}