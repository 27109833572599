import styled from 'styled-components';

export const Container = styled.div`
    height: 90vh;
    width: 80vw;

    border-radius: 40px;
`;

export const CloseButton = styled.div`
    position: absolute;
    top: 3%;
    left: 96%;
    right: 0;
    bottom: 0;

    cursor: pointer;
    user-select: none;

    align-items: center;
    justify-content: center;

    color: var(--grey2);
`;

