import React, { useState, createContext } from "react";

import { collection, query, onSnapshot, where, getDoc, doc } from "firebase/firestore";
import db from '../firebase/config';
import { arrayHelper } from "../helpers/arrayHelper";

export const ClientsContext = createContext();

export const ClientsProvider = (props) => {
    const { children } = props;

    const [ clients, setClients ] = useState('');
    const [ clientsUser, setClientsUser ] = useState('');

    const clearClientsContext = async () => {
        setClients('');
        setClientsUser('');
        return
    }

    const getClientsUser = async (mapsArray, especies, uid) => {
        const mapsValues = arrayHelper.reduceToSimple(mapsArray, 'value');

        const consulta = query(collection(db, 'clientes'), 
            where("selected", "==", ""), where("mapId", "in", mapsValues));

        await onSnapshot(consulta, (querySnapshot) => {
            const database = [];
            
            const data = querySnapshot.docs;
            
            for(const i in data){
                const client = data[i].data();
                const mapName = mapsArray.find(x => x.value == client.mapId)

                if(client.selected != 'done' && 
                    client.endNotFound != true && 
                    client.endNotFoundOperator != true && 
                    client.differentLocation != true &&
                    (client.operatorUid === uid || client.operatorUid === "" || !client.operatorUid))
                    
                {
                    if(mapName && mapName.label && especies[mapName.label]){
                        if(client.specialIcon){
                            if(especies[mapName.label].includes('ESPECIAL')){
                                database.push({...client, docId: data[i].id});
                            }
                        }else{
                            if(especies[mapName.label].includes(client.especie)){
                                database.push({...client, docId: data[i].id});
                            }else{
                                //verifica se é um retorno, pois pode estar no mapa independente do benefício
                                if(client.retornos > 0 && client.operatorUid === uid){
                                    database.push({...client, docId: data[i].id})
                                }
                            }
                        } 
                    }
                }
            }
            
            setClientsUser(database);
        }, error => {
            console.log("error: ", error)
        });
    }
    
    const getClientsAdmin = async () => {        
        const consulta = query(collection(db, 'clientes'), 
            where("selected", "not-in", ["done", "foraDoMapa"]));
        await onSnapshot(consulta, (querySnapshot) => {
            const database = [];
            
            const data = querySnapshot.docs;
            
            for(const i in data){
                const client = data[i].data();

                database.push({...client, docId: data[i].id});
            }
            
            setClients(database);
        });
    }

    //Get clientes do gerente
    const getClientsManager = async (mapsArray) => {     
        
        if(!mapsArray || mapsArray?.length == 0){
            return setClients([]);
        } 

        const mapsIds = arrayHelper.reduceToSimple(mapsArray, 'value');

        const consulta = query(collection(db, 'clientes'), where('mapId', 'in', mapsIds), where("selected", "!=", "done"));
        await onSnapshot(consulta, (querySnapshot) => {
            const database = [];
            
            const data = querySnapshot.docs;
            
            for(const i in data){
                const client = data[i].data();

                if(client.selected != 'foraDoMapa'){
                    database.push({...client, docId: data[i].id});
                }
            }
      
            setClients(database);
        });
    }

    const getClientByCpf = async (cpf) => {
        const docRef = doc(db, "clientes", cpf);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const client = {...docSnap.data(), docId: docSnap.id };
            
            return client
        } else {
            return null
        }
    }

    return (
        <ClientsContext.Provider 
            value={{ 
                clients: clients,
                clientsUser: clientsUser,
                getClientsUser,
                getClientsAdmin,
                getClientsManager,
                getClientByCpf,
                clearClientsContext,
            }}
        >
            {children}
        </ClientsContext.Provider>
    )
}