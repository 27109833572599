import React, { useEffect, useState, useContext } from 'react'
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, StreetViewPanorama } from '@react-google-maps/api';

//Styled components
import {
    Container,  
    TableContainer,
    MapContainer,
    MapBar,
    MapBarHeader,
    NewMapButton,
    MapsContainer,
    MapDivRight,
    MapAddressDiv,
    MapAddressHeader,
    AlertDiv,
    AlertDivHeader,
} from './styles';

import { 
    CustomInfoWindow, 
    InfoWindowTextContainer,
    HelpButton,
    MarkersLegend,
    LegendItem,
} 
from '../MapPage/styles';

//Firebase and Context
import { AuthContext } from '../../contexts/AuthContext';
import { collection, doc, updateDoc, addDoc, writeBatch, deleteDoc } from "firebase/firestore";
import db from '../../firebase/config';


//Icons
import { MdAddCircleOutline } from 'react-icons/md';
import { BiMap } from 'react-icons/bi';
import { BiHelpCircle } from 'react-icons/bi';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import { markerIcons } from '../../resources/markerIcons';

//MUI
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

//Custom Components
import AdminMapCard from '../../components/AdminMapCard';
import ClientDetailsAdmin from '../../components/ClientDetailsAdmin';
import CustomLoadingPage from '../../components/CustomLoadingPage';
import CustomTable from '../../components/CustomTable';
import ModalChangeAddress from '../../components/ModalChangeAddress';
import ModalDeleteMap from '../../components/ModalDeleteMap';
import ModalDetailsAdmin from '../../components/ModalDetailsAdmin';
import ModalFormMap from '../../components/ModalFormMap';
import ModalReportAdmin from '../../components/ModalReportAdmin';
import ProfileButton from '../../components/ProfileButton';
import PointIcon from '../../components/PointIcon';

//APIS
import Geocode from "react-geocode";

//JSON
import tableColumnsMap from '../../resources/tableColumnsMap.json';

//Utils
import Modal from 'react-modal';
import { alertErrorStyle } from '../../resources/alertErrorStyle';
import { toast } from 'react-toastify';
import { alertSuccessStyle } from '../../resources/alertSuccessStyle';
import ModalHeader from '../../components/ModalHeader';
import { address } from '../../helpers/address';
import { markers as markersHelper } from '../../helpers/markers';
import { ClientsContext } from '../../contexts/ClientsContext';
import { MapsContext } from '../../contexts/MapsContext';
import { addClientLogs } from '../../firebase/logs/clients/addClientLogs';
import { addMapLogs } from '../../firebase/logs/maps/addMapLogs';
import EspeciesMapFilter from './components/EspeciesMapFilter';
import { arrayHelper } from '../../helpers/arrayHelper';
import { badges } from '../../helpers/badges';
import NewAddressCard from '../../components/NewAddressCard';

const alertOptions = [
    {
        title: 'endereços não encontrados',
        value: 'endNotFound',
    },
    {
        title: 'não encontrados pelos operadores',
        value: 'endNotFoundOperator',
    },
    {
        title: 'localidades diversas',
        value: 'differentLocation', 
    }
]

const AdminMapPage = props => {

    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    Geocode.setLanguage('pt-BR');

    const { isAdmin, isManager, userData } = useContext(AuthContext);
    const { clients, getClientsAdmin, getClientsManager } = useContext(ClientsContext);
    const { maps, getMapsAdmin } = useContext(MapsContext);

    const [ loadingData, setLoadingData ] = useState(true);

    //Clientes and Table    
    const [ clientes, setClientes ] = useState('');
    const [ clientesBackup, setClientesBackup ] = useState('');
    const [ clientesBackupTable, setClientesBackupTable ] = useState('');

    //Client manager
    const [ viewDetails, setViewDetails ] = useState(false);
    const [ currentClient, setCurrentClient ] = useState('');
    const [ newAddress, setNewAddress ] = useState('');
    const [ dataType, setDataType ] = useState('clientsMap');

    //Modal
    const [ modalFormMap, setModalFormMap ] = useState(false);
    const [ modalLoading, setModalLoading ] = useState(false);
    const [ modalChangeAddress, setModalChangeAddress ] = useState(false);
    const [ modalFullData, setModalFullData ] = useState(false);
    const [ modalReport, setModalReport ] = useState(false);
    const [ modalLogs, setModalLogs ] = useState(false);

    //Map manager
    const [ selectedMap, setSelectedMap ] = useState('');
    const [ newMap, setNewMap ] = useState('');
    const [ newAddressView, setNewAddressView ] = useState(true);
    const [ modalDeleteMap, setModalDeleteMap ] = useState(false);
    const [ mapToDelete, setMapToDelete ] = useState('');

    //Map
    const [ mapRef, setMapRef ] = useState(null);
    const [ alerts, setAlerts ] = useState([]);
    const [ centerMap, setCenterMap ] = useState('');
    const [ mapZoom, setMapZoom ] = useState(5);
    const [ streetView, setStreetView ] = useState(false);
    const [ expandedAlert, setExpandedAlert ] = useState(false);
    const [ alertsNumber, setAlertsNumber ] = useState('');
    const [ showInfoWindow, setShowInfoWindow ] = useState({});
    const [ markers, setMarkers ] = useState(false);
    const [ mapsData, setMapsData ] = useState([]);
    const [ optionsCities, setOptionsCities ] = useState([]);
    const [ legend, setLegend ] = useState('');
    const [ clearEspeciesFilter, setClearEspeciesFilter ] = useState(false);

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    useEffect(() => {
        (async () => {
            if(!clients){      
                await getMapsAdmin()

                if(isAdmin){
                    await getClientsAdmin();
                }

                if(isManager && userData){
                    await getClientsManager(userData.mapsArray);
                }                
            }else{
                const validCities = [];
                const arrayMaps = [];

                const clientsMap = [];
                var allCities = [];

                for(const cliente of clients){
                    if(cliente.selected != 'done' && cliente.endNotFound != true){
                        clientsMap.push(cliente)
                    }
                    
                }
                
                if(isAdmin){
                    for(const i in clients){
                        const mapDoc = maps.find(x => x.docId === clients[i].mapId)
                        // const result = mapDoc.cities.find(x => x === clients[i].cidadeMap);
    
                        if(!mapDoc){
                            const inValid = validCities.find(x => x.value === clients[i].cidadeMap);
                            if(!inValid){
                                validCities.push({
                                    value: clients[i].cidadeMap, 
                                    label: clients[i].cidadeMap
                                })
                            }
                        }
                    }
    
                    getAlerts();

                    

                    for(const map of maps){
                        const clientes = clientsMap.filter(x => x.mapId === map.docId);

                        arrayMaps.push({
                            ...map,
                            clientes: clientes,
                            clientesBackup: clientes,
                            calculate: true,
                        })

                        allCities = allCities.concat(map.cities)
                    }

                    arrayMaps.unshift({
                        mapName: 'Geral',
                        cities: allCities,
                        clientes: clientsMap,
                        clientesBackup: clientsMap,
                        calculate: true,
                    })

                    if(!selectedMap){
                        setSelectedMap({
                            mapName: 'Geral',
                            cities: allCities,
                            clientes: clientsMap,
                            clientesBackup: clientsMap,
                            calculate: false
                        })
                    }

                    setMapsData(arrayMaps);
                    setOptionsCities(validCities);
                    handleSelectedMap(arrayMaps[0])
                }

                if(isManager && userData?.mapsArray){
                    for(const map of maps){
                        const managerMap = userData?.mapsArray?.find(x => x === map.docId);

                        if(managerMap){
                            const clientes = clientsMap.filter(x => x.mapId === map.docId);

                                arrayMaps.push({
                                    ...map,
                                    clientes: clientes,
                                    clientesBackup: clientes
                                })
                        }
                    }

                    setMapsData(arrayMaps);
                    handleSelectedMap(arrayMaps[0])
                }

                if(!currentClient){
                    calculateMapCenter(clientsMap)
                }

                if(currentClient){
                    const updatedClient = clientsMap.find(x => x.cpf == currentClient.cpf);

                    if(updatedClient){
                        setCurrentClient(updatedClient)
                    }
                }
                
                setClientesBackup(clientsMap); 
                switch(dataType){
                    case 'clientsMap':
                        setClientes(clientsMap); 
                        setClientesBackupTable(clientsMap); 
                        break
                    case alertOptions[0].value:
                        getEndNotFound();
                        break
                    case alertOptions[1].value:
                        getEndNotFound();
                        break
                    case alertOptions[2].value:
                        getDifferentLocation();
                        break
                }
                
                setMarkers(true);
                setLoadingData(false);
            }
        })();
        
    }, [clients, maps, userData]);

    useEffect(() => {
        if(dataType == 'clientsMap'){
            setClientes(selectedMap.clientes)
        }
        if(selectedMap && selectedMap.clientes?.length > 0){
            if(selectedMap.calculate){
                calculateMapCenter(selectedMap.clientes)
            }
        }
    }, [selectedMap])

    const calculateMapCenter = (clientes) => {
        const arrayLat = [];
        const arrayLng = [];
        var valueBase = 0;

        if(selectedMap.mapName != 'Geral'){
            valueBase = 10.52150820000008;
        }else{
            valueBase = 8.397150820000008;
        }

        for(const cliente of clientes){
            if(cliente.cidadeMap != 'SP - TREMEMBÉ' && cliente.cpf != '0' && cliente.cpf != '11' && cliente.cpf != '111'){
                arrayLat.push(cliente.lat);
                arrayLng.push(cliente.lng);
            }else{
                
            }
        }

        const centerLat = (getMaxOfArray(arrayLat) + getMinOfArray(arrayLat)) / 2
        const centerLng = (getMaxOfArray(arrayLng) + getMinOfArray(arrayLng)) / 2

        const dif = getMaxOfArray(arrayLat) - getMinOfArray(arrayLat)

        if(clientes.length > 1){
            changeMapCenter({lat: centerLat, lng: centerLng}, (valueBase / dif)*dif)
        }else{
            if(clientes.length == 0){
                const defaultCoords = { lat: -8.060360, lng: -34.921713 }

                changeMapCenter(defaultCoords, valueBase)
                return toast('Sem clientes no mapa', alertErrorStyle)
            }else{
                changeMapCenter({lat: clientes[0].lat, lng: clientes[0].lng}, valueBase)
            }   
        }        
    }

    const handleSelectedMap = (map) => {
        setSelectedMap({
            ...map,
            calculate: true,
        })
    }

    const changeMapCenter = (mapCenter, zoom) => {
        setCenterMap(mapCenter)
        setMapZoom(zoom)
    }

    function getMaxOfArray(numArray) {
        return Math.max.apply(null, numArray);
    }

    function getMinOfArray(numArray) {
        return Math.min.apply(null, numArray);
    }

    const getAlerts = async () => {

        var count = 0;
        const arrayAlerts = [];

        const arrayEndNotFound = [];
        const arrayDifferentLocation = [];

        for(const cliente of clients){
            if(cliente.selected != 'done' && (
                cliente[alertOptions[0].value] == true  || cliente[alertOptions[1].value] == true )){
                arrayEndNotFound.push(cliente)
            }else{
                if(cliente.selected != 'done' && cliente[alertOptions[2].value] == true){
                    arrayDifferentLocation.push(cliente)
                }
            }
        }

        if(arrayEndNotFound.length > 0){
            arrayAlerts.push({
                qtd: arrayEndNotFound.length,
                title: alertOptions[0].title,
                dataType: alertOptions[0].value,
            })
        }

        if(arrayDifferentLocation.length > 0){
            arrayAlerts.push({
                qtd: arrayDifferentLocation.length,
                title: alertOptions[2].title,
                dataType: alertOptions[2].value,
            })
        }

        setAlerts(arrayAlerts);
        setAlertsNumber(arrayEndNotFound.length + arrayDifferentLocation.length)
    }

    const createMap = async (mapData) => {

        const batchArray = [];
        batchArray.push(writeBatch(db));
        var operationCounter = 0;
        var batchIndex = 0;

        const arrayCities = [];
        const arrayClientsInCities = [];

        const dateNow = new Date();

        if(mapData === null || !mapData.mapName || mapData.cities.length === 0){
            return toast("Preencha o nome e as cidades antes de salvar", alertErrorStyle)
        }

        const dataToPost = {
            mapName: mapData.mapName,
            cities: mapData.cities,
            time: dateNow.getTime(),
        }

        setModalLoading(true);

        const docId = await addDoc(collection(db, "maps"), dataToPost)
        .then(async (doc) => {
            var stringCidades = '';

            for(const item of mapData.cities){
                if(stringCidades == ''){
                    stringCidades = item.label
                }else{
                    stringCidades = stringCidades + ' | ' + item.label
                }
            }
            const logMessage = `${mapData.mapName} foi criado com as cidades: [ ${stringCidades} ]`

            await addMapLogs(doc.id, logMessage)

            toast(logMessage, alertSuccessStyle)
            return doc.id
        }).catch(async (error) => {
            setModalLoading(false);
            return toast(`Erro ao criar mapa: ${error.code}`, alertErrorStyle)
        })

        for(const city of mapData.cities){
            const result = arrayCities.find(x => x.value === city.value);

            if(!result){
                arrayCities.push(city.value);
            }
        }

        for(const client of clients){
            if(arrayCities.includes(client.cidadeMap) && (!client.mapId || client.mapId != docId)){
                arrayClientsInCities.push(client.cpf);
                const docRef = doc(db, "clientes", client.cpf);
        
                batchArray[batchIndex].update(docRef, {
                    mapId: docId,
                });
                operationCounter++;

                if (operationCounter === 499) {
                    batchArray.push(writeBatch(db));
                    batchIndex++;
                    operationCounter = 0;
                }
            }
        }

        if(arrayClientsInCities.length != 0 && batchArray.length != 0){
            for(const batch of batchArray){
                batch.commit().catch((error) => {
                    toast(`Erro ao definir mapa dos clientes: ${error.code}`, alertErrorStyle)
                })
            }
        }

        setModalLoading(false);
        setNewMap(false);
        setModalFormMap(false);
    }

    const updateMap = async (mapData) => {

        if(mapData === null || !mapData.mapName || mapData.cities.length === 0){
            return toast("Preencha o nome e as cidades antes de salvar", alertErrorStyle)
        }

        setModalLoading(true);

        const batchArray = [];
        batchArray.push(writeBatch(db));
        var operationCounter = 0;
        var batchIndex = 0;

        const arrayCities = [];
        const arrayClientsInCities = [];

        const docRef = doc(db, "maps", mapData.docId);

        await updateDoc(docRef, {
            mapName: mapData.mapName,
            cities: mapData.cities,
        }).then(async () => {
            var stringCidades = '';

            for(const item of mapData.cities){
                if(stringCidades == ''){
                    stringCidades = item.label
                }else{
                    stringCidades = stringCidades + ' | ' + item.label
                }
            }
            const logMessage = `${mapData.mapName} foi atualizado e agora contêm as cidades: [ ${stringCidades} ]`

            await addMapLogs(mapData.docId, logMessage)

            toast(logMessage, alertSuccessStyle)
        }).catch(async (error) => {
            const logMessage = `Erro ao atualizar o mapa ${mapData.mapName}: ${error.code}`

            await addMapLogs(mapData.docId, logMessage)

            setModalLoading(false);
            return toast(logMessage, alertErrorStyle);
        })

        for(const city of mapData.cities){
            const result = arrayCities.find(x => x.value === city.value);

            if(!result){
                arrayCities.push(city.value);
            }
        }

        for(const client of clients){
            if(arrayCities.includes(client.cidadeMap) && (!client.mapId || client.mapId != mapData.docId)){
                arrayClientsInCities.push(client.cpf);
                const docRef = doc(db, "clientes", client.cpf);
        
                batchArray[batchIndex].update(docRef, {
                    mapId: mapData.docId,
                });
                operationCounter++;

                if (operationCounter === 499) {
                    batchArray.push(writeBatch(db));
                    batchIndex++;
                    operationCounter = 0;
                }
            }
        }

        if(arrayClientsInCities.length != 0 && batchArray.length != 0){
            for(const batch of batchArray){
                batch.commit().catch((error) => {
                    toast(`Erro ao definir mapa dos clientes: ${error.code}`, alertErrorStyle)
                })
            }
        }

        setModalLoading(false);
        setNewMap(false);
        setModalFormMap(false);
    }

    const deleteMap = async () => {
        setModalLoading(true);

        const docRef = doc(db, "maps", mapToDelete.docId);

        await deleteDoc(docRef)
            .then(async () => {
                const logMessage = `O mapa ${mapToDelete.mapName} foi excluído com sucesso.`

                await addMapLogs(mapToDelete.docId, logMessage)
                toast(logMessage, alertSuccessStyle)
            }).catch(async (error) => {
                const logMessage = `Erro ao excluir o mapa ${mapToDelete.mapName}: ${error.code}`

                await addMapLogs(mapToDelete.docId, logMessage)

                setModalLoading(false);
                toast(logMessage, alertErrorStyle)
            })

        setModalLoading(false);
        setMapToDelete('');
        setModalDeleteMap(false);
    }

    const getLocation = async (address) => {

        try {
            const response = await Geocode.fromAddress(address.toLowerCase());
            const locations = response.results[0].geometry.location;
            return locations;
        }catch{
            return null
        }
        
    }

    const saveNewAddress = async (newAddress) => {
        const result = await getLocation(address.formatStringEnd(newAddress));

        const newCidadeMap = newAddress.estado.trim() + ' - ' + newAddress.cidade.trim();
        let newMapId = null

        for(const map of maps){
            const findInCities = map.cities.find(x => x.value === newCidadeMap);

            if(findInCities){
                newMapId = map.docId
            }
        }

        if(!newMapId){
            toast(`Não foi encontrado um mapa com a cidade ${
                newCidadeMap}, adicione essa cidade ao mapa para concluir a alteração`, alertErrorStyle)
        }

        if(result && newCidadeMap){
            const docRef = doc(db, "clientes", currentClient.cpf);

            await updateDoc(docRef, {
                lat: result.lat,
                lng: result.lng,
                differentLocation: false,
                operatorUid: "",
                selected: "",
                cidadeMap: newMapId ? newCidadeMap : "",
                mapId: newMapId ? newMapId : currentClient.mapId
            }).then(async () => {
                const logMessage = `${currentClient.nome} teve sua localização do mapa alterada`

                await addClientLogs(currentClient.cpf, logMessage)

                setCurrentClient({
                    ...currentClient, 
                    lat: result.lat,
                    lng: result.lng,
                    differentLocation: false,
                    operatorUid: "",
                    selected: "",
                    cidadeMap: newMapId ? newCidadeMap : "",
                    mapId: newMapId ? newMapId : currentClient.mapId
                })
                setShowInfoWindow({ status: true, cliente: currentClient.cpf })
                setViewDetails(true)
                setNewAddressView(false)
                setNewAddress('');
                toast(logMessage, alertSuccessStyle)
            }).catch(async (error) => {

                const logMessage = `${currentClient.nome} erro ao salvar sua nova localização no mapa: ${error.code}`

                await addClientLogs(currentClient.cpf, logMessage);

                return toast(logMessage, alertErrorStyle)
            })

            
        }else{
            return toast('Endereço não encontrado', alertErrorStyle)
        }
    }

    const changeAddress = async (newValidEnd) => {
        const newValidAddress = address.formatValidEnd(currentClient, newValidEnd);

        const result = await getLocation(newValidAddress);

        if(!currentClient[`estado${newValidEnd}`] || !currentClient[`cidade${newValidEnd}`]){
            return toast(`O novo endereço não possui os campos de estado ou cidade, edite essas informações no modal do cliente e tente novamente`, alertErrorStyle)
        }

        const newCidadeMap = currentClient[`estado${newValidEnd}`].trim() 
                + ' - ' + currentClient[`cidade${newValidEnd}`].trim();
        let newMapId = null

        for(const map of maps){
            const findInCities = map.cities.find(x => x.value === newCidadeMap);

            if(findInCities){
                newMapId = map.docId
            }
        }

        if(!newMapId){
            toast(`Não foi encontrado um mapa com a cidade ${
                newCidadeMap}, adicione essa cidade ao mapa para concluir a alteração`, alertErrorStyle)
        }

        if(result){
            const docRef = doc(db, "clientes", currentClient.cpf);

            await updateDoc(docRef, {
                lat: result.lat,
                lng: result.lng,
                validEnd: newValidEnd,
                endNotFound: false,
                endNotFoundOperator: false,
                operatorUid: "",
                selected: "",
                cidadeMap: newMapId ? newCidadeMap : "",
                mapId: newMapId ? newMapId : currentClient.mapId
            }).then(async () => {
                const logMessage = `${currentClient.nome} teve seu endereço válido do mapa alterado para ${newValidEnd}`

                await addClientLogs(currentClient.cpf, logMessage)

                setCurrentClient({
                    ...currentClient, 
                    lat: result.lat,
                    lng: result.lng,
                    validEnd: newValidEnd,
                    endNotFound: false,
                    endNotFoundOperator: false,
                    operatorUid: "",
                    selected: "",
                    cidadeMap: newMapId ? newCidadeMap : "",
                    mapId: newMapId ? newMapId : currentClient.mapId
                })
                setShowInfoWindow({ status: true, cliente: currentClient.cpf })
                setModalChangeAddress(false)
                toast(logMessage, alertSuccessStyle)
            }).catch(async (error) => {

                const logMessage = `${currentClient.nome} erro ao alterar endereço válido do mapa: ${error.code}`

                await addClientLogs(currentClient.cpf, logMessage);

                return toast(logMessage, alertErrorStyle)
            })

            
        }else{
            return toast('Localização do mapa não encontrada', alertErrorStyle)
        }
    }

    const getEndNotFound = async () => {
        const array = [];

        for(const cliente of clients){
            if(cliente.selected != 'done' && cliente[alertOptions[0].value] == true ||
                cliente[alertOptions[1].value] == true
            ){
                array.push(cliente)
            }
        }
       
        setClientes(array);
        handleSelectedMap(mapsData[0])
        setClientesBackupTable(array);
        
        setCurrentClient('');
        setShowInfoWindow({ status: false, cliente: null})
        setNewAddress('')
    }
    
    const getDifferentLocation = async () => {
        const array = [];

        for(const cliente of clients){
            if(cliente.selected != 'done' && cliente[alertOptions[2].value] == true){
                array.push(cliente)
            }
        }

        setClientes(array);
        handleSelectedMap(mapsData[0])
        setClientesBackupTable(array);
        setCurrentClient('');
        setShowInfoWindow({ status: false, cliente: null})
        setNewAddress('')
    }

    const handleChangeData = async (event, newDataType) => {
        setDataType(newDataType);

        switch(newDataType){
            case alertOptions[0].value:
                await getEndNotFound();
                break
            case alertOptions[1].value:
                await getEndNotFound();
                break
            case alertOptions[2].value:
                await getDifferentLocation();
                break
        }
    }

    if(loadingData || !clientes || !isLoaded){
        return <Container>
            <CustomLoadingPage />
        </Container>
    }

    return (
        <>
        <Container>
            {isLoaded && loadingData === false && centerMap && clientes && selectedMap ? (
                <MapContainer>
                    <MapBar>
                        <MapBarHeader>
                            <h3>Mapas</h3>
                            {isAdmin && (
                                <NewMapButton onClick={() => {
                                    setNewMap(true)
                                    setModalFormMap(true);
                                }}>
                                    <MdAddCircleOutline />
                                    <text>Novo mapa</text>
                                </NewMapButton>
                            )}
                        </MapBarHeader>
                        <MapsContainer>
                            {mapsData.length > 0 && mapsData.map((item, index) => (
                                <AdminMapCard 
                                    key={index}
                                    map={item} 
                                    selected={selectedMap.docId == item.docId ? true : false}
                                    onSelect={() => {
                                        setDataType('clientsMap')
                                        handleSelectedMap(item)
                                    }}
                                    onDelete={() => {
                                        setMapToDelete(item);
                                        setModalDeleteMap(true)
                                    }}
                                    onEdit={() => {
                                        setDataType('clientsMap')
                                        handleSelectedMap(item)
                                        setModalFormMap(true);
                                    }}
                                />
                            ))}
                        </MapsContainer>
                    </MapBar>
                    <GoogleMap
                        onLoad={map => {
                            setMapRef(map)
                        }}
                        mapContainerStyle={{width: '100%', height: '100%'}}
                        clickableIcons={false}
                        center={centerMap}
                        zoom={mapZoom}
                        options={{
                            disableDefaultUI: true
                        }}
                    >
                        <MapDivRight>
                            <EspeciesMapFilter 
                                onChangeSelected={(selectedEspecies) => {
                                    if(mapRef && mapRef?.center && mapRef?.zoom){
                                        changeMapCenter(mapRef.center, mapRef.zoom)
                                    }              
                                    
                                    setSelectedMap(prev => {
                                        const newObj = {
                                            ...prev,
                                            calculate: false,
                                            clientes: prev.clientesBackup.filter(
                                                x => {
                                                    const isEspecial = selectedEspecies.find(x => x.especie == 'ESPECIAL');

                                                    if(isEspecial){
                                                        if(x.specialIcon){
                                                            return true
                                                        }
                                                    }

                                                    if(arrayHelper.isPropertyValue(selectedEspecies, 'especie', x.especie) && !x.specialIcon){
                                                        return true
                                                    }

                                                    return false
                                                })
                                        }

                                        return newObj
                                    })
                                }}
                                calculate={selectedMap ? selectedMap?.calculate : true}
                                clearEspeciesFilter={clearEspeciesFilter}
                            />
                            {isAdmin && (
                                <AlertDiv>
                                    <AlertDivHeader>
                                        <div>
                                            <h3>Alertas</h3>
                                            <PointIcon 
                                                text={alertsNumber}
                                                backgroundColor='var(--red)'
                                                size={30}
                                                textColor='var(--white)'
                                                fontSize='14'
                                                fontWeight='400'
                                            />
                                        </div>
                                        
                                        {expandedAlert
                                            ? <IoIosArrowUp 
                                                cursor='pointer' 
                                                onClick={() => setExpandedAlert(!expandedAlert)} 
                                            />
                                            : <IoIosArrowDown 
                                                cursor='pointer' 
                                                onClick={() => setExpandedAlert(!expandedAlert)} 
                                            />
                                        }
                                    </AlertDivHeader>
                                    {expandedAlert && alerts.length > 0 && alerts.map((item, index) => (
                                        <Accordion 
                                            key={index} 
                                            style={{ 
                                                boxShadow: 'none', 
                                                margin: 0, 
                                                padding: 0, 
                                            }}
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls={`${index}-content"`}
                                                id={`${index}-header"`}
                                                style={{ 
                                                    marginBottom: -10, 
                                                    gap: 10, 
                                                    padding: 0,
                                                }}
                                            >
                                                <Typography sx={{ 
                                                    width: '100%', 
                                                    flexShrink: 10, 
                                                    fontSize: 12, 
                                                    fontFamily: 'Roboto' 
                                                }}>
                                                    {`${item.qtd} ${item.title}`}
                                                </Typography>
                                            </AccordionSummary>
                                            <AccordionDetails
                                                style={{ paddingTop: 0, paddingLeft: 0 }}
                                                onClick={(event) => {
                                                    handleChangeData(event, item.dataType)
                                                }}
                                            >
                                                <h4>
                                                    Corrigir
                                                </h4>
                                            </AccordionDetails>
                                        </Accordion>
                                    ))}
                                </AlertDiv>
                            )}

                            <ProfileButton arrowColor='white' />                       
                        </MapDivRight>
                        {newAddressView && newAddress && currentClient && (
                            <MapAddressDiv>
                                <MapAddressHeader>
                                    <h3>{currentClient.nome}</h3>
                                    <div>
                                        <BiMap size={15} color='var(--red)' />
                                        <h4 onClick={() => setModalChangeAddress(true)}>
                                            Escolher outro endereço
                                        </h4>
                                        <AiOutlineCloseCircle 
                                            onClick={() => setNewAddressView(false)} 
                                            strokeWidth='0.5'
                                            cursor='pointer' 
                                            size={25} 
                                            color='var(--blue4)'
                                        />  
                                    </div>
                                </MapAddressHeader>
                                
                                <NewAddressCard 
                                    client={currentClient} 
                                    onSave={(newAddress) => saveNewAddress(newAddress)} 
                                />
                            </MapAddressDiv>
                        )}
                        {!legend && (
                            <HelpButton onClick={() => setLegend(true)}>
                                <BiHelpCircle size={40} cursor='pointer' />
                            </HelpButton>
                        )}
                        {legend && (
                            <MarkersLegend>
                                <ModalHeader title='Legenda' onClose={() => setLegend(false)} />
                                {markerIcons && markerIcons.map((item, index) => (
                                    <LegendItem key={index}>
                                        <img src={item.icon} alt='img img' />
                                        <h1>{item.title}</h1>
                                    </LegendItem>
                                ))}
                            </MarkersLegend>
                        )}
                        {markers                    
                            ?( clientes.map((cliente) => (
                                cliente.lat
                                ?<Marker 
                                    key={cliente.cpf} 
                                    position={{lat: cliente.lat, lng: cliente[`lng`] }}
                                    onClick={async () => {
                                        setShowInfoWindow({ status: true, cliente: cliente.cpf});
                                        setCurrentClient(cliente);
                                        setViewDetails(true)
                                    }}
                                    options={{
                                        icon: markersHelper.getMarkerIcons(cliente),
                                        optimized: false,
                                    }}
                                >
                                    {showInfoWindow.status === true && showInfoWindow.cliente === cliente.cpf 
                                    && (
                                        <InfoWindow
                                            position={{lat: currentClient.lat, lng: currentClient[`lng`]}}
                                            clickable={true}
                                            onCloseClick={() => setShowInfoWindow({ status: false, cliente: null})}
                                        >
                                                <CustomInfoWindow>
                                                    <InfoWindowTextContainer>
                                                        <h4>{currentClient.nome}</h4>
                                                    </InfoWindowTextContainer>
                                                </CustomInfoWindow>
                                        </InfoWindow>
                                    )}
                                </Marker>
                                : null                            
                            )))
                            : (
                                <></>
                            )
                        }   
                        {streetView === true && (
                            <StreetViewPanorama position={{lat: currentClient.lat, lng: currentClient.lng}} visible={streetView}/>
                        )}  
                        {currentClient && viewDetails && (
                            <ClientDetailsAdmin 
                                data={currentClient} 
                                onClose={() => {
                                    setViewDetails(false)
                                    setCurrentClient('');
                                    setShowInfoWindow({ status: false, cliente: null})
                                }} 
                                onStreetView={async () => {
                                    if(streetView == true){
                                        setStreetView(false);
                                        setTimeout(async () => {
                                            setStreetView(true)
                                        }, 500)
                                    }else{
                                        setStreetView(!streetView)
                                    }
                                }}
                                onGenerateReport={() => {
                                    setModalReport(true);
                                }}
                                onViewFullData={() => {
                                    setModalFullData(true)
                                }}
                                onChangeSpecialIcon={() => {
                                    setSelectedMap(prev => ({
                                            ...prev,
                                            calculate: false,
                                            clientes: prev.clientesBackup,
                                            clientesBackup: prev.clientesBackup,
                                        })
                                    )
                                    setClearEspeciesFilter(!clearEspeciesFilter)
                                }}
                            />
                        )}
                    </GoogleMap>
                </MapContainer>
            ) : (
                <Container>
                    {/* <CustomLoadingPage /> */}
                </Container>
            )}
            <TableContainer>
                <CustomTable 
                    tableColumns={tableColumnsMap}
                    tableRows={clientes}
                    filterTypes={tableColumnsMap}
                    tableRowsBackup={clientesBackupTable}
                    itemType='map'
                    columns={6}
                    filtersHighlights={[ { field: 'cidadeMap', headerName: 'Cidade' } ]}
                    tableType={dataType}
                    onClick={(client) => {
                        setShowInfoWindow({ status: true, cliente: client.cpf});
                        setCurrentClient(client);
                        setViewDetails(true)
                        setClientes([client])
                    }}
                    onFilter={() => {

                    }}
                    onClearFilters={() => {
                        
                    }}
                    tableDefaultFilters={{}}
                    onChangeTableType={(newDataType) => {
                        setDataType(newDataType)
                        switch(newDataType){
                            case 'clientsMap':
                                setClientes(clientesBackup); 
                                setClientesBackupTable(clientesBackup); 
                                break
                            case alertOptions[0].value:
                                getEndNotFound();
                                break
                            case alertOptions[1].value:
                                getEndNotFound();
                                break
                            case alertOptions[2].value:
                                getDifferentLocation();
                                break
                        }
                    }}
                    onChangeAddress={(client) => {
                        if(dataType == alertOptions[2].value){
                            setViewDetails(false)
                            setNewAddress(address.formatEnd(client))
                            setCurrentClient(client)
                            setNewAddressView(true)
                        }

                        if(dataType == alertOptions[0].value){
                            setViewDetails(false)
                            setCurrentClient(client)
                            setModalChangeAddress(true)
                        }
                    }}
                    dateField='time'
                />
            </TableContainer>
        </Container>

        <Modal
            isOpen={modalFullData}
            onRequestClose={() => setModalFullData(false)}
            overlayClassName="modal-overlay"
            className="modal-content-auto-width"
            contentLabel="Example Modal"
        >
            <ModalDetailsAdmin 
                data={currentClient} 
                onClose={() => setModalFullData(false)}
                onStreetView={() => {
                    setModalFullData(false)
                    if(streetView == true){
                        setStreetView(false);
                        setTimeout(async () => {
                            setStreetView(true)
                        }, 500)
                    }else{
                        setStreetView(!streetView)
                    }
                }}
            />
        </Modal>

        <Modal
            isOpen={modalChangeAddress}
            onRequestClose={() => {
                setModalChangeAddress(false);
            }}
            overlayClassName="modal-overlay"
            className="modal-content-auto-width"
            contentLabel="Example Modal"
        >
            <ModalChangeAddress 
                title='Escolher próximo endereço no mapa' 
                data={currentClient}
                loading={modalLoading}
                onSave={(newValidEnd) => {
                    changeAddress(newValidEnd)
                }}
                onClose={() => {
                    setModalChangeAddress(false);
                }}/>
        </Modal>

        <Modal
            isOpen={modalFormMap}
            onRequestClose={() => {
                setModalFormMap(false);
                setNewMap(false)
            }}
            overlayClassName="modal-overlay"
            className="modal-content-auto-width"
            contentLabel="Example Modal"
        >
            <ModalFormMap 
                title={!newMap ? 'Editar mapa' : 'Novo mapa'} 
                data={!newMap ? selectedMap : null}
                loading={modalLoading}
                optionsCities={optionsCities}
                onSave={(dataMap) => {
                    if(newMap){
                        createMap(dataMap);
                    }else{
                        updateMap(dataMap)
                    }
                }}
                onClose={() => {
                    setModalFormMap(false);
                    setNewMap(false)
                }}/>
        </Modal>

        <Modal
            isOpen={modalDeleteMap}
            onRequestClose={() => {
                setModalDeleteMap(false);
            }}
            overlayClassName="modal-overlay"
            className="modal-content-auto-width"
            contentLabel="Example Modal"
        >
            <ModalDeleteMap 
                mapName={mapToDelete.mapName}
                loading={modalLoading}
                onConfirm={() => {
                    deleteMap()
                }}
                onCancel={() => {
                    setModalDeleteMap(false);
            }}/>
        </Modal>

        <Modal
            isOpen={modalReport}
            onRequestClose={() => {
                setModalReport(false);
            }}
            overlayClassName="modal-overlay"
            className="modal-content-auto-width"
            contentLabel="Example Modal"
        >
            <ModalReportAdmin 
                data={currentClient}
                onClose={() => setModalReport(false)}
                onSaved={() => {
                    setViewDetails(false);
                    setShowInfoWindow({ status: false, client: null})
                    setCurrentClient('');
                }}
            />
        </Modal>
        </>
        
    )
};

export default AdminMapPage;