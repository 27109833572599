import React, { useContext, useEffect, useState} from "react";
import { Navigate } from 'react-router-dom';
import { AuthContext } from "../contexts/AuthContext";

import { getAuth, onAuthStateChanged } from "firebase/auth";

export const AdminRoute = ({ children, redirectTo }) => {
    const { isAdmin, isManager, setRole } = useContext(AuthContext);

    const auth = getAuth();  

    const [ loadingData, setLoadingData ] = useState(true);

    useEffect(() => {
        (async () => {
            onAuthStateChanged(auth, async (user) => {
                if (user) {        
                    const profile = (await user.getIdTokenResult()).claims;

                    if(profile.root == true){
                        setRole('root');
                    }
                
                    if(profile.manager == true){
                        setRole('manager');
                    }

                    if(profile.admin == true){
                        setRole('admin');
                    }

                    setLoadingData(false);
                }else{
                    setLoadingData(false);
                }
            });
    
            
        })();
       
    });

    if(loadingData){
        return console.log("Carregando...");
    }
    
    return isAdmin || isManager ? (
        children
    ) : (
        <Navigate to={redirectTo} replace/>
    )
}