import styled from "styled-components";
import logo from '../../assets/logoCentral.svg';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 292px;
    /* width: 20%; */
    min-height: 200vh;
    left: 0px;
    top: 0px;
    bottom: 0px;

    background: var(--red);
`;

export const ImageContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 136px;
    /* left: -20px; */
    top: -6px;
    bottom: 0;
    left: 0;
    /* 5 */

    background: #FFFFFF;
    cursor: pointer;
`;

export const Image = styled.img.attrs({
        src: `${logo}`
    })`
    position: absolute;
    width: 83.36px;
    height: 91.3px;
    left: 84px;
    top: 16px;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 22px;

    position: absolute;
    width: 100%;
    height: 561px;
    left: 10px;
    top: 155px;

    /* width: 100%;
    height: 100%; */

    background: #DE6161;
    border-radius: 0px;
`;

export const Button = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items: center;
    align-content: center;
    width: 80%;
    height: 46px;
    padding-left: 10px;
    gap: 10px;

    background: ${props => props.selected ? 'rgba(0, 0, 0, 0.2)' : 'transparent'} ;
    border-radius: 15px;

    cursor: pointer;
    user-select: none;

    color: var(--white);

    flex: none;
    order: 0;
    flex-grow: 0;
`;

export const Frame = styled.div`
    width: 25px;
    height: 18px;

    opacity: 0.6;
`;

export const Text = styled.text`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;

    color: #FFFFFF;
`;

export const LogButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 80%;
`;