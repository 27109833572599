import { doc, setDoc, updateDoc, arrayUnion } from "firebase/firestore";
import db from '../../config';
import { datetime } from "../../../helpers/datetime";

export async function addMapLogs(mapId, data){

    const dateNow = new Date();

    const docName = `${dateNow.getDate()}-${dateNow.getMonth()+1}-${dateNow.getFullYear()}`;
    
    const mapRef = doc(db, `mapsLogs/${mapId}/logs/`, docName);

    const stringDate = datetime.getFullDate(dateNow.getTime());

    const log = `${stringDate} -> ${data}`

    await updateDoc(mapRef, {
        logs: arrayUnion(log),
        time: dateNow.getTime(),
    }).then(() => {
        
    }).catch(async (error) => {
        
        if(error.code == 'not-found'){
            await setDoc(mapRef, {
                logs: [log],
                time: dateNow.getTime(),
            })
        }else{
            await setDoc(mapRef, {
                logs: arrayUnion(`Erro ao salvar log: ${error.code}`)
            })
        }
    })
}