import React, { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { ReportsContext } from '../../contexts/ReportsContext';
import { RoutesContext } from '../../contexts/RoutesContext';
import ModalHeader from '../ModalHeader';

import { 
    Container, 
    CardsContainer, 
    ReturnCard, 
    ReturnCardHeader, 
    Divider, 
    ReturnCardNumber, 
    ReturnsList, 
    ReturnsListHeader, 
    ReturnsListContent, 
    ListItem, 
    ListClientName, 
    ListEspecie, 
    ListDate, 
    ListOperator, 
    ListOptions, 
    DividerHeader
} from './styles';

import pendingIcon from '../../assets/pending.png';
import inRouteIcon from '../../assets/inRoute.png';
import expiredIcon from '../../assets/expired.png';
import { AiOutlineEye } from 'react-icons/ai'

import Avatar from '@mui/material/Avatar';
import CustomLoading from '../CustomLoading';
import CustomFilterSimple from '../CustomFilterSimple';

const especies = [
    'LOAS DEF',
    'LOAS IDOSO',
    'AUX. DOENÇA'
]

function ModalReturnsOperator({ reports, onClose, onClickView }) {

    const { userData } = useContext(AuthContext);
    const { reportsUser } = useContext(ReportsContext);
    const { routesUser } = useContext(RoutesContext);

    const [ loadingData, setLoadingData ] = useState(true);

    const [ pendingReturns, setPendingReturns ] = useState('');
    const [ expiredReturns, setExpiredReturns ] = useState('');
    const [ inRouteReturns, setInRouteReturns ] = useState('');

    const [ reportsData, setReportsData ] = useState(''); 
    const [ reportsDataBackup, setReportsDataBackup ] = useState(''); 

    useEffect(() => {
        (async () => {
            setLoadingData(true);

            await processReturns();
        })();
    }, [reports]);

    const processReturns = async () => {
        const arrayPending = [];
        const arrayExpired = [];
        const arrayInRoute = [];

        if(routesUser){
            for(const route of routesUser){
                for(const routePoint of route.points){
                    if(routePoint.retornos > 0){
                        const report = reportsUser.find(x => x.clientCpf === routePoint.cpf);

                        if(report){
                            arrayInRoute.push({
                                ...report,
                                ...routePoint,
                                reportStatus: 'Em Rota'
                            })
                        }
                    }
                }
            }
        }
        
        for(const report of reports){
            if(report.atraso){
                arrayExpired.push({
                    ...report,
                    reportStatus: 'Expirado'
                });
            }else{
                arrayPending.push({
                    ...report,
                    reportStatus: 'Pendente'
                })
            }
        }

        setReportsData(arrayPending.concat(arrayExpired).concat(arrayInRoute));
        setReportsDataBackup(arrayPending.concat(arrayExpired).concat(arrayInRoute));

        setPendingReturns(arrayPending)
        setExpiredReturns(arrayExpired)
        setInRouteReturns(arrayInRoute);
        setLoadingData(false);
    }

    if(loadingData){
        return <Container>
            <CustomLoading background='transparent' />
        </Container>
    }

    return (
        <Container>
            <ModalHeader title='Retornos' onClose={onClose} />
            <CardsContainer>
                <ReturnCard onClick={() => {
                    if(reportsData == pendingReturns){
                        setReportsData(reportsDataBackup)
                    }else{
                        setReportsData(pendingReturns)
                    }
                }}>
                    <ReturnCardHeader>
                        <img src={pendingIcon} />
                        <h3>Retornos pendentes</h3>
                    </ReturnCardHeader>
                    <DividerHeader />
                    <ReturnCardNumber color='#F5C87B'>
                        {pendingReturns.length}
                    </ReturnCardNumber>
                </ReturnCard>

                <ReturnCard onClick={() => {
                    if(reportsData == inRouteReturns){
                        setReportsData(reportsDataBackup)
                    }else{
                        setReportsData(inRouteReturns)
                    }
                }}>
                    <ReturnCardHeader>
                        <img src={inRouteIcon} />
                        <h3>Retornos em rota</h3>
                    </ReturnCardHeader>
                    <DividerHeader />
                    <ReturnCardNumber color='#9BBEFF'>
                        {inRouteReturns.length}
                    </ReturnCardNumber>
                </ReturnCard>

                <ReturnCard onClick={() => {
                    if(reportsData == expiredReturns){
                        setReportsData(reportsDataBackup)
                    }else{
                        setReportsData(expiredReturns)
                    }
                }}>
                    <ReturnCardHeader>
                        <img src={expiredIcon} />
                        <h3>Retornos expirados</h3>
                    </ReturnCardHeader>
                    <DividerHeader />
                    <ReturnCardNumber color='#DE6161'>
                        {expiredReturns.length}
                    </ReturnCardNumber>
                </ReturnCard>
            </CardsContainer>
            <ReturnsList>
                <ReturnsListHeader>
                    <h3>Seus retornos</h3>
                    <CustomFilterSimple 
                        filterTypes={[
                            { headerName: 'Data', field: 'dataRetorno' }, 
                            { headerName: 'Status', field: 'reportStatus'}
                        ]}
                        data={reportsData}
                        tableRowsBackup={reportsDataBackup}
                        clearFilters={() => {
                            setReportsData(reportsDataBackup);
                        }}
                        onFilter={(arrayValues) => {
                            setReportsData(arrayValues)
                        }}
                        filterType='retornos'
                    />
                </ReturnsListHeader>
                <ReturnsListContent>
                    {reportsData.map((item, index) => {
                        var especieColor = '#DE6161'
                        if(item.especie == especies[0]){
                            especieColor = '#808B9F'
                        }
                        if(item.especie == especies[1]){
                            especieColor = '#9BBEFF'
                        }
                        return (
                            <>
                                <ListItem key={index}>
                                    <ListClientName>
                                        {item.clientName}
                                    </ListClientName>
                                    <ListEspecie color={especieColor}>
                                        <h4>
                                            {item.especie}
                                        </h4>
                                    </ListEspecie>
                                    <ListDate>
                                        {item.dataRetorno || item.horaRetorno 
                                            ? `${item.dataRetorno} - ${item.horaRetorno}`
                                            : 'Sem data e hora'
                                        }
                                    </ListDate>
                                    <ListOperator>
                                        <Avatar 
                                            src={`data:image;base64,${userData.img64}`}
                                            sx={{ marginRight: 1, height: 24, width: 24}}
                                        />
                                        <h4>
                                            {item.operatorName}
                                        </h4>
                                    </ListOperator>
                                    <ListOptions>
                                        <AiOutlineEye 
                                        onClick={() => onClickView(item)} 
                                        cursor='pointer' 
                                        size={20} 
                                        color='#808B9F' 
                                        fill='#808B9F' 
                                    />
                                    </ListOptions>
                                </ListItem>
                                <Divider />
                            </>
                        )   
                    })}
                </ReturnsListContent>
            </ReturnsList>
        </Container>
    )
}

export default ModalReturnsOperator;