import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    /* height: 400px; */
    width: 100%;

    padding: 0 20px;
    align-items: center;
    /* justify-content: center; */

    color: var(--blue4);
    user-select: none;
`;

export const Header = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;

    align-items: center;
`;

export const WeekHeader = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 14.29%);
    width: 100%;

    margin: 15px 0px 10px 0px;
    
    h4 {
        text-align: center;
    }
`;

export const Divider = styled.div`
    width: 100%;
    height: 0px;

    border: 0.001px solid #808B9F;
`;

export const DaysContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 14.29%);

    row-gap: 10px;
    align-items: center;
    justify-content: center;

    width: 100%;
`;

export const DayItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    height: 35px;
    cursor: pointer;

    background-color: ${props => props.background ? props.background : 'white'};
    color: ${props => props.current ? 'var(--red)' : props.selectable 
            ? 'var(--blue4)' : 'var(--grey1)'};
`;

export const DayItemSelected = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    border-top-left-radius: ${props => props.type == 'start' ? '100px' : '0px'};
    border-bottom-left-radius: ${props => props.type == 'start'  ? '100px' : '0px'};

    border-top-right-radius: ${props => props.type == 'end'  ? '100px' : '0px'};
    border-bottom-right-radius: ${props => props.type == 'end'  ? '100px' : '0px'};

    height: 35px;
    cursor: pointer;

    background-color: ${props => props.background ? props.background : 'white'};

    h4 {
        text-align: center;
    }
`;

export const Selected = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;

    padding: 5px;
    
    background: var(--red);
    border-radius: 100px;

    color: var(--white);
`;

export const RowButtons = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: space-around;
    width: 100%;
`;

export const TextClearFilters = styled.div`
    cursor: pointer;
    user-select: none;
`;

export const ConfirmButton = styled.div`
    display: flex;

    padding: 8px 30px;

    color: var(--white);
    user-select: none;
    cursor: pointer;

    background: #DE6161;
    box-shadow: 0px 7px 10px rgba(228, 73, 73, 0.25);
    border-radius: 100px;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        color: #FFFFFF;
    }
`;
