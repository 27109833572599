import React, { useState, useContext, useEffect } from 'react';

import { 
    Container,
    Title,
    OperatorsList,
    HeaderList,
    ListContainer,
    OperatorItem,
    OperatorsInfo,
    HeaderInfo,
    InfoItem,
    Circle,
    InfoItemHeader,
    InfoItemDescription
} from './styles';

//Firebase and context
import { AuthContext } from '../../contexts/AuthContext';
import { UsersContext } from '../../contexts/UsersContext';

//Icons
import centralLogo from '../../assets/centralLogo.png';

import { FiUsers, FiRefreshCcw } from 'react-icons/fi';
import { TbRoute } from 'react-icons/tb';

//MUI
import { Avatar } from '@mui/material';
import CustomLoading from '../CustomLoading';

const options = [
    "Contrato fechado",
    "Contrato fechado por indicação",
    "Endereço não encontrado",
    "Litispendência",
    "Já tem advogado",
    "Retornar",
    "Não tem direito",
    "Não tem interesse",
    "Localidade diversa",
]

function OperatorsCardDashboard({ data, onFilter, unFilter }) {

    const { users } = useContext(UsersContext); 

    const [ loadingData, setLoadingData ] = useState(true);
    const [ selected, setSelected ] = useState(0);
    const [ arrayData, setArrayData ] = useState('');

    useEffect(() => {
        (async () => {
            setLoadingData(true);
            if(data){
                const array = [];
                var arrayUsers = [];

                arrayUsers = users?.operators.filter(x => x.disabled != true);

                array.push({
                    userName: 'Geral',
                    img64: null,
                    daysWithRoutes: data.daysWithRoutes.length,
                    visitedClients: data.visitedClients,
                    returns: {
                        total: data.returns.realized.concat(data.returns.expired, data.returns.pending),
                        realized: data.returns.realized,
                        expired: data.returns.expired,
                        pending: data.returns.pending,
                    },
                })

                for(const user of arrayUsers){
                    var totalDays = 0;

                    for(const day of data.daysWithRoutes){
                        const result = day.routes.find(x => x.uid === user.uid);

                        if(result){
                            totalDays = totalDays + 1
                        }
                    }
                    const findVisitedClients = data.visitedClients.filter(x => x.operatorUid === user.uid);
                    const findRealizedReturns = data.returns.realized.filter(x => x.operatorUid === user.uid);
                    const findExpiredReturns = data.returns.expired.filter(x => x.operatorUid === user.uid);
                    const findPendingReturns = data.returns.pending.filter(x => x.operatorUid === user.uid);
                    
                    array.push({
                        ...user,
                        daysWithRoutes: totalDays,
                        visitedClients: findVisitedClients,
                        returns: {
                            total: findRealizedReturns.concat(findPendingReturns, findExpiredReturns),
                            realized: findRealizedReturns,
                            expired: findExpiredReturns,
                            pending: findPendingReturns,
                        },
                    })
                }
                
                setArrayData(array);
                setLoadingData(false)
            }else{
                // if(!returnsAdmin){
                //     await getReturnsAdmin();
                // }
                // if(!routesAdmin){
                //     await getRoutesAdmin();
                // }
            }
        })();
    }, [data, users]);

    const handleSelect = (newValue) => {
        if(newValue == selected){
            unFilter()
            setSelected(0)
        }else{
            setSelected(newValue)
            proccessToFilter(newValue)
        }
        
    }
 
    const proccessToFilter = (newValue) => {
        const aux = arrayData[newValue];

        const dataToFilter = aux.visitedClients;
        onFilter(dataToFilter);
    }

    const getReportsType = (data) => {
        const array = []

        for(const option of options){
            const reports = data.visitedClients.filter(x => x.result == option);

            if(reports.length > 0){
                array.push({
                    field: option,
                    qtd: reports.length
                }) 
            }
                      
        }
        return array
    }

    if(loadingData){
        return (
            <Container>
                <CustomLoading />
            </Container>
        )
    }

    return (
        <Container>
            <OperatorsList>
                <Title>
                    <FiUsers />
                    <h3>Operadores</h3>
                </Title>
                <HeaderList>
                    <h3>Operador</h3>
                    <h3>Dias com rotas</h3>
                    <h3>Clientes visitados</h3>
                    <h3>Retornos</h3>
                </HeaderList>
                <ListContainer>
                    {arrayData.map((item, index) => {

                        if(item.userName != 'Geral'){
                            return (
                                <OperatorItem key={index} active={selected == index ? true : false} onClick={() => handleSelect(index)}>
                                    <div>
                                        <Avatar
                                            alt="Avatar"
                                            src={item.img64  ? 
                                                `data:image;base64,${item.img64}`
                                                : centralLogo
                                            }
                                            sx={{ width: 30, height: 30, borderRadius: 100, borderColor: 'white', borderWidth: 20 }}
                                        />
                                        <h4>{item.userName}</h4>
                                    </div>
                                    <div>
                                        <h4>{item.daysWithRoutes}</h4>
                                    </div>
                                    <div>
                                        <h4>{item.visitedClients.length}</h4>
                                    </div>
                                    <div>
                                        <h4>{item.returns.total.length}</h4>
                                    </div>
                                </OperatorItem>
                            )
                        }
                    })}
                </ListContainer>
            </OperatorsList>
            {arrayData.length > 0 && (
                <OperatorsInfo>
                    <HeaderInfo>
                        <Avatar
                            alt="Avatar"
                            src={arrayData[selected].img64 ? 
                                `data:image;base64,${arrayData[selected].img64}`
                                : centralLogo
                            }
                            sx={{ 
                                marginTop: -5, 
                                width: 100, 
                                height: 100, 
                                borderRadius: 100, 
                                borderColor: 'white', 
                                borderWidth: 10 
                            }}
                        />
                        <h3>{arrayData[selected].userName}</h3>
                    </HeaderInfo>
                    <InfoItem>
                        <InfoItemHeader color='var(--success)'>
                            <div>
                                <Circle color='var(--success)'>
                                    <TbRoute />
                                </Circle>
                                <h3>Dias com rotas</h3>
                            </div>
                            <h1>{arrayData[selected].daysWithRoutes}</h1>
                        </InfoItemHeader>
                    </InfoItem>
                    <InfoItem onClick={() => {
                        onFilter(arrayData[selected].visitedClients)
                    }}>
                        <InfoItemHeader color='var(--yellow)' >
                            <div>
                                <Circle color='var(--yellow)'>
                                    <FiUsers />
                                </Circle>
                                <h3>Clientes visitados</h3>
                            </div>
                            <h1>{arrayData[selected].visitedClients.length}</h1>
                        </InfoItemHeader>
                        <InfoItemDescription>
                            {getReportsType(arrayData[selected]).map((item, index) => {
                                if(index == getReportsType(arrayData[selected]).length -1){
                                    return <h3>{item.field}: <span>{item.qtd}</span></h3>
                                }else{
                                    return <h3>{item.field}: <span>{item.qtd}</span> /</h3>
                                }
                                
                            })}
                        </InfoItemDescription>
                    </InfoItem>
                    <InfoItem onClick={() => {
                        onFilter(arrayData[selected].returns.total)
                    }}>
                        <InfoItemHeader color='var(--blue)'>
                            <div>
                                <Circle color='var(--blue)'>
                                    <FiRefreshCcw />
                                </Circle>
                                <h3>Retornos</h3>
                            </div>
                            <h1>{arrayData[selected].returns.total.length}</h1>
                        </InfoItemHeader>
                        <InfoItemDescription>
                            <h3>Realizados: <span>{arrayData[selected].returns.realized.length}</span> / Pendentes: <span>
                                {arrayData[selected].returns.pending.length}</span> / Expirados: <span>
                                {arrayData[selected].returns.expired.length}</span>
                            </h3>
                        </InfoItemDescription>
                    </InfoItem>
                </OperatorsInfo>
            )}
        </Container>
    )
}

export default OperatorsCardDashboard;