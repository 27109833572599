import React, { useState, createContext } from "react";

import { getAuth } from "firebase/auth";
import db from '../firebase/config';
import { collection, onSnapshot, query } from "firebase/firestore";

export const DatabaseContext = createContext();

export const DatabaseProvider = (props) => {
    const { children } = props;

    const auth = getAuth();
    
    const [ clientsDb, setClientsDb ] = useState('');

    const clearDatabaseContext = async () => {
        setClientsDb('');
        return
    }

    //Get clientes do Banco de dados
    const getClientsDb = async () => {        
        const consulta = query(collection(db, 'clientes'));
        await onSnapshot(consulta, (querySnapshot) => {
            const database = [];
            
            const data = querySnapshot.docs;
            
            for(const i in data){
                const client = data[i].data();

                database.push({...client, docId: data[i].id});
            }
            
            setClientsDb(database);
        });
    }

    return (
        <DatabaseContext.Provider 
            value={{ 
                clientsDb: clientsDb,
                getClientsDb,
                clearDatabaseContext
            }}
        >
            {children}
        </DatabaseContext.Provider>
    )
}