import React from 'react';

import { Container, Text } from './styles';

const PointIcon = ({ text, textColor, backgroundColor, size, fontSize, fontWeight}) => {
    return (
        <Container 
            backgroundColor={backgroundColor} 
            size={size} 
        >
            <Text 
                textColor={textColor} 
                fontSize={fontSize}
                fontWeight={fontWeight}
            >
                {text}
            </Text>
        </Container>
    )
}

export default PointIcon;