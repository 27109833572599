import React, { useContext, useState } from 'react';

import { Container, ButtonFilter, TextClearFilters } from './styles';

//Firebase and Context
import { UsersContext } from '../../contexts/UsersContext';

//icons
import { IoMdArrowDropdown } from 'react-icons/io';
import { BsEraser } from 'react-icons/bs';

//Custom components
import DateFilter from '../DateFilter';

//Utils
import Select from 'react-select'

//Mui components
import Menu from '@mui/material/Menu';


const monthNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

const CustomFilterLogs = ({ filterTypes, data, tableRowsBackup, clearFilters, onFilter, filterType, 
    defaultTitle, dateField, filterOptions }) => {

    const { users } = useContext(UsersContext);

    const [ operatorsOptions, setOperatorsOptions ] = useState([]);

    const [ filteredItems, setFilteredItems ] = useState({});
    const [ selectedField, setSelectedField ] = useState('');

    const [ filterCalendar, setFilterCalendar ] = useState(false);
    const [ calendarValues, setCalendarValues ] = useState('');

    const [ arrayDateFilter, setArrayDateFilter ] = useState('');

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const clearAllFilters = () => {
        // setFilterOptions([]);
        setFilteredItems({});
        setSelectedField('');
        setArrayDateFilter('')
        setCalendarValues('');
        clearFilters()
    }

    const proccessOperatorOptions = (field) => {
        const array = [];

        for(const user of users?.allUsers){
            array.push({
                label: user.userName,
                value: user.userName
            })
        }
        const ordenatedArray = array.sort(function(a,b) {
            return a.label > b.label ? 1 : a.label < b.label ? -1 : 0;
        });
        setOperatorsOptions(ordenatedArray);
    }

    const handleMenuClose = (field) => {
        if(filterCalendar && arrayDateFilter){
            setSelectedField('');
            setAnchorEl(null);
            newFilterTable();
        }else{
            setSelectedField('');
            setAnchorEl(null);
            // filterTable(null);
            newFilterTable();
        }
    }

    const handleMenuOpen = (event, headerName) => {
        if(headerName == 'Data'){
            setFilterCalendar(true);
        }else{
            setFilterCalendar(false)
        }

        setSelectedField(event.currentTarget.id)
        setAnchorEl(event.currentTarget);
    };

    const onChangeFilter = async (newValue, field) => {
        if(newValue){
            const obj = {
                ...filteredItems,
                [field]: newValue,
            }
            setFilteredItems(obj);
        }
        
        if(newValue.length == 0){

            const array = [];

            const keys = Object.keys(filteredItems);
            var obj = {};
            for(const key of keys){
                if(key != field){
                    obj[key] = filteredItems[key];
                }
            }
          
            setFilteredItems(obj);
            newFilterTable();
        }
    }

    const newFilterTable = () => {
        var dataToFilter = data

        if(arrayDateFilter){
            dataToFilter = arrayDateFilter
        }

        const arrayLogs = [];

            const keys = Object.keys(filteredItems);

            if(keys.length == 0){
                if(arrayDateFilter){
                    return onFilter(arrayDateFilter)
                }else{
                    return clearFilters();
                }
            }

            for(const key of keys){
                for(const log of dataToFilter){
                    const newLogs = []
                    
                    if(log.logs){
                        for(const text of log.logs){
                            for(const item of filteredItems[key]){
                                if(text.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(
                                    item.value.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase())
                                    || item.value.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase().includes(
                                        text.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toUpperCase()))
                                {
                                    newLogs.push(text)
                                }
                            }
                            
                        }
        
                        arrayLogs.push({
                            ...log,
                            logs: newLogs
                        })
                    }
                    
                }

                onFilter(arrayLogs)
            }

       
    }

    const getFilterDescription = () => {

        if(!calendarValues.selectedEnd.day){
            return `${calendarValues.selectedStart.day} de ${
                    monthNames[calendarValues.selectedStart.month-1]} de ${
                    calendarValues.selectedStart.year
                }`
        }

        if(calendarValues.selectedStart.year == calendarValues.selectedEnd.year){
            if(calendarValues.selectedStart.month == calendarValues.selectedEnd.month){
                return `${calendarValues.selectedStart.day} a ${
                        calendarValues.selectedEnd.day} de ${
                        monthNames[calendarValues.selectedEnd.month-1]} de ${
                        calendarValues.selectedEnd.year
                    }`
            }else{
                return `${calendarValues.selectedStart.day} de ${
                            monthNames[calendarValues.selectedStart.month-1]} a ${
                            calendarValues.selectedEnd.day} de ${
                            monthNames[calendarValues.selectedEnd.month-1]} de ${
                            calendarValues.selectedEnd.year
                        }`
            }
        }else{
            return `${calendarValues.selectedStart.day} de ${
                    monthNames[calendarValues.selectedStart.month-1]} de ${
                    calendarValues.selectedStart.year} a ${
                    calendarValues.selectedEnd.day} de ${
                    monthNames[calendarValues.selectedEnd.month-1]} de ${
                    calendarValues.selectedEnd.year
                }`
        }
    }

    const buttonHandle = (label, field) => {
        if(filteredItems[field]){
            if(filteredItems[field].length > 1){
                return `${filteredItems[field][0].value} +${filteredItems[field].length -1}`
            }else{
                return `${filteredItems[field][0].value}`
            }
        }else{
            if(label == 'Data' && calendarValues){
                // return `${calendarValues[0]} a ${calendarValues.slice(0)[-1]}`
                return getFilterDescription()
            }else{
                return `Filtrar ${label}`
            }
        }
    }

    const customStyle = {      
        container: (base) => ({
            ...base,
            borderRadius: 40
        }),
        control: (base) => ({
            ...base,
            padding: 3,
            borderRadius: 40
        }),
        multiValue: (base) => ({
            ...base,
            borderRadius: 40
        }),
    }  

    return <Container>
            {filterTypes.map((item, index) => (
                <ButtonFilter key={index} id={item.field} onClick={(event) => handleMenuOpen(event, item.headerName)}>
                    <h4>{!calendarValues && defaultTitle ? defaultTitle : buttonHandle(item.headerName, item.field)}</h4>
                    <IoMdArrowDropdown style={{ cursor: 'pointer'}} size={18} color='#808B9F' fill='#808B9F' />
                </ButtonFilter>
            ))}

            <TextClearFilters onClick={() => clearAllFilters()}>
                <BsEraser style={{ cursor: 'pointer', marginRight: -5 }} />
                Limpar
            </TextClearFilters>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                style={{  zIndex: 100000, }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                PaperProps={{  
                    style: {  
                        width: 400,
                        height: 400,
                        padding: 10,
                        borderRadius: 15,
                    },  
                }} 
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {filterCalendar
                    ?<DateFilter 
                        data={data} 
                        tableRowsBackup={tableRowsBackup} 
                        defaultValues={calendarValues}
                        onFilterDate={(newData, values) => {
                            setArrayDateFilter(newData)
                            setCalendarValues(values);
                        }} 
                        clearDateFilter={() => {
                            setArrayDateFilter('')
                            setCalendarValues('');
                        }}
                        filterType={filterType}
                        dateField={dateField}
                        onClose={handleMenuClose}
                    />
                    :<Select 
                        isClearable={false} 
                        isSearchable={true} 
                        defaultValue={selectedField ? filteredItems[selectedField] : null}
                        closeMenuOnSelect={false} 
                        options={selectedField == 'operator' ? operatorsOptions : filterOptions} 
                        isMulti={true} 
                        styles={customStyle}
                        autoFocus={true}
                        openMenuOnFocus={true}
                        onMenuOpen={() => {
                            proccessOperatorOptions(selectedField)
                        }}
                        onChange={(item) => onChangeFilter(item, selectedField)}
                    />
                }
                
            </Menu>
    </Container>
}

export default CustomFilterLogs;