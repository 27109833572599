import React from 'react';

import { Container } from './styles';

import { BiDownload } from 'react-icons/bi';

//utils
import { datetime } from '../../helpers/datetime';

function DownloadButton({ tableColumns, tableRows }) {

    const csvMaker = function (data) {
        const csvRows = [];
        const header = [];
        const fields = [];

        for(const column of tableColumns){
            if(column.field != 'actions'){
                header.push(column.headerName);
                fields.push(column.field);
            }
        }
        csvRows.push(header.join(';'));
        
        for(const item of data){
            var obj = {};

            for(const field of fields){
                const result = tableColumns.find(x => x.field == field);

                if(result && result.headerName == 'Data'){
                    obj[field] = datetime.getFullDate(item[field]);
                }else{
                    obj[field] = item[field];
                }
            }

            const values = Object.values(obj).join(';');

            csvRows.push(values)
        }

        return csvRows.join('\n')
    }

    const downloadCsv = function (data) {
        var BOM = "\uFEFF";
        var    csvData = BOM + data;
        const blob = new Blob([csvData], { type: 'application/octet-binary' });
        const url = window.URL.createObjectURL(blob)
    
        const a = document.createElement('a')
        a.setAttribute('href', url)

        a.setAttribute('download', 'download.csv');
        a.click()
    }

    return (
        <Container onClick={() => {
            const csvdata = csvMaker(tableRows);
            downloadCsv(csvdata);
        }}>
            <BiDownload style={{ cursor: 'pointer' }} size={18} color='#FFF'  fill='#FFF' />
        </Container>
    )
}

export default DownloadButton;