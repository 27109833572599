import React, { useState, useEffect } from "react";
import { Navigate } from 'react-router-dom';

import { getAuth, onAuthStateChanged } from "firebase/auth";

export const PrivateRoute = ({ children, redirectTo }) => {

    const auth = getAuth();  

    const [ loadingData, setLoadingData ] = useState(true);
    const [ isLogged, setIsLogged ] = useState(false)

    useEffect(() => {
        (async () => {
            onAuthStateChanged(auth, async (user) => {
                if (user) {        
                    setIsLogged(true);
                    setLoadingData(false);
                }else{
                    setIsLogged(false)
                    setLoadingData(false);
                }
            });
    
            
        })();
    });

    if(loadingData){
        return console.log("Carregando...");
    }

    return isLogged == true ? (
        children
    ) : (
        <Navigate to={redirectTo} replace/>
    )
}