import React, { useState, useContext, useEffect } from 'react'
import { 
    ActionsContent,
    CancelButton,
    Divider,
    FieldText,
    Container,
    SaveButton,
    TextInput,
} from './styles';

//Firebase and Context
import { AuthContext } from '../../contexts/AuthContext';
import { doc, updateDoc, collection, addDoc } from "firebase/firestore";
import db from '../../firebase/config';

//Custom Components
import { LoadingButton } from '../../Pages/ManageUsersPage/styles';
import ModalHeader from '../ModalHeader';
import ModalConfirm from '../ModalConfirm';

//Utils
import { toast } from 'react-toastify'
import Modal from 'react-modal';
import DotLoader from "react-spinners/ClipLoader";
import Select from 'react-select';
import { alertSuccessStyle } from '../../resources/alertSuccessStyle';
import { alertErrorStyle } from '../../resources/alertErrorStyle';
import { addClientLogs } from '../../firebase/logs/clients/addClientLogs';
import { MapsContext } from '../../contexts/MapsContext';

const selectOptions = [
    {
        label: "Contrato fechado",
        value: "Contrato fechado",
    },
    {
        label: "Localidade diversa",
        value: "Localidade diversa",
    },
    {
        label: "Endereço não encontrado",
        value: "Endereço não encontrado",
    },
    {
        label: "Já tem advogado",
        value: "Já tem advogado",
    },
    {
        label: "Não tem direito",
        value: "Não tem direito",
    },
    {
        label: "Não tem interesse",
        value: "Não tem interesse",
    },
]

const ModalReportAdmin = ({ data, onClose, onSaved }) => {

    const { user, userData } = useContext(AuthContext);
    const { removeFromRoute } = useContext(MapsContext);

    const [ selectedStatus, setSelectedStatus ] = useState("Contrato fechado");
    const [ description, setDescription ] = useState('');
    const [ loading, setLoading ] = useState(false);

    const [ modalConfirm, setModalConfirm ] = useState(false);
    const [ modalData, setModalData ] = useState('');

    const saveReport = async () => {
        setLoading(true);

        const dataInclusao = new Date(data.time);
        const date = new Date();

        if(selectedStatus != selectOptions[1].value && selectedStatus != selectOptions[2].value){
            await removeFromRoute(data)
        }

        const report = {
            operatorUid: user.uid,
            operatorName: userData.userName,
            clientCpf: data.cpf,
            clientName: data.nome,
            result: selectedStatus,
            description: description,
            routeDocId: 'off-route',
            dataRetorno: '',
            horaRetorno: '', 
            especie: data.especie,
            bairro: data[`bairro${data.validEnd}`],
            cidade: data[`cidade${data.validEnd}`],
            estado: data[`estado${data.validEnd}`],
            validEnd: data.validEnd,
            retornos: data.retornos, 
            tempoMapa: (date.getTime() - dataInclusao.getTime()),
            time: date.getTime(),
        };

        const docAdd = await addDoc(collection(db, "reports"), report);
        
        const docRef = doc(db, "clientes", data.cpf);
        await updateDoc(docRef, {
            selected: selectedStatus == selectOptions[1].value || selectedStatus == selectOptions[2].value ? "" : "done",
            closeDate: date.getTime(),
            reportId: docAdd.id,
            operatorUid: user.uid,
            differentLocation: selectedStatus == selectOptions[1].value ? true : false,
            endNotFoundOperator: selectedStatus == selectOptions[2].value ? true : false,
            retornos: data.retornos,
        }).then(async () => {
            const logMessage = `${data.nome} foi atendido pelo ${userData.userName} com o resultado de ${selectedStatus} (Web)`

            await addClientLogs(data.cpf, logMessage)

            toast("Atendimento salvo com sucesso", alertSuccessStyle)
            onSaved();
        }).catch(async (error) => {
            const logMessage = `${data.nome} falha ao salvar o atendimento feito pelo ${
                userData.userName} com o resultado de ${selectedStatus}: ${error.code} (Web)`

            await addClientLogs(data.cpf, logMessage)

            toast(`Erro ao salvar atendimento ${error.code}`, alertErrorStyle)
        })
        setLoading(false);
        onClose()
    }

    const customStyle = {      
        container: (base) => ({
            ...base,
            borderRadius: 40,
            color: 'var(--blue4)'
        }),
        control: (base) => ({
            ...base,
            padding: 3,
            borderRadius: 40,
            color: 'var(--blue4)'
        }),
        multiValue: (base) => ({
            ...base,
            borderRadius: 40
        }),
        singleValue: provided => ({
            ...provided,
            color: 'var(--blue4)'
        })
    } 

    const onChangeFilter = async (newValue) => {
        setSelectedStatus(newValue.value)
    }
    
    return (
        <Container>
            <ModalHeader title='Gerar atendimento' onClose={onClose} />
            <br />
            <FieldText>
                Resultado
            </FieldText>
            <Select 
                isClearable={false} 
                isSearchable={true} 
                defaultValue={{label: selectedStatus, value: selectedStatus}}
                closeMenuOnSelect={true} 
                options={selectOptions} 
                isMulti={false} 
                styles={customStyle}
                autoFocus={true}
                openMenuOnFocus={true}
                onChange={(item) => onChangeFilter(item)}
            />
            <br />
            <FieldText>
                Descrição
            </FieldText>
            <TextInput>
                <input 
                    name="description" 
                    onChange={event => setDescription(event.target.value)}
                    placeholder='Opcional'
                />
            </TextInput>

            <Divider />

            {loading
                ?<LoadingButton>
                    <DotLoader
                        size={20}
                        color={"var(--red)"}
                        loading={loading}
                        speedMultiplier={1.5}
                    />
                </LoadingButton> 
                : <ActionsContent>
                    <CancelButton onClick={onClose}>
                        <text>Cancelar</text>
                    </CancelButton>
                    <SaveButton onClick={() => {
                        setModalData({
                            text: 'Tem certeza que deseja salvar o atendimento?',
                            option: 'Sim'
                        })
                        setModalConfirm(true)
                    }}>
                        <text>Salvar</text>
                    </SaveButton>
                </ActionsContent>
            }
            <Modal
                isOpen={modalConfirm}
                onRequestClose={() => setModalConfirm(false)}
                overlayClassName="modal-overlay"
                className="modal-content-alert"
                contentLabel="Example Modal"
            >
                <ModalConfirm 
                    data={modalData} 
                    onCancel={() => {
                        setModalConfirm(false);
                    }}
                    onConfirm={async () => {
                        await saveReport();
                    }}
                />
            </Modal>
        </Container>
    )
}

export default ModalReportAdmin