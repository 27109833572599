import React from 'react';

//Styles
import { Container, RoutePointItemLeft, RoutePointItemInfo, PointDot, IconContainer } from './styles';

//Icons
import PointIcon from '../PointIcon';

//utils
import { address } from '../../helpers/address';
import { markers } from '../../helpers/markers';

const letras = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

const SavedRoutePoint = ({ letter, clientName, clientEnd, especie }) => {

    return <Container>
            <RoutePointItemLeft>
                <PointIcon text={letras[letter]} size={18} />

                <PointDot />
                <PointDot />
                <PointDot />
                <PointDot />
                <PointDot />
                <PointDot />
            </RoutePointItemLeft>
            <IconContainer>
                <img style={{ width: 15 }} src={markers.getMarkerIconsEspecie(especie)} alt='icon'/>
            </IconContainer>
            <RoutePointItemInfo>
                <h4>{clientName}</h4>
                <text>{address.formatEnd(clientEnd)}</text>
            </RoutePointItemInfo>
    </Container>
}

export default SavedRoutePoint;