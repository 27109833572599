import React, { useState, useEffect } from 'react';

import { 
    ModalLogsContent,
    LogsContainer,
    LogItem,
    Circle,
    Line,
    Container
} from '../ModalLogs/styles';

import CustomLoading from '../CustomLoading';
import CustomNoRowsOverlay from '../CustomNoRowsOverlay';
import ModalHeader from '../ModalHeader';
import { getRouteLogs } from '../../firebase/logs/routes/getRouteLogs';

const ModalLogsRoutes = ({ routeName, routeDocId, onClose }) => {

    const [ routeLogs, setRouteLogs ] = useState('');
    const [ routeLogsFormated, setRouteLogsFormated ] = useState('');
    const [ order, setOrder ] = useState('crescente');

    const [ loadingLogs, setLoadingLogs ] = useState(true);

    useEffect(() => {
        (async () => {
            const logs = await getRouteLogs(routeDocId);

            setRouteLogs(logs);
        })();
    }, [])

    useEffect(() => {
        (async () => {
            if(routeLogs){
                if(routeLogs.length != 0){
                    setRouteLogsFormated(routeLogs.slice(0).reverse())
                }else{
                    setRouteLogsFormated([]);
                }
                setLoadingLogs(false)
            }
        })();
    }, [routeLogs, order]);

    const handleChangeToggleButton = (event, newOrder) => {
        setOrder(newOrder);
    };
    
    return (
        <Container>
            <ModalHeader title={`Logs da rota ${routeName}`} onClose={onClose} />
            <hr />
            <ModalLogsContent>
                {loadingLogs == true
                    ? <CustomLoading />
                    : <>
                        {routeLogsFormated.length != 0 
                            ?<LogsContainer>
                                {routeLogsFormated.map((item, index) => (
                                    <LogItem key={index}>
                                       <div>
                                            <Circle />
                                            <Line />
                                       </div>
                                       <h3>{item}</h3>
                                    </LogItem>
                                ))}
                            </LogsContainer> 
                            : <CustomNoRowsOverlay />
                        }
                        
                    </>
                }
            </ModalLogsContent>
        </Container>
    );
}

export default ModalLogsRoutes;