import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    gap: 10px;
`;

export const ProgressBar = styled.div`
    display: flex;
    /* background: ${props => props.progress == 100 ? `${props.background}` : 'var(--background)'}; */
    background: ${props => props.barBackground};
    border-radius: 100px;
    height: 6px;
    width: 100px;

    div {
        width: ${props => props.progress ? `${props.progress + 5}%` : '0%'};
        height: 100%;
        background: ${props => props.background};
        border-radius: 100px;
    }
`;

export const ProgressText = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    color: #808B9F;
`;
