import React, { useState } from 'react';

import { Container, Content, HelpText, Item, NewEndBar, RowItem, SaveNewEndButton } from './styles';

function NewAddressCard({ client, onSave }) {

    const [newAddress, setNewAddress] = useState({
        logradouro: client[`logradouro${client.validEnd}`],
        numero: client[`numero${client.validEnd}`],
        complemento: client[`complemento${client.validEnd}`],
        bairro: client[`bairro${client.validEnd}`],
        cidade: client[`cidade${client.validEnd}`],
        estado: client[`estado${client.validEnd}`],
        cep: client[`cep${client.validEnd}`],
    })

    const handleInputChange = (field, value) => {
        setNewAddress({
            ...newAddress,
            [field]: value
        })
    }

    const handleSave = () => {
        if(newAddress.cidade && newAddress.estado){
            onSave(newAddress)
        }
    }

    return (
        <Container>
            <Content>
                <RowItem>
                    <Item>
                        <HelpText>Logradouro</HelpText>
                        <NewEndBar>
                            <input
                                id="logradouro"
                                onChange={event => handleInputChange(event.target.id, event.target.value)}
                                value={newAddress.logradouro}
                                placeholder='Logradouro'
                            />
                        </NewEndBar>
                    </Item>
                    <Item>
                        <HelpText>Número</HelpText>
                        <NewEndBar>
                            <input
                                id="numero"
                                onChange={event => handleInputChange(event.target.id, event.target.value)}
                                value={newAddress.numero}
                                placeholder='Número'
                            />
                        </NewEndBar>
                    </Item>
                    <Item>
                        <HelpText>Complemento</HelpText>
                        <NewEndBar>
                            <input
                                id="complemento"
                                onChange={event => handleInputChange(event.target.id, event.target.value)}
                                value={newAddress.complemento}
                                placeholder='Complemento'
                            />
                        </NewEndBar>
                    </Item>
                </RowItem>

                <RowItem>

                    <Item>
                        <HelpText>Bairro</HelpText>
                        <NewEndBar>
                            <input
                                id="bairro"
                                onChange={event => handleInputChange(event.target.id, event.target.value)}
                                value={newAddress.bairro}
                                placeholder='Bairro'
                            />
                        </NewEndBar>
                    </Item>
                    <Item>
                        <HelpText>Cidade</HelpText>
                        <NewEndBar>
                            <input
                                id="cidade"
                                onChange={event => handleInputChange(event.target.id, event.target.value)}
                                value={newAddress.cidade}
                                placeholder='Cidade'
                            />
                        </NewEndBar>
                    </Item>
                    <Item>
                        <HelpText>Estado</HelpText>
                        <NewEndBar>
                            <input
                                id="estado"
                                onChange={event => handleInputChange(event.target.id, event.target.value)}
                                value={newAddress.estado}
                                placeholder='Estado'
                            />
                        </NewEndBar>
                    </Item>
                    <Item>
                        <HelpText>CEP</HelpText>
                        <NewEndBar>
                            <input
                                id="cep"
                                onChange={event => handleInputChange(event.target.id, event.target.value)}
                                value={newAddress.cep}
                                placeholder='CEP'
                            />
                        </NewEndBar>
                    </Item>
                </RowItem>
            </Content>
            <SaveNewEndButton 
                disabled={!newAddress.cidade || !newAddress.estado} 
                onClick={() => handleSave()}
            >
                Salvar
            </SaveNewEndButton>
        </Container>
    )
}

export default NewAddressCard;