import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    max-width: 600px;
`;

export const ClientName = styled.div`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    
    margin: 20px 0;
    /* 1 */

    color: #4A5E83;
`;

export const AddressItem = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;

    width: 90%;

    gap: 20px;

    margin: 20px 0;

    span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 15px;

        /* 1 */

        color: ${props => props.color}
    }

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 15px;

        /* 1 */

        color: ${props => props.color}
    }
`;

export const ActionButtons = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    justify-content: right;

    gap: 30px;
    padding: 20px 10px;
`;

export const CancelButton = styled.div`
     display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    padding: 10px;

    text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        color: var(--grey2);
    }
`;


export const SaveButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    padding: 12px 58px 12px 58px;

    background: #DE6161;
    box-shadow: 0px 7px 10px rgba(228, 73, 73, 0.25);
    border-radius: 100px;

    text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        text-align: center;

        /* 5 */

        color: var(--white);

    }
`;
