import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const RoutePointItemLeft = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    gap: 2px;
    width: 10%;
`;

export const IconContainer = styled.div`
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    height: 100%;

    width: 8%;

    color: #626F87;
    fill: #626F87;
`;

export const RoutePointItemInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    gap: 5px;
    width: 80%;
    height: 100%;

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        /* 1 */

        color: var(--blue3);
    }

    text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
        /* identical to box height */
        max-lines: 2;
        text-overflow: clip;
        /* 1 */

        color: var(--blue3);
    }
`;

export const RoutePointItemRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;

    height: 100%;
    width: 10%;
    color: var(--red);
`;

export const PointDot = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5px;
    width: 0.7px;

    border-radius: 2px;
    background: var(--red);

    text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 9px;

        /* 5 */

        color: var(--white);
    }
`; 

