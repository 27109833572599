import styled from "styled-components";

export const Container = styled.div`
    height: 100vh;
    width: 100%;
    background-color: var(--background);
    display: flex;

    /* display: grid;
    grid-template-columns: 2fr 1fr; */
`;

//Left Content

export const RouteBar = styled.div`
    display: flex;
    flex-direction: column;
    width: 432px;
    height: 100vh;

    padding: 20px 33px;

    background: var(--background);
    box-shadow: 4px -2px 10px rgba(99, 99, 99, 0.15);
    z-index: 10;
`;

export const RouteBarHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    height: 77px;
    width: 100%;
`;

export const LogoContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: left;
`;

export const HeaderLeft = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
`;

export const HeaderTitle = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
    color: var(--blue3);
    fill: var(--blue3);

    text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 28px;
        line-height: 38px;
        /* identical to box height */

        color: var(--blue3);
    }
`;

export const NewRouteButton = styled.div`
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;

    color: var(--blue3);
    fill: var(--blue3);

    cursor: pointer;

    text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;

        color: var(--blue3);
    }
`;

export const RouteBarContent = styled.div`
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
`;

//Saved Routes
export const SavedRoutesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 369px;
    height: 100%;
    padding: 15px;
    border-radius: 20px;

    gap: 20px;
`;

//New and Edit Route

export const NewRouteContainer = styled.div`
    display: flex;
    flex-direction: column;
    background: var(--white);
    width: 369px;
    height: 100%;
    padding: 15px;
    border-radius: 20px;
`;

export const FieldText = styled.text`
    height: 24px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 24px;

    margin-bottom: 5px;
    margin-left: 5px;

    color: #4A5E83;
`;

export const TextInput = styled.div`
    box-sizing: border-box;
    display: flex;

    width: 312px;
    height: 44px;
    align-items: center;

    border: 1px solid var(--grey1);
    background: transparent;
    border-radius: 100px;
`;

export const TextSection = styled.text`
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    padding: 20px 0px;

    /* 1 */

    color: var(--blue3);
`;

export const RoutePointsContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 80%;
    overflow-y: auto;
`;

export const Divider = styled.div`
    border: 1px solid #ECF2FB;
    align-self: center;
    width: 80%;
`;

export const DividerReturn = styled.div`
    margin: 20px 0;
    opacity: 0.5;

    border: 1px solid #B4C1D5;
    align-self: center;
    width: 100%;
`;

export const DividerReturnItem = styled.div`
    margin: 5px 0;
    opacity: 0.5;

    border: 1px solid #B4C1D5;
    align-self: center;
    width: 100%;
`;

export const ActionsContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: bottom;
    justify-content: right;
    gap: 10px;
    padding: 18px 10px;
`;

export const CancelButton = styled.div`
     display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    padding: 10px;

    text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        color: var(--grey2);
    }
`;


export const SaveButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    padding: 12px 58px 12px 58px;

    background: #DE6161;
    box-shadow: 0px 7px 10px rgba(228, 73, 73, 0.25);
    border-radius: 100px;

    text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        text-align: center;

        /* 5 */

        color: var(--white);

    }
`;

export const NoRoutesContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 80%;
    align-items: center;
    justify-content: center;

    gap: 30px;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;

        /* 2 */

        color: #808B9F;
    }
`;

export const NoRouteButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    padding: 10px 41px;
    gap: 5px;

    background: var(--red);
    box-shadow: 0px 7px 10px rgba(228, 73, 73, 0.25);
    border-radius: 100px;

    color: var(--white);
    fill: var(--white);

    text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        text-align: center;

        /* 5 */

        color: var(--white);

    }
`;

//Map
export const MapContainer = styled.div`
    width: 100%;
    height: 100%;
`;

export const CustomInfoWindow = styled.div`
    display: flex;
    flex-direction: column;
    width: 252px;

    padding: 5px 10px;

    background: var(--white);
    border-radius: 10px;

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        /* 1 */

        color: var(--blue4);
    }
`;

export const InfoWindowTextContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
`;

export const InfoWindowButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
`;

export const AddRouteButton = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    padding: 7px 17px;
    gap: 5px;

    background: var(--red);
    box-shadow: 0px 7px 10px rgba(228, 73, 73, 0.25);
    border-radius: 100px;

    color: var(--white);
    fill: var(--white);

    text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        text-align: center;

        /* 5 */

        color: var(--white);

    }
`;

export const GenerateReportButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    margin: 10px 0 10px 0;
    cursor: pointer;

    text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;

        color: var(--red);
    }
`;

export const MapDivRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    border: 1px solid transparent;
    /* background-color: rgba(255, 255, 255, 255); */
    background: 'transparent';
    width: 300px;
    /* height: 200px; */
    padding: 12px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.3);
    position: absolute;
    left: 68%;
    margin-top: 10px;
`;

export const ReturnMapDiv = styled.div`
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 278px;
    left: 20px;
    top: 25px;

    padding: 20px;
    /* 5 */

    background-color: #fff;
    /* shadowcard */

    box-shadow: 0px 4px 20px rgba(35, 54, 63, 0.05);
    border-radius: 10px;
`;

export const ReturnMapDivHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    color: #808B9F;

    text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        /* or 114% */
        max-width: 50%;

        /* 2 */

        color: #808B9F;
    }
`;

export const ReturnsContainer = styled.div`
    display: flex;
    flex-direction: column;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;

        color: #4A5E83;
    }
`;

export const ReturnsContent = styled.div`
    display: flex;
    flex-direction: column;

    padding-right: 10px;

    max-height: calc(100vh - 150px);
    overflow-y: auto;
`;

export const ReturnCard = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 0 0 10px;

    cursor: pointer;

    gap: 5px;

    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h3 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            /* identical to box height, or 133% */


            /* 1 */

            color: #4A5E83;
        }

        text {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            /* identical to box height, or 133% */


            /* 2 */

            color: #808B9F;
        }
    }

    .description {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        /* identical to box height, or 133% */


        /* 2 */

        color: #808B9F;
    }
`;

export const ReturnMapButton = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 51px;

    padding: 14px 19px;
    gap: 10px;

    background: var(--white);
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    cursor: pointer;

    text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        /* identical to box height */


        color: #4D6289;
    }
`;

export const HelpButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    color: var(--blue4);
    position: absolute;
    top: 90%;
    left: 95%;
`;

export const MarkersLegend = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 5px;
    box-sizing: border-box;
    border: 1px solid transparent;
    background-color: rgba(255, 255, 255, 255);
    /* width: 300px; */
    /* height: 200px; */
    padding: 12px 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.3);
    position: absolute;
    bottom: 5%;
    right: 2%;
    margin-top: 10px;
`;

export const LegendItem = styled.div`
    display: flex;
    align-items: center;
    column-gap: 20px;

    padding-bottom: 5px;

    border-bottom: 1px solid #ECF2FB;

    h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 38px;
        /* identical to box height */

        color: var(--blue3);
    }
`;