import { doc, getDoc } from "firebase/firestore";
import db from '../../config';

export async function getRouteLogs(routeDocId){

    const logDataRef = doc(db, "rotasLogs", routeDocId);
    const logData = await getDoc(logDataRef).catch((error) => {
        alert("Erro ao realizar leitura do log: ", error.code);
    })

    if(logData.exists()){
        return logData.data().logs
    }else{
        return []
    }
}