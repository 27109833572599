import React, { useState, createContext, useEffect } from "react";

import { toast } from 'react-toastify';

import { collection, query, onSnapshot, where } from "firebase/firestore";
import db from '../firebase/config';
import { arrayHelper } from "../helpers/arrayHelper";
import { alertSuccessStyle } from "../resources/alertSuccessStyle";

export const ReportsContext = createContext();

const date = new Date();

let numberContracts = 0;

export const ReportsProvider = (props) => {
    const { children } = props;

    const [ reports, setReports ] = useState('');
    const [ reportsUser, setReportsUser ] = useState('');

    const clearReportsContext = async () => {
        setReportsUser('')
        setReports('');
        return
    }

    useEffect(() => {
        checkNewContracts();
    }, [])

    const getReportsUser = async (user) => {
        const consulta = query(collection(db, 'reports'), 
            where('operatorUid', '==', user.uid), where('result', '==', 'Retornar'));
        await onSnapshot(consulta, (querySnapshot) => {
            const database = [];
            
            const data = querySnapshot.docs;
            
            for(const i in data){
                database.push({...data[i].data(), docId: data[i].id});
            }
            
            setReportsUser(arrayHelper.ordenateArrayDesc(database, 'time'));
        });
    }

    //Get atendimentos realizados
    const getReportsAdmin = async () => {
        const consulta = query(collection(db, 'reports'));
        await onSnapshot(consulta, (querySnapshot) => {
            const database = [];
            
            const data = querySnapshot.docs;
            
            for(const i in data){
                database.push({...data[i].data(), docId: data[i].id});
            }
            
            setReports(arrayHelper.ordenateArrayDesc(database, 'time'));
        });
    }

    const getReportsManager = async (operatorsArray) => {
        
        if(operatorsArray.length == 0){
            return setReports([]);
        }

        const operatorsUid = arrayHelper.reduceToSimple(operatorsArray, 'uid');

        const consulta = query(collection(db, 'reports'), where("operatorUid", "in", operatorsUid));
        await onSnapshot(consulta, (querySnapshot) => {
            const database = [];
            
            const data = querySnapshot.docs;
            
            for(const i in data){
                database.push({...data[i].data(), docId: data[i].id});
            }
            
            setReports(arrayHelper.ordenateArrayDesc(database, 'time'));
        }, error => {
            console.log("getReportsManager error: ", error)
        });
    }

    //Notificações web de novos contratos
    const checkNewContracts = async () => {
        const timeToCompare = new Date(`${date.getMonth()+1}-1-${date.getFullYear()}`)
        const consulta = query(collection(db, 'reports'), 
            where("result", "in", ["Contrato fechado", "Contrato fechado por indicação"]));
        await onSnapshot(consulta, (querySnapshot) => {
            const database = [];
            
            const data = querySnapshot.docs;
            
            for(const i in data){
                database.push(data[i].data());
            }
            
            if(numberContracts != 0 && database.length > numberContracts){
                toast("Novo contrato fechado", alertSuccessStyle);
            }

            numberContracts = database.length
        });
    }

    return (
        <ReportsContext.Provider 
            value={{ 
                reports: reports,
                reportsUser: reportsUser,
                getReportsUser,
                getReportsAdmin,
                getReportsManager,
                clearReportsContext,
            }}
        >
            {children}
        </ReportsContext.Provider>
    )
}