import React, { useEffect, useRef, useState } from 'react';

import { Container, ButtonFilter, MoreFilters, ModalFilterItem, ModalFilterContainer, TextClearFilters } from './styles';

//icons
import { IoMdArrowDropdown, IoIosAddCircleOutline } from 'react-icons/io';
import { BsEraser } from 'react-icons/bs';

//Custom components
import ModalHeader from '../ModalHeader';
import DateFilter from '../DateFilter';

//Utils
import Select from 'react-select'
import Modal from 'react-modal';

//MUI
import Menu from '@mui/material/Menu';

const CustomFilter = ({ filterTypes, data, tableRowsBackup, clearFilters, onFilter, 
    clearAll, filterType, filtersHighlights, dateField, tableDefaultFilters }) => {

    const [ filterOptions, setFilterOptions ] = useState([]);
    const [ filteredItems, setFilteredItems ] = useState({});
    const [ modalMoreFilters, setModalMoreFilters ] = useState(false);
    const [ selectedField, setSelectedField ] = useState('');

    //Date filter
    const [ filterCalendar, setFilterCalendar ] = useState(false);
    const [ calendarValues, setCalendarValues ] = useState('');
    const [ arrayDateFilter, setArrayDateFilter ] = useState('');

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        setFilterOptions([]);
        setFilteredItems({});
        setSelectedField('');
        setArrayDateFilter('')
        setCalendarValues('');
        clearFilters(tableRowsBackup, {})
    }, [clearAll]);

    useEffect(() => {
        if(tableDefaultFilters){
            filterTable(tableDefaultFilters, tableRowsBackup)
            setFilteredItems(tableDefaultFilters)
        }
    }, [tableDefaultFilters])

    const clearAllFilters = () => {
        setFilterOptions([]);
        setFilteredItems({});
        setSelectedField('');
        setArrayDateFilter('')
        setCalendarValues('');
        clearFilters(tableRowsBackup, {})
    }

    const processFilterOptions = (field) => {
        const array = [];

        const keys = Object.keys(filteredItems);

        if(keys.length == 1 && keys[0] == field){
            for(const item of tableRowsBackup){
                const result = array.find(x => x.value === item[field]);
    
                if(!result){
                    array.push({ label: item[field], value: item[field] })
                }
                
            }
        }else{
            for(const item of data){
                const result = array.find(x => x.value === item[field]);
    
                if(!result){
                    array.push({ label: item[field], value: item[field] })
                }
                
            }
        }
        const ordenatedArray = array.sort(function(a,b) {
            return a.label > b.label ? 1 : a.label < b.label ? -1 : 0;
        });
        setFilterOptions(ordenatedArray);
    }

    const handleMenuClose = (field) => {
        // if(filterCalendar && arrayDateFilter){
        if(arrayDateFilter){
            setSelectedField('');
            setAnchorEl(null);
            // onFilter(arrayDateFilter)
            filterTable(filteredItems, arrayDateFilter)
        }else{
            setSelectedField('');
            setAnchorEl(null);
            filterTable(null, arrayDateFilter);
        }
    }

    const handleMenuOpen = (event, headerName) => {
        if(headerName == 'Data'){
            setFilterCalendar(true);
        }else{
            setFilterCalendar(false)
        }

        setSelectedField(event.currentTarget.id)
        setAnchorEl(event.currentTarget);
    };

    const onChangeFilter = async (newValue, field) => {
        if(newValue){
            const obj = {
                ...filteredItems,
                [field]: newValue,
            }
            setFilteredItems(obj);
        }
        
        if(newValue.length == 0){

            const array = [];

            const keys = Object.keys(filteredItems);
            var obj = {};
            for(const key of keys){
                if(key != field){
                    obj[key] = filteredItems[key];
                }
            }
          
            setFilteredItems(obj);
            filterTable(obj, arrayDateFilter);

            for(const item of tableRowsBackup){
                const result = array.find(x => x.value === item[field]);

                if(!result){
                    array.push({ label: item[field], value: item[field] })
                }
                
            }
            setFilterOptions(array);
        }
    }

    const filterTable = (filters, dataToFilter) => {

        var values = filteredItems;
        var data = tableRowsBackup;
        
        if(filters){
            values = filters;
        }

        if(dataToFilter){
            data = dataToFilter;
        }

        const keys = Object.keys(values);

        var filterCount = 0;
        var arrayValues = [];

        if(keys.length == 0){
            if(dataToFilter){
                if(calendarValues){
                    return onFilter(dataToFilter, {
                        ...values,
                        data: calendarValues
                    })  
                }else{
                    return onFilter(dataToFilter, values) 
                }
            }else{
                clearFilters(tableRowsBackup, values)
                return setFilterOptions([])
            }
        }

        for(const key of keys){
            if(values[key] != null){
                if(filterCount == 0){    
                    for(const item of values[key]){
                        const result = data.filter(x => x[key] === item.value);
    
                        if(result){
                            const newArray = arrayValues.concat(result);
                            arrayValues = newArray;
                        }  
                    }
                }else{
                    if(values[key].length > 1){
                        var arrayAux = [];
                        for(const item of values[key]){
                            

                            const result = arrayValues.filter(x => x[key] === item.value);
        
                            if(result){
                                arrayAux = arrayAux.concat(result);
                            }else{
                                alert('não encontrado dentro')
                            }
                        }
                        arrayValues = arrayAux;
                    }else{
                        for(const item of values[key]){
                            const result = arrayValues.filter(x => x[key] === item.value);
        
                            if(result){
                                arrayValues = result;
                            }else{
                                alert('não encontrado dentro')
                            }
                        }
                    }
                }
                filterCount++
            }            
        }

        if(arrayValues.length != 0){
            if(calendarValues){
                onFilter(arrayValues, {
                    ...values,
                    data: calendarValues
                })  
            }else{
                onFilter(arrayValues, values) 
            }
              
        }else{
            // alert("Não encontrado");
            if(calendarValues){
                onFilter(arrayValues, {
                    ...values,
                    data: calendarValues
                })  
            }else{
                onFilter(arrayValues, values) 
            }
            // onFilter([]);
        }

        if(filters){
            const array = [];

            for(const item of arrayValues){
                const result = array.find(x => x.value === item[selectedField]);

                if(!result){
                    array.push({ label: item[selectedField], value: item[selectedField] })
                }
                
            }
            setFilterOptions(array);
        }
        
    }

    const buttonHandle = (label, field) => {
        if(filteredItems[field]){
            if(filteredItems[field].length > 1){
                return `${filteredItems[field][0].value} +${filteredItems[field].length -1}`
            }else{
                return `${filteredItems[field][0].value}`
            }
        }else{
            return `${label}`
        }
    }

    const getNumberMoreFilters = () => {
        const keys = Object.keys(filteredItems);
        var count = 0;

        for(const key of keys){
            const result = filtersHighlights.find(x => x === key);

            if(!result){
                count++
            }
        }

        if(count != 0){
            return `(${count})`;
        }else{
            return '';
        }
    }

    const customStyle = {      
        container: (base) => ({
            ...base,
            borderRadius: 40
        }),
        control: (base) => ({
            ...base,
            padding: 3,
            borderRadius: 40
        }),
        multiValue: (base) => ({
            ...base,
            borderRadius: 40
        }),
    }  

    return <Container>
            {filtersHighlights.map((item, index) => (
                    <ButtonFilter 
                        key={index} 
                        id={item.field} 
                        onClick={(event) => handleMenuOpen(event, item.headerName)}
                    >
                        <h4>{buttonHandle(item.headerName, item.field)}</h4>
                        <IoMdArrowDropdown style={{ cursor: 'pointer'}} size={18} color='#808B9F' fill='#808B9F' />
                    </ButtonFilter>
            ))}

            <MoreFilters onClick={() => setModalMoreFilters(true)}>
                <IoIosAddCircleOutline style={{ cursor: 'pointer', marginRight: -5 }} size={15} color='#DE6161'  fill='#DE6161' />
                <h5>Mais </h5>
            </MoreFilters>

            <TextClearFilters onClick={() => clearAllFilters()}>
                <BsEraser style={{ cursor: 'pointer', marginRight: -5 }} />
                Limpar {getNumberMoreFilters()}
            </TextClearFilters>

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                PaperProps={{  
                    style: {  
                        width: 400,
                        height: 400,
                        padding: 10,
                        borderRadius: 15
                    },  
                }} 
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {filterCalendar
                    ?<DateFilter 
                        data={data} 
                        tableRowsBackup={tableRowsBackup} 
                        defaultValues={calendarValues}
                        onFilterDate={(newData, values) => {
                            if(Object.keys(filteredItems).length > 0){
                                setArrayDateFilter(newData)
                                setCalendarValues(values);
                            }else{
                                setArrayDateFilter(newData)
                                setCalendarValues(values);
                            }
                        }} 
                        clearDateFilter={() => {
                            setArrayDateFilter('')
                            setCalendarValues('');
                        }}
                        filterType={filterType}
                        dateField={dateField}
                        onClose={handleMenuClose}
                    />
                    :<Select 
                        isClearable={false} 
                        isSearchable={true} 
                        defaultValue={selectedField ? filteredItems[selectedField] : null}
                        closeMenuOnSelect={false} 
                        options={filterOptions} 
                        isMulti={true} 
                        styles={customStyle}
                        autoFocus={true}
                        openMenuOnFocus={true}
                        onMenuOpen={() => processFilterOptions(selectedField)}
                        onChange={(item) => onChangeFilter(item, selectedField)}
                    />
                }
            </Menu>

            <Modal
                isOpen={modalMoreFilters}
                onRequestClose={() => {
                    filterTable(null);
                    setModalMoreFilters(false)
                }}
                overlayClassName="modal-overlay"
                className="modal-content"
                contentLabel="Example Modal"
                style={{overlay: {
                    overflowY:"scroll"
                }}}
            >
                <ModalFilterContainer>
                    <ModalHeader title='Filtros' onClose={() => {
                        filterTable(null);
                        setModalMoreFilters(false)
                    }} />
                    {filterTypes.map((item, index) => {
                        if(item.headerName != "Data" && item.headerName != 'Ações'){
                            return (
                                <ModalFilterItem key={`filter-${index}`}>
                                    <h4>{item.headerName}</h4>
                                    <Select 
                                        key={`filter-${index}`}
                                        isClearable={false} 
                                        isSearchable={true} 
                                        defaultValue={filteredItems[item.field]  ? filteredItems[item.field] : null}
                                        closeMenuOnSelect={false} 
                                        options={filterOptions} 
                                        isMulti={true} 
                                        styles={customStyle}
                                        onMenuOpen={() => {
                                            setSelectedField(item.field)
                                            processFilterOptions(item.field)
                                        }}
                                        onChange={(text) => {
                                            onChangeFilter(text, item.field)
                                        }}
                                    />
                                </ModalFilterItem>
                            )
                        }
                    })}
                </ModalFilterContainer>
            </Modal>
    </Container>
}

export default CustomFilter;