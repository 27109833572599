import { combineComponents } from '../helpers/combineComponents';

import { AuthProvider } from './AuthContext';
import { ClientsProvider } from './ClientsContext';
import { DatabaseProvider } from './DatabaseContext';
import { MapsProvider } from './MapsContext';
import { ReportsProvider } from './ReportsContext';
import { ReturnsProvider } from './ReturnsContext';
import { RoutesProvider } from './RoutesContext';
import { UsersProvider } from './UsersContext';

const providers = [
  AuthProvider,
  ReportsProvider,
  ReturnsProvider,
  RoutesProvider,
  UsersProvider,
  MapsProvider,
  ClientsProvider,
  DatabaseProvider
]
export const AppContextProvider = combineComponents(...providers);