import React, { useState, useEffect } from 'react';

import { 
    ModalLogsContent,
    LogsContainer,
    Container,
    LogItem,
    Circle,
    Line,
    Header
} from '../ModalLogs/styles';

import { CancelButton, SearchBar, SearchButton, SearchContainer } from '../CustomTable/styles';

//Icons
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { FiSearch } from 'react-icons/fi';

//Custom components
import CustomLoading from '../CustomLoading';
import CustomNoRowsOverlay from '../CustomNoRowsOverlay';
import CustomFilterLogs from '../CustomFilterLogs';

//MUI
import Menu from '@mui/material/Menu';
import { getUserLogs } from '../../firebase/logs/users/getUserLogs';
import { searchHelper } from '../../helpers/searchHelper';


const ModalLogsUser = ({ userName, userId, onClose }) => {
    const [ userLogs, setUserLogs ] = useState([]);
    const [ userLogsFormated, setUserLogsFormated ] = useState('');

    const [ userLogsBackup, setUserLogsBackup ] = useState('');

    const [ loadingLogs, setLoadingLogs ] = useState(true);

    const [ search, setSearch ] = useState('');
    const [ searchBar, setSearchBar ] = useState(null);
    const openSearch = Boolean(searchBar);

    useEffect(() => {
        (async () => {
            const logs = await getUserLogs(userId);    

            setUserLogs(logs)
        })();
    }, []);

    useEffect(() => {
        if(userLogs){
            if(userLogs.length != 0){
                var arrayLogs = [];

                for(const log of userLogs){
                    arrayLogs = arrayLogs.concat(log.logs.reverse())
                }

                setUserLogsFormated(arrayLogs);
                setUserLogsBackup(userLogs);
            }else{
                setUserLogsFormated([]);
                setUserLogsBackup([]);
            }
            setLoadingLogs(false)
        }
    }, [userLogs])

    useEffect(() => {
        if(!search){
            clearSearch()
        }
    }, [search])

    const onFilterData = (arrayValues) => {
        var arrayLogs = [];

        for(const log of arrayValues){
            arrayLogs = arrayLogs.concat(log.logs)
        }
        setUserLogsFormated(arrayLogs)
    }

    const clearSearch = async () => {
        setSearch('')
        const logs = await getUserLogs(userId);    

        setUserLogs(logs)
    }

    const searchTable = () => {
        if(search){
            const arrayLogs = [];

            for(const log of userLogsFormated){
                if(searchHelper.searchText(log, search)){
                    arrayLogs.push(log)
                }
            }
            
            setUserLogsFormated(arrayLogs)
        }
    }
    
    return (
        <Container>
            <Header>
                <div>
                    <h3>{`Logs do usuário ${userName}`}</h3>
                    <FiSearch 
                        onClick={(event) => {
                            setSearchBar(event.currentTarget);
                        }}
                        size={20} 
                        cursor='pointer' 
                        color='var(--grey2)' 
                    />
                </div>
                <div>
                    <CustomFilterLogs
                        data={userLogs}
                        filterOptions={[ 
                            { label: 'Entrou no sistema', value: 'acessou' },
                            { label: 'Saiu do sistema', value: 'saiu' },
                            { label: 'Visualizou as informações', value: 'visualizou' },
                            { label: 'Atendimentos', value: 'atendeu' },
                            { label: 'Erros', value: 'erro' },
                        ]}
                        onFilter={(arrayValues) => {
                            onFilterData(arrayValues)
                        }}
                        dateField='time'
                        filterType='atendimentos'
                        filterTypes={[
                            { headerName: 'Data', field: 'time' },
                            { headerName: 'Ação', field: 'text' },
                        ]}
                        tableRowsBackup={userLogsBackup}
                        clearFilters={() => {
                            var arrayLogs = [];

                            for(const log of userLogs){
                                arrayLogs = arrayLogs.concat(log.logs)
                            }
                            setUserLogsFormated(arrayLogs);
                        }}
                       
                    />
                    <AiOutlineCloseCircle 
                        style={{ marginLeft: 10 }} 
                        onClick={() => onClose()} 
                        strokeWidth={0.5} 
                        cursor='pointer' 
                        size={22} 
                    />
                </div>
            </Header>
            <hr />
            <ModalLogsContent>
                {loadingLogs == true
                    ? <CustomLoading />
                    : <>
                        {userLogsFormated.length != 0 
                            ?<LogsContainer>
                                {userLogsFormated.map((item, index) => (
                                    <LogItem key={index}>
                                       <div>
                                            <Circle />
                                            <Line />
                                       </div>
                                       <h3>{item}</h3>
                                    </LogItem>
                                ))}
                            </LogsContainer> 
                            : <CustomNoRowsOverlay />
                        }
                        
                    </>
                }
            </ModalLogsContent>

            <Menu
                id="basic-menu"
                anchorEl={searchBar}
                open={openSearch}
                onClose={() => {
                    setSearch('');
                    setSearchBar(null);
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                PaperProps={{
                    style: {
                        width: 600,
                        height: 40,
                        maxHeight: 40,
                        borderRadius: 20,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        overflow: 'hidden',
                    },
                }}
            >
                <SearchContainer onSubmit={(e) => { 
                    e.preventDefault();
                    searchTable()
                }}>
                    <FiSearch 
                        size={18} 
                        cursor='pointer' 
                        color='#626F87'
                        strokeWidth='3'
                    />
                     <text>&#8288;</text>
                    <SearchBar>
                        <input 
                            name="search" 
                            onChange={event => setSearch(event.target.value)}
                            value={search}
                            placeholder='Buscar...'
                            autoFocus={true}
                        />
                    </SearchBar>
                    <CancelButton onClick={() => {
                        setSearch('');
                        setSearchBar(null);
                    }}>
                        Cancelar
                    </CancelButton>
                    <SearchButton onClick={() => searchTable()}>
                        Buscar
                    </SearchButton>
                </SearchContainer>
            </Menu>
        </Container>
    );
}

export default ModalLogsUser;