import React, { useEffect, useState } from 'react';

import { Container, CloseButton } from './styles';

//APIS
import { GoogleMap, useJsApiLoader, StreetViewPanorama } from '@react-google-maps/api';

//Icons
import { AiOutlineCloseCircle } from 'react-icons/ai';

//Custom components
import CustomLoading from '../CustomLoading';

function ModalStreetView({ client, onClose }) {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    });

    const [ loadingData, setLoadingData ] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoadingData(false);
        }, 2000)
    }, [client])

    if(loadingData || !isLoaded){
        return (
            <CustomLoading />
        )
    }

    return (
        <Container>
            {isLoaded && loadingData == false && (
                <GoogleMap
                    mapContainerStyle={{
                        width: '100%', 
                        height: '100%',
                        borderRadius: 10
                    }}
                    center={{lat: -8.113334, lng: -35.059530}}
                    zoom={10}
                    clickableIcons={false}
                    options={{
                        disableDefaultUI: true
                    }}
                >
                    <StreetViewPanorama 
                        position={{lat: client.lat, lng: client.lng}} 
                        visible={true}
                        options={{
                            disableDefaultUI: true
                        }}
                    />
                    <CloseButton onClick={onClose}>
                        <AiOutlineCloseCircle size={35} />
                    </CloseButton>  
                </GoogleMap>
            )}
        </Container>
    )
}

export default ModalStreetView;