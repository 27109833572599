import React, { Component } from "react";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

//context API
import { AppContextProvider } from "./contexts/AppContextProvider";

//tools
import './App.css';

import './firebase/config';

import { createTheme, ThemeProvider} from '@mui/material/styles';
import { ptBR } from '@mui/material/locale';
import Router from "./routes";

const theme = createTheme({
  palette: {
    primary: {
      main: "#DE6161",
    },
    secondary: {
      main: "#000",
    },
    white: {
      main: "#fff",
    },
    red: {
      main: "#DE6161",
    }
  },
  ptBR
});

class App extends Component {
  
  render() {
    return  (
      <>
        <AppContextProvider>
          <ThemeProvider theme={theme}>
            <ToastContainer 
              progressStyle={{
                background: '#DE6161',
              }}
              style={{
                zIndex: 500000,
              }}
            />
              <Router />
          </ThemeProvider>
        </AppContextProvider>
      </>
    );
  }
  
}

export default App;
