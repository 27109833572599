import { doc, setDoc, updateDoc, arrayUnion } from "firebase/firestore";
import db from '../../config';
import { datetime } from "../../../helpers/datetime";

export async function addClientLogs(clientCpf, data){

    const dateNow = new Date();
    
    const clientRef = doc(db, "clientesLogs", clientCpf);

    const stringDate = datetime.getFullDate(dateNow.getTime());

    const log = `${stringDate} -> ${data}`

    await updateDoc(clientRef, {
        logs: arrayUnion(log)
    }).then(() => {
        
    }).catch(async (error) => {
        if(error.code == 'not-found'){
            await setDoc(clientRef, {
                logs: arrayUnion(log)
            })
        }else{
            await setDoc(clientRef, {
                logs: arrayUnion(`Erro ao salvar log: ${error.code}`)
            })
        }
    })
}