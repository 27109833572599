import React, { useState, useEffect } from 'react';

import { 
    ModalLogsContent,
    LogsContainer,
    LogItem,
    Circle,
    Line,
    Container,
    Header,
    NumberOfLines
} from '../ModalLogs/styles';

//Icons
import { AiOutlineCloseCircle } from 'react-icons/ai'

//Custom components
import CustomLoading from '../CustomLoading';
import CustomNoRowsOverlay from '../CustomNoRowsOverlay';
import CustomFilterLogs from '../CustomFilterLogs';

//MUI
import Typography from '@mui/material/Typography';
import { getMapLogs } from '../../firebase/logs/maps/getMapLogs';

const ModalLogsMaps = ({ mapName, mapId, onClose }) => {

    const [ mapLogs , setMapLogs ] = useState('');

    const [ mapLogsFormated, setMapLogsFormated ] = useState('');
    const [ mapLogsFormatedBackup, setMapLogsFormatedBackup ] = useState('');
    
    const [ loadingLogs, setLoadingLogs ] = useState(true);

    useEffect(() => {
        (async () => {
            const logs = await getMapLogs(mapId);    

            setMapLogs(logs)
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if(mapLogs){
                if(mapLogs.length != 0){
                    var arrayLogs = [];

                    for(const log of mapLogs){
                        arrayLogs = arrayLogs.concat(log.logs.reverse())
                    }
                    setMapLogsFormated(arrayLogs)
                    setMapLogsFormatedBackup(arrayLogs)
                    
                }else{
                    setMapLogsFormated([]);
                    setMapLogsFormatedBackup([]);
                }
                setLoadingLogs(false)
            }
        })();
    }, [mapLogs]);

    const onFilterData = (arrayValues) => {
        var arrayLogs = [];

        for(const log of arrayValues){
            arrayLogs = arrayLogs.concat(log.logs)
        }
        setMapLogsFormated(arrayLogs)
    }

    const getNumberOfClientes = () => {
        // if()

        const array = [];
        var count = 0;

        for(const item of mapLogsFormated){
            if(item.includes('clientes')){
                // array.push(item)
                const text = item.split('clientes')

                const text2 = text[0].split(' ');
                
                array.push(text2.at(-2))
            }
        }

        for(const number of array){
            count = count + Number(number)
        }


        return (mapLogsFormated.length - array.length) + count
    }
    
    return (
        <Container>
            <Header>
                <h3>{`Logs do mapa ${mapName}`}</h3>
                <div>
                    <CustomFilterLogs
                        data={mapLogs}
                        filterOptions={[ 
                            { label: 'Importados', value: 'importados' },
                            { label: 'Excluídos', value: 'excluido' },
                        ]}
                        onFilter={(arrayValues) => {
                            onFilterData(arrayValues)
                        }}
                        dateField='time'
                        filterType='atendimentos'
                        filterTypes={[
                            { headerName: 'Data', field: 'time' },
                            { headerName: 'Ação', field: 'text' },
                        ]}
                        tableRowsBackup={mapLogsFormatedBackup}
                        clearFilters={() => {
                            setMapLogsFormated(mapLogsFormatedBackup)
                        }}
                       
                    />
                    <AiOutlineCloseCircle 
                        style={{ marginLeft: 10 }} 
                        onClick={onClose} 
                        strokeWidth={0.5} 
                        cursor='pointer' 
                        size={22} 
                    />
                </div>
            </Header>
            <hr />
            <ModalLogsContent>
                {loadingLogs == true
                    ? <CustomLoading />
                    : <>
                        {mapLogsFormated.length != 0 
                            ?<LogsContainer>
                                <Typography sx={{ width: '100%', flexShrink: 0, fontWeight: 'bold', color: '#9a4749'}}>
                                    {mapLogsFormated.map((item, index) => (
                                        <LogItem key={index}>
                                            <div>
                                                <Circle />
                                                <Line />
                                            </div>
                                            <h3>{item}</h3>
                                        </LogItem>
                                    ))}
                                </Typography>
                            </LogsContainer> 
                            : <CustomNoRowsOverlay />
                        }
                        
                    </>
                }
                <NumberOfLines>
                    {getNumberOfClientes()} clientes
                </NumberOfLines>
            </ModalLogsContent>
        </Container>
    );
}

export default ModalLogsMaps;