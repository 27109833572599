import React, { useEffect, useState } from 'react';

import { Container, Header, WeekHeader, Divider, DaysContainer, DayItem, Selected, DayItemSelected, TextClearFilters, RowButtons, ConfirmButton } from './styles';

import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';
import CustomLoading from '../CustomLoading';


//utils
import { datetime } from '../../helpers/datetime';

const dateNow = new Date();

const weekDays = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];

const monthNames = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

const empty = { day: '', month: '', year: ''};

function DateFilter({ data, defaultValues, tableRowsBackup, onFilterDate, clearDateFilter, filterType, dateField, onClose }) {

    const [ days, setDays ] = useState('');
    const [ currentMonth, setCurrentMonth ] = useState(dateNow.getMonth()+1);
    const [ currentYear, setCurrentYear ] = useState(dateNow.getFullYear());

    const [ selectedStart, setSelectedStart ] = useState(empty);
    const [ selectedEnd, setSelectedEnd ] = useState(empty);

    const [ newData, setNewData ] = useState('');

    useEffect(() => {
        if(defaultValues){
            setSelectedStart(defaultValues.selectedStart);
            setSelectedEnd(defaultValues.selectedEnd);
            setCurrentMonth(defaultValues.selectedStart.month)
            setCurrentYear(defaultValues.selectedStart.year)
        }
    }, [])

    useEffect(() => {
        onFilterDate(newData, { selectedStart: selectedStart, selectedEnd: selectedEnd });
    }, [newData])

    useEffect(() => {
        if(JSON.stringify(empty) !== JSON.stringify(selectedStart)){
            if(JSON.stringify(selectedEnd) !== JSON.stringify(empty)){
                filterDate(selectedStart, selectedEnd)
            }else{
                filterDate(selectedStart, selectedStart)
            }
        }else{
            clearDateFilter()
        }
    }, [selectedStart, selectedEnd])

    useEffect(() => {
        processDays(currentMonth, currentYear)
    }, [currentMonth]);

    const processDays = async (month, year) => {
        const days = [];
        var count = 0;
        const dayStart = new Date(`${month}/1/${year}`).getDay();
        const dayEnd = new Date(year, month, 0).getDate();

        for(var i = 1; i < (dayEnd + dayStart+1); i++){

            if(i >= dayStart+1 && count <= dayEnd-1){
                days.push(count+1);
                count++
            }else{
                days.push('');
            }
        }

        setDays(days)
    }

    const filterDate = async (dateStart, dateEnd) => {
        const startTime = new Date(`${dateStart.month}/${dateStart.day}/${dateStart.year}`).getTime();
        const endTime = new Date(`${dateEnd.month}/${dateEnd.day}/${dateEnd.year}`).getTime();

        const arrayValues = [];
        const newArray = [];
        

        for(var i = startTime; i <= endTime; i = i+(86400*1000)){
            const date = new Date(i);

            if(filterType == 'retornos'){
                const stringDate = `${date.getDate()}/${date.getMonth()+1}/${date.getFullYear()}`;

                arrayValues.push(stringDate)
            }

            if(filterType == 'atendimentos'){
                const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
                const month = date.getMonth()+1 < 10 ? `0${date.getMonth()+1}` : date.getMonth()+1;
                const year = date.getFullYear();

                const stringDate = `${day}/${month}/${year}`;

                arrayValues.push(stringDate)
            }
        }

        for(const date of arrayValues){

            if(data.length > 0 && !defaultValues){

                if(filterType == 'retornos'){
                    const result = data.find(x => x.dataRetorno === date);

                    if(result){
                        newArray.push(result)
                    }
                }

                if(filterType == 'atendimentos'){
                    for(const item of data){
                        if(item[dateField]){
                            if(datetime.getDate(item[dateField]) === date){
                                newArray.push(item)
                            }
                        }
                    }
                }
                
            }else{

                if(filterType == 'retornos'){
                    const result = tableRowsBackup.find(x => x.dataRetorno === date);

                    if(result){
                        newArray.push(result)
                    }
                }

                if(filterType == 'atendimentos'){
                    for(const item of tableRowsBackup){
                        if(item[dateField]){
                            if(datetime.getDate(item[dateField]) === date){
                                newArray.push(item)
                            }
                        }
                    }
                }
            }
            
        }

        if(newArray.length > 0){
            setNewData(newArray)
        }else{
            setNewData([])
        }
    }

    const verifyCurrentDay = (item) => {
        if(dateNow.getDate() == item && dateNow.getMonth()+1 == currentMonth 
            && dateNow.getFullYear() == dateNow.getFullYear())
        {
            return true
        }else{
            return false
        }
    }

    const verifySelectable = (item) => {
        if(currentYear < dateNow.getFullYear()){
            return true
        }
        
        if(currentMonth <= dateNow.getMonth()+1 && currentYear <= dateNow.getFullYear()){
            if(dateNow.getMonth()+1 == currentMonth){
                if(item <= dateNow.getDate()){
                    return true
                }
            }else{
                return true
            }
        }

        return false
    }
    
    if(!days){
        <Container>
            <CustomLoading />
        </Container>
    }

    return (
        <Container>
            <Header>
                <BsArrowLeft size={24} cursor='pointer' onClick={() => {
                    if(currentMonth-1 <= 0){
                        setCurrentYear(currentYear-1);
                        setCurrentMonth(12);
                    }else{
                        setCurrentMonth(currentMonth-1)
                    }
                }} />
                    <h3>{`${monthNames[currentMonth-1]} ${currentYear}`}</h3>
                <BsArrowRight size={24} cursor='pointer' onClick={() => {
                    if(currentMonth+1 > 12){
                        setCurrentYear(currentYear+1);
                        setCurrentMonth(1)
                    }else{
                        setCurrentMonth(currentMonth+1)
                    }
                }}/>
            </Header>
            <WeekHeader>
                {weekDays.map((item, index) => (
                    <h4 key={index}>{item}</h4>
                ))}
            </WeekHeader>
            <Divider />
            <DaysContainer>
                {days && days.map((item, index) => {
                    const itemDate = {
                        day: item,
                        month: currentMonth,
                        year: currentYear,
                    }

                    const itemTime = new Date(`${itemDate.month}/${itemDate.day}/${itemDate.year}`).getTime();
                    const selectedStartTime = new Date(`${selectedStart.month}/${selectedStart.day}/${selectedStart.year}`).getTime();
                    const selectedEndTime = new Date(`${selectedEnd.month}/${selectedEnd.day}/${selectedEnd.year}`).getTime();

                    const itemDateValues =  Object.values(itemDate);
                    const selectedStartValues = Object.values(selectedStart);
                    const selectedEndValues = Object.values(selectedEnd);

                    const emptyValues = Object.values(empty);
                    
                    if((JSON.stringify(itemDateValues) === JSON.stringify(selectedStartValues)) ||
                        (JSON.stringify(itemDateValues) === JSON.stringify(selectedEndValues))){

                        var type = '';
                        var background = 'white';

                        if(JSON.stringify(itemDateValues) === JSON.stringify(selectedStartValues)){
                            type = 'start'
                        }
                        if(JSON.stringify(itemDateValues) === JSON.stringify(selectedEndValues)){
                            type = 'end'
                        }

                        

                        if(JSON.stringify(selectedStartValues) !== JSON.stringify(emptyValues)){
                            if(JSON.stringify(selectedEndValues) !== JSON.stringify(emptyValues)){
                                background = 'rgba(222, 97, 97, 0.2)';
                            }
                        }                       

                        return (
                            <DayItemSelected type={type} background={background} onClick={() => {
                                if(JSON.stringify(itemDateValues) === JSON.stringify(selectedStartValues)){
                                    if(JSON.stringify(itemDateValues) !== JSON.stringify(emptyValues)){
                                        setSelectedStart(selectedEnd);
                                    }else{
                                        setSelectedStart(empty)
                                    }
                                }

                                if(JSON.stringify(itemDateValues) === JSON.stringify(selectedEndValues)){
                                    setSelectedEnd(empty)
                                }
                            }} key={index}>
                                <Selected>
                                    {item}
                                </Selected>
                            </DayItemSelected>
                        )
                    }else{

                        var background = 'white';

                        if(itemTime > selectedStartTime && itemTime < selectedEndTime){
                            background = 'rgba(222, 97, 97, 0.2)';

                        }

                        return (
                            <DayItem 
                                current={verifyCurrentDay(item)} 
                                selectable={verifySelectable(item)}
                                background={background} 
                                onClick={() => {
                                    if(verifySelectable(item)){
                                        if(JSON.stringify(selectedStartValues) === JSON.stringify(emptyValues)){
                                            setSelectedStart(itemDate);
                                        }else{
                                            setSelectedEnd(itemDate)
                                        }
                                    }
                                }} 
                                key={index}
                            >
                                {item}
                            </DayItem>
                        )
                    }
                    
                })}
            </DaysContainer>
            <RowButtons>
                <TextClearFilters onClick={() => {
                    clearDateFilter()
                    setSelectedStart(empty)
                    setSelectedEnd(empty)
                }}>
                    Limpar
                </TextClearFilters>
                <ConfirmButton onClick={() => onClose()}>
                    <h3>OK</h3>
                </ConfirmButton>
            </RowButtons>
           
        </Container>
    )
}

export default DateFilter;