import React from 'react';

import { Container } from './styles';

const validStatus = ['aguardando', 'iniciado', 'parcial', 'finalizado', 'cancelado']
const statusName = ['Aguardando', 'Em andamento', 'Parcialmente concluída', 'Concluída', 'Cancelada']
const colorsStatus = ['var(--grey1)', 'var(--orange)', 'var(--yellow)', 'var(--success)', 'var(--red)']

const StatusIcon = ({ status }) => {
    return (
        <Container background={colorsStatus[validStatus.findIndex(x => x === status)]}>
            {statusName[validStatus.findIndex(x => x === status)]}
        </Container>
    )
}

export default StatusIcon;