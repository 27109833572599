import styled from 'styled-components';

export const Text = styled.div`
    margin: 20px 0;
    
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */


    /* 2 */

    color: #808B9F;
`;
