import styled from "styled-components";

export const Container = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 256px;

    padding: 20px 34px;

    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    height: 200vh;
    width: calc(100% - 256px);


    background: var(--background);
`;

export const Header = styled.div`
    width: 100%;
    height: 67px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 20px;

    h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 38px;
        /* identical to box height */


        /* 1 */

        color: #4A5E83;
    }

    div {
        display: flex;
        flex-direction: row;

        gap: 20px;
        align-items: center;
    }
`;

export const UploadContent = styled.div`
    display: flex;
    flex-direction: row;

    width: 100%;
    /* min-height: 592px; */

    padding: 40px;

    background: #FFFFFF;
    /* shadowcard */

    box-shadow: 0px 4px 20px rgba(35, 54, 63, 0.05);
    border-radius: 20px;
`;

export const UploadSide = styled.div`
    display: flex;
    flex-direction: column;

    width: 49%;
    height: 100%;

    padding: 20px;
    gap: 20px;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        /* 1 */

        color: #4A5E83;
    }

    h4 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        /* 1 */

        color: #4A5E83;
    }
`;

export const ModelSide = styled.div`
    display: flex;
    flex-direction: column;

    width: 49%;
    height: 100%;

    padding: 20px;
    gap: 20px;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        /* 1 */

        color: #4A5E83;
    }
`;

export const Table = styled.div`
    display: flex;
    flex-direction: column;

    width: 100%;
`;

export const TableTitleContainer = styled.div`
    display: flex;
    /* justify-content: center; */
    margin: 20px 0;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    /* DE6161 */

    color: #DE6161;

`;

export const TableColumns = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    height: 46px;
    width: 100%;

    padding: 0 10px;

    align-items: center;
    /* justify-content: center; */

    background: rgba(236, 242, 251, 0.5);
    border-radius: 10px;

    color: #808B9F;

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;

        color: #808B9F;
    }
`;

export const TableList = styled.div`
    display: flex;
    flex-direction: column;

    overflow-y: auto;
    width: 100%;
    max-height: 100vh;
`;

export const TableItem = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    padding: 20px 10px;

    align-items: center;
    /* justify-content: center; */

    background: #fff;

    color: #808B9F;

    border-bottom: 1px solid #ECF2FB;

    div {
        display: flex;

        align-items: center;
        gap: 10px;
    }

    h1 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;

        /* 1 */

        color: #4A5E83;
    }

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;

        max-width: 90%;

        color: #808B9F;
    }
`;

export const ActionsContent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    gap: 20px;
    padding-top: 18px;
    padding-right: 20px;
`;

export const CancelButton = styled.div`
     display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    padding: 10px;

    text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;

        color: var(--grey2);
    }
`;


export const ConfirmButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    padding: 12px 58px 12px 58px;

    background: #DE6161;
    box-shadow: 0px 7px 10px rgba(228, 73, 73, 0.25);
    border-radius: 100px;

    text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        text-align: center;

        /* 5 */

        color: var(--white);

    }
`;

export const UploadButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    padding: 12px 58px 12px 58px;

    width: 100%;

    background: #DE6161;
    box-shadow: 0px 7px 10px rgba(228, 73, 73, 0.25);
    border-radius: 100px;

    text {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        /* identical to box height, or 171% */

        text-align: center;

        /* 5 */

        color: var(--white);

    }
`;

export const DownloadButton = styled.div`
    display: flex;
    flex-direction: row;

    align-items: center;
    
    gap: 10px;

    cursor: pointer;
    user-select: none;

    padding: 12px 46px;

    width: 254px;

    /* DE6161 */

   

    border: 1px solid #DE6161;
    filter: drop-shadow(0px 7px 10px rgba(228, 73, 73, 0.25));
    border-radius: 100px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    text-align: center;

    /* DE6161 */

    color: var(--red);
`;

export const InputContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 292px;

    background: rgba(236, 242, 251, 0.4);
    border: 1px dashed #808B9F;
    border-radius: 20px;

    div {
        position: absolute;
        display: flex;
        flex-direction: column;
        
        width: 40%;
        height: 292px;
        gap: 20px;

        align-items: center;
        justify-content: center;
    }

    h3 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        /* 1 */

        color: #4A5E83;

    }
`;

export const Input = styled.input`
    width: 100%;
    height: 292px;
    position: relative; 
    right: 0; 
    font-size: 14px; 
    opacity: 0; 
    margin: 0; 
    padding: 0; 
    border: none; 
`;