import React, { useState, useEffect } from 'react';

import { 
    ModalLogsContent,
    LogsContainer,
    Container,
    LogItem,
    Circle,
    Line,
} from './styles';

import CustomLoading from '../CustomLoading';
import CustomNoRowsOverlay from '../CustomNoRowsOverlay';
import ModalHeader from '../ModalHeader';
import { getClientLogs } from '../../firebase/logs/clients/getClientLogs';

const ModalLogs = ({ clientName, clientCpf, onClose }) => {

    const [ clientLogs, setClientsLogs ] = useState('');
    const [ clientLogsFormated, setClientLogsFormated ] = useState('');

    const [ loadingLogs, setLoadingLogs ] = useState(true);

    useEffect(() => {
        (async () => {
            const logs = await getClientLogs(clientCpf);

            if(logs){
                if(logs.length != 0){
                    setClientLogsFormated(logs.slice(0).reverse())
                }else{
                    setClientLogsFormated([]);
                }
                setLoadingLogs(false)
            }
        })();
    }, []);
    
    return (
        <Container>
            <ModalHeader title={`Logs do cliente ${clientName}`} onClose={onClose} />
            <hr />
            <ModalLogsContent>
                {loadingLogs == true
                    ? <CustomLoading />
                    : <>
                        {clientLogsFormated.length != 0 
                            ?<LogsContainer>
                                {clientLogsFormated.map((item, index) => (
                                    <LogItem key={index}>
                                       <div>
                                            <Circle />
                                            <Line />
                                       </div>
                                       <h3>{item}</h3>
                                    </LogItem>
                                ))}
                            </LogsContainer> 
                            : <CustomNoRowsOverlay />
                        }
                        
                    </>
                }
            </ModalLogsContent>
        </Container>
    );
}

export default ModalLogs;