import React, { useContext, useEffect, useState } from 'react';

import { 
    Container, 
    Header, 
    CardsContainer, 
    CustomCard, 
    CardTitle, 
    CardDivider, 
    CardContent
} from './styles';

//Firebase and Context
import { ReportsContext } from '../../contexts/ReportsContext';
import { UsersContext } from '../../contexts/UsersContext';
import { RoutesContext } from '../../contexts/RoutesContext';
import { ReturnsContext } from '../../contexts/ReturnsContext';
import { writeBatch, doc } from "firebase/firestore";
import db from '../../firebase/config';

//Icons
import checkedIcon from '../../assets/checkedIcon.svg';
import clockIcon from '../../assets/clockIcon.svg';
import inRouteIcon from '../../assets/inRouteIcon.svg';
import expiredIcon from '../../assets/expiredIcon.svg';
import contractIcon from '../../assets/contractIcon.svg';

//Custom components
import ProfileButton from '../../components/ProfileButton';
import CustomLoadingPage from '../../components/CustomLoadingPage';
import OperatorsCard from '../../components/OperatorsCard';
import CustomTable from '../../components/CustomTable';

//Utils
import tableColumnsReturns from '../../resources/tableColumnsReturns.json';
import Modal from 'react-modal';
import ModalReturnCard from '../../components/ModalReturnCard';
import { arrayHelper } from '../../helpers/arrayHelper';

const options = [
    "Contrato fechado",
    "Contrato fechado por indicação",
    "Endereço não encontrado",
    "Litispendência",
    "Já tem advogado",
    "Retornar",
    "Não tem direito",
    "Não tem interesse",
    "Localidade diversa",
]

function AdminReturnsPage() {

    const { users } = useContext(UsersContext);
    const { returns, getReturnsAdmin } = useContext(ReturnsContext);
    const { routes, getRoutesAdmin } = useContext(RoutesContext);
    const { reports, getReportsAdmin } = useContext(ReportsContext);

    const [ loadingData, setLoadingData ] = useState(true);
    const [ realizedReturns, setRealizedReturns ] = useState('');
    const [ pendingReturns, setPendingReturns ] = useState('');
    const [ inRouteReturns, setInRouteReturns ] = useState('');
    const [ expiredReturns, setExpiredReturns ] = useState('');
    const [ closedByReturns, setClosedByReturns ] = useState('');

    //Table
    const [ tableRows, setTableRows ] = useState('');
    const [ tableRowsBackup, setTableRowsBackup ] = useState('');
    const [ operatorsDataBackup, setOperatorsDataBackup ] = useState('');

    //Modal
    const [ modalReturnCard, setModalReturnCard ] = useState(false);
    const [ modalData, setModalData ] = useState('');

    useEffect(() => {
        (async () => {
            if(reports && returns && routes && users){
                setLoadingData(true);
                const findRealized = reports.filter(x => x.retornos > 0 && x.result != options[5]);
                // const findInRoute = routes.filter(x => x.status == 'aguardando' || x.status == 'iniciado');
                // const findExpired = returns.filter(x => x.atraso === true);
                const findClosedByReturn = reports.filter(x => x.result === options[0] && x.retornos > 0);

                const findExpired = [];
                const findPending = [];
                const findInRoute = [];

                // const findRealized = reports.filter(x => x.retornos > 0 && x.result != options[5]);
                // const findClosedByReturn = reports.filter(x => x.result === options[0] && x.retornos > 0);

                for(const item of returns){
                    if(item.atraso && item.atraso == true){

                        const report = reports.find(x => x.docId == item.reportId);

                        if(report){
                            const user = users?.operators.find(x => x.uid === item.operatorUid);

                            findExpired.push({
                                ...report,
                                reportTime: report.time,
                                operatorName: user ? user.userName : report.operatorName,
                                operatorUid: item.operatorUid,
                            })
                        }
                    }else{
                        const report = reports.find(x => x.docId == item.reportId);
                        
                        const user = users.operators.find(x => x.uid === item.operatorUid);

                        if(report){
                            findPending.push({
                                ...report,
                                reportTime: report.time,
                                operatorName: user ? user.userName : report.operatorName,
                                operatorUid: item.operatorUid,
                            })
                        }
                    }
                }

                for(const route of routes){
                    if(route.status == 'iniciado' || route.status == 'aguardando'){
                        for(const routePoint of route.points){
                            if(routePoint.retornos > 0){
                                const report = reports.find(x => x.docId === routePoint.reportId);
        
                                if(report){
                                    findInRoute.push({
                                        ...routePoint,
                                        ...report,
                                        time: report.time,
                                        routeStatus: route.status,
                                        routeTime: route.time,
                                    })
                                }
                            }
                        }
                    }
                }
                let allData = findRealized.concat(findPending, findInRoute, findExpired, findClosedByReturn);
                allData = arrayHelper.ordenateArrayDesc(allData, 'time')

                setRealizedReturns(findRealized);
                setPendingReturns(arrayHelper.ordenateArrayDesc(findPending, 'time'))
                setInRouteReturns(arrayHelper.ordenateArrayDesc(findInRoute, 'time'))
                setExpiredReturns(arrayHelper.ordenateArrayDesc(findExpired, 'time'))
                setClosedByReturns(findClosedByReturn);

                setTableRows(allData)
                setTableRowsBackup(allData);
                setOperatorsDataBackup(allData)
                
                setLoadingData(false)
            }else{
                if(!reports){
                    await getReportsAdmin();
                }
                if(!returns){
                    await getReturnsAdmin();
                }
                if(!routes){
                    await getRoutesAdmin();
                }
            }
        })();
    }, [reports, returns, routes, users]);

    useEffect(() => {
        expireReturns(pendingReturns)
    }, [])

    function formatNumber(number){
        if(number < 10){
            return `0${number}`
        }else{
            return number
        }
    }

    const expireReturns = (data) => {

        const batch = writeBatch(db);

        const dateNow = new Date();

        for(const report of data){
            const clientDoc = returns.find(x => x.cpf == report.clientCpf);

            if(clientDoc && clientDoc?.forwardTime){
                if(dateNow.getTime() > (clientDoc.forwardTime+((86400*1000) * 10)) && !clientDoc?.atraso){

                    const docRef = doc(db, "clientes", report.clientCpf);
    
                    batch.update(docRef, {
                        atraso: true,
                        atrasoTime: dateNow.getTime()
                    });
                }
            }else{
                if(clientDoc && dateNow.getTime() > (report.time+((86400*1000) * 10)) && !clientDoc?.atraso){

                    const docRef = doc(db, "clientes", report.clientCpf);
    
                    batch.update(docRef, {
                        atraso: true,
                        atrasoTime: dateNow.getTime()
                    });
                }
            }

            if(report.dataRetorno){
                const textArray = report.dataRetorno.split('/');

                var stringHora = null;


                const stringDate = `${textArray[2]}-${formatNumber(textArray[1])}-${formatNumber(textArray[0])}`;

                if(report.horaRetorno){
                    const horaArray = report.horaRetorno.split(':');

                    stringHora = `${formatNumber(horaArray[0])}:${formatNumber(horaArray[1])}`;
                }

                const finalString = stringHora ? `${stringDate}T${stringHora}` : stringDate;
                
                const reportTime = new Date(finalString).getTime()

                if(dateNow.getTime() > reportTime){
                    const docRef = doc(db, "clientes", report.clientCpf);

                    batch.update(docRef, {
                        atraso: true,
                        atrasoTime: dateNow.getTime()
                    });
                }
            }
        }

        if(data.length > 0){
            batch.commit()
        }
    }

    if(loadingData){
        return (
            <Container>
                <CustomLoadingPage />
            </Container>
        )
    }

    return (
        <Container>
            <Header>
                <h1>Central de Retornos</h1>
                <ProfileButton arrowColor='var(grey2)' />
            </Header>

            <CardsContainer>
                <CustomCard onClick={() => {
                    setModalData({
                        array: realizedReturns,
                        title: 'Retornos realizados',
                        type: 'realized',
                        columns: 6,
                        filterTypes: [
                            { headerName: 'Data', field: 'dataRetorno' }, 
                            { headerName: 'Operador', field: 'operatorName'},
                            { headerName: 'Resultado', field: 'result'}
                        ],
                        tableColumns: tableColumnsReturns
                    });
                    setModalReturnCard(true);
                }}>
                    <CardTitle color='var(--success)'>
                        <img src={checkedIcon} alt='Icon icon' />
                        <h1>Retornos realizados</h1>
                    </CardTitle>
                    <CardDivider />
                    <CardContent color='var(--success)'>
                        <h1 className="value">{realizedReturns.length}</h1>
                    </CardContent>
                </CustomCard>

                <CustomCard onClick={() => {
                    setModalData({
                        array: pendingReturns,
                        title: 'Retornos a realizar',
                        type: 'pending',
                        columns: 5,
                        filterTypes: [
                            { headerName: 'Operador', field: 'operatorName'},
                        ],
                        tableColumns: tableColumnsReturns
                    });
                    setModalReturnCard(true);
                }}>
                    <CardTitle color='var(--yellow)'>
                        <img src={clockIcon} alt='Icon icon' />
                        <h1>Retornos pendentes</h1>
                    </CardTitle>
                    <CardDivider />
                    <CardContent color='var(--yellow)'>
                        <h1 className="value">{pendingReturns.length}</h1>
                    </CardContent>
                </CustomCard>

                <CustomCard onClick={() => {
                    setModalData({
                        array: inRouteReturns,
                        title: 'Retornos em rota',
                        type: 'inRoute',
                        columns: 6,
                        filterTypes: [
                            { headerName: 'Status', field: 'status' }, 
                            { headerName: 'Operador', field: 'operatorName'},
                        ],
                        tableColumns: tableColumnsReturns
                    });
                    setModalReturnCard(true);
                }}>
                    <CardTitle color='var(--blue)'>
                        <img src={inRouteIcon} alt='Icon icon' />
                        <h1>Retornos em rota</h1>
                    </CardTitle>
                    <CardDivider />
                    <CardContent color='var(--blue)'>
                        <h1 className="value">{inRouteReturns.length}</h1>
                    </CardContent>
                </CustomCard>

                <CustomCard onClick={() => {
                    setModalData({
                        array: expiredReturns,
                        title: 'Retornos expirados',
                        type: 'expired',
                        columns: 5,
                        filterTypes: [
                            { headerName: 'Operador', field: 'operatorName'},
                        ],
                        tableColumns: tableColumnsReturns
                    });
                    setModalReturnCard(true);
                }}>
                    <CardTitle color='var(--red)'>
                        <img src={expiredIcon} alt='Icon icon' />
                        <h1>Retornos expirados</h1>
                    </CardTitle>
                    <CardDivider />
                    <CardContent color='var(--red)'>
                        <h1 className="value">{expiredReturns.length}</h1>
                    </CardContent>
                </CustomCard>

                <CustomCard onClick={() => {
                    setModalData({
                        array: closedByReturns,
                        title: 'Contratos fechados após retorno',
                        type: 'closed',
                        columns: 5,
                        filterTypes: null,
                        tableColumns: tableColumnsReturns
                    });
                    setModalReturnCard(true);
                }}>
                    <CardTitle color='var(--success)'>
                        <img src={contractIcon} alt='Icon icon' />
                        <h1>Contratos fechados após retorno</h1>
                    </CardTitle>
                    <CardDivider />
                    <CardContent color='var(--success)'>
                        <h1 className="value">{closedByReturns.length}</h1>
                    </CardContent>
                </CustomCard>
            </CardsContainer> 

            <OperatorsCard 
                data={{
                    realizedReturns: realizedReturns,
                    pendingReturns: pendingReturns,
                    inRouteReturns: inRouteReturns,
                    expiredReturns: expiredReturns,
                    closedByReturns: closedByReturns,
                }}
                onFilter={(data) => {
                    setTableRows(data)
                    setTableRowsBackup(data);
                }} 
                unFilter={() => {
                    setTableRows(operatorsDataBackup)
                    setTableRowsBackup(operatorsDataBackup);
                }}
            />

            <CustomTable 
                tableColumns={tableColumnsReturns} 
                tableRows={tableRows} 
                tableRowsBackup={tableRowsBackup}
                filterTypes={tableColumnsReturns} 
                columns={7}
                tableDefaultFilters={{}}
                onFilter={() => {

                }}
                onClearFilters={() => {
                        
                }}
                filtersHighlights={[
                    {
                        field: 'time',
                        headerName: 'Data'
                    },
                    {
                        field: 'operatorName',
                        headerName: 'Operador'
                    },
                    {
                        field: 'result',
                        headerName: 'Resultado'
                    }
                ]}
                title='Retornos'
                dateField='time'
            />

            <Modal
                isOpen={modalReturnCard}
                onRequestClose={() => setModalReturnCard(false)}
                overlayClassName="modal-overlay"
                className="modal-content-auto-width"
                contentLabel="Example Modal"
            >
                <ModalReturnCard data={modalData} onClose={() => setModalReturnCard(false)}/>
            </Modal>
        </Container>
    )
}

export default AdminReturnsPage;