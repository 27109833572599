import { collection, getDocs, orderBy, query } from "firebase/firestore";
import db from '../../config'

export async function getPjeLogs(){
    const array = [];

    const consulta = query(collection(db, 'pjeLogs'), orderBy("timeStart", 'desc'))
    const querySnapshot = await getDocs(consulta);    
    querySnapshot.forEach(async (doc) => {
        array.push({...doc.data(), docId: doc.id})
    });

    return array
}