import React, { useEffect, useState } from 'react';

import { Container, Content, ActionsContent, CancelButton, ConfirmButton, ModalConfirmHeader, ModalConfirmText } from './styles';

import { IoWarningOutline } from 'react-icons/io5';

const ModalConfirm = ({ data, onCancel, onConfirm }) => {

    return (
        <Container>
            <Content>
                <IoWarningOutline size={80}/>
                <ModalConfirmText>
                    {data.text}
                </ModalConfirmText>
            </Content>
            <ActionsContent>
                <CancelButton onClick={onCancel}>
                    <text>Voltar</text>
                </CancelButton>
                <ConfirmButton onClick={onConfirm}>
                    <text>{data.option}</text>
                </ConfirmButton>
            </ActionsContent>
        </Container>
    )
}

export default ModalConfirm;