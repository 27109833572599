import React, { useContext, useEffect, useState } from 'react';

import { 
    DetailsContainer,
    Header, 
    ClientHeader, 
    ClientNameContent, 
    Container, 
    ImageContainer, 
    ImageStreetView, 
    InfoHeader, 
    PointDivider, 
    FullData,
    GenerateReportButton,
    ImageEspecie,
    SpecialIcon,
} from './styles';

//Firebase and Context
import { AuthContext } from '../../contexts/AuthContext';

import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FiMapPin } from 'react-icons/fi';
import { TbFileInvoice } from 'react-icons/tb';
import CustomLoading from '../CustomLoading';
import axios from 'axios';

//utils
import { address } from '../../helpers/address';
import { markers } from '../../helpers/markers';
import { specialIcon } from '../../firebase/specialIcon/specialIcon';
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md';
import money34 from '../../assets/markers/money34.gif';
import ClientBadges from '../ClientBadges';

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const ClientDetailsAdmin = ({ data, onClose, onStreetView, onGenerateReport, onViewFullData, onChangeSpecialIcon }) => {

    const { isAdmin, userData } = useContext(AuthContext);

    const [ loadingData, setLoadingData ] = useState(true);

    const [ imageStreetView, setImageStreetView ] = useState('');

    useEffect(() => {
        (async () => {
            setLoadingData(true);         

            await axios.get(`https://maps.googleapis.com/maps/api/streetview?size=400x400&location=${data.lat},${data.lng}&fov=80&heading=70&pitch=0&key=${apiKey}`, { responseType: "blob" })
                .then((response) => {
                    setImageStreetView(response.data);
                })
                .catch((error) => {
                    
                })
            
            setLoadingData(false);
        })();
    }, [data]);

    if(loadingData){
        return <Container>
            <CustomLoading background='white' />
        </Container>
    }

    return (
        <Container>
            <Header>
                <AiOutlineCloseCircle size={25} style={{ cursor: 'pointer'}} onClick={onClose}/>
            </Header>

            <ImageContainer>
                <ImageStreetView
                    src={URL.createObjectURL(imageStreetView)}
                    onClick={() => {
                        onStreetView()
                    }}
                />
            </ImageContainer>

            <ClientHeader>
                <ImageEspecie>
                    <img 
                        src={markers.getMarkerIconsEspecie(data.especie)} 
                        style={{ width: 34, height: 34, objectFit: 'cover'}} 
                    />
                </ImageEspecie>
                <ClientNameContent>
                    <h4>{data.nome}</h4>
                    <text>{data.especie}</text>
                </ClientNameContent>
            </ClientHeader>

            <SpecialIcon
                onClick={() => {
                    if(data.specialIcon){
                        specialIcon.disableSpecialIcon(data.cpf, data.nome)
                        onChangeSpecialIcon()
                    }else{
                        specialIcon.enableSpecialIcon(data.cpf, data.nome)
                        onChangeSpecialIcon()
                    }
                    
                }} 
            >
                {data.specialIcon 
                    ? <MdCheckBox 
                        size={20} 
                        style={{ borderRadius: 20}}
                    /> 
                    : <MdCheckBoxOutlineBlank
                        size={20} 
                    />
                }
                <h2>{`${data.specialIcon ? 'Remover' : 'Adicionar'} ícone especial`}</h2>
                <img src={money34} />
            </SpecialIcon>

            <PointDivider />

            <DetailsContainer>
                <InfoHeader>
                    <FiMapPin size={22} color='var(--red)' />
                    <text>Endereço</text>
                </InfoHeader>
                <text>{address.formatEnd(data)}</text>
            </DetailsContainer>

            <DetailsContainer>
                <InfoHeader>
                    <TbFileInvoice size={26} color='var(--red)' style={{ strokeWidth: 1.8 }} />
                    <text>Dados</text>
                </InfoHeader>
                <text>Idade: {data.idade}</text>
                <ClientBadges data={data} />
            </DetailsContainer>

            <FullData onClick={() => onViewFullData(imageStreetView)}>
                Ver dados completos
            </FullData>

            {isAdmin && (
                <GenerateReportButton
                    onClick={onGenerateReport}
                >
                    Gerar atendimento
                </GenerateReportButton>
            )}
        </Container>
    )
}

export default ClientDetailsAdmin;